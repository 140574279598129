import React from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  SimpleForm,
  TextField,
  useRecordContext,
  useResourceContext,
  Labeled,
  FunctionField,
  SimpleShowLayout,
  ReferenceManyField,
  ReferenceField,
  RadioButtonGroupInput,
  TextInput,
  NullableBooleanInput,
} from "react-admin";
import { MessageSquarePlus } from "lucide-react";
import { Typography } from "@mui/material";

const styles = {
  sm: { minWidth: 120 },
  lg: { minWidth: 140 },
  parent: { paddingLeft: 16, gap: 16, display: "flex" },
};

const filters = [
  <TextInput source="parent" alwaysOn />,
  <TextInput source="id" alwaysOn />,
  <TextInput label="Lecture ID" source="lecture_id" alwaysOn />,
  <TextInput label="Dose Number" source="dose_number" alwaysOn />,
  <TextInput label="User Name" source="author_name" alwaysOn />,
  <RadioButtonGroupInput
    alwaysOn
    label="Comment Type"
    source="comment_type"
    options={{
      row: true,
    }}
    choices={[
      { id: "all", name: "All" },
      { id: "lecture", name: "Lecture" },
      { id: "dose", name: "Dose" },
    ]}
  />,
  <NullableBooleanInput
    source="approved"
    alwaysOn
    format={(value) => {
      if (value === 1) return "true";
      if (value === 0) return "false";
      return null;
    }}
    parse={(value) => {
      if (value == "true") return 1;
      if (value == "false") return 0;
      return null;
    }}
  />,
];

const ExpandComment = () => {
  const record = useRecordContext();
  const resource = useResourceContext();

  const hasParent =
    record.parent && record.parent !== 0 && record.parent !== "0";
  return (
    <Edit
      resource={resource}
      id={record.id}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleForm>
        <Labeled>
          <TextField source="content" multiline label="Comment" />
        </Labeled>
        <BooleanInput source="approved" label="Approved" />

        {hasParent ? (
          <Labeled>
            <ReferenceField
              source="parent"
              reference="manage-user-comments"
              label="Parent Comment"
            >
              <div style={styles.parent}>
                <Labeled>
                  <TextField source="id" />
                </Labeled>
                <Labeled>
                  <TextField source="content" />
                </Labeled>
                <Labeled>
                  <TextField
                    source="author_name"
                    label="User"
                    style={styles.sm}
                  />
                </Labeled>
                <Labeled>
                  <DateField
                    source="created_at"
                    label="Created"
                    style={styles.lg}
                  />
                </Labeled>
                <Labeled>
                  <BooleanField source="approved" label="Approved" />
                </Labeled>
              </div>
            </ReferenceField>
          </Labeled>
        ) : (
          <></>
        )}
        {/* This doesn't work on the api yet:  {record.child_count > 0 ? (
          <></>
        ) : (
          <Typography
            component="span"
            variant="body1"
            // style={styles.inlineFieldRight}
          >
            No replies
          </Typography>
        )}
        {record.lecture_id ? (
          <></>
        ) : (
          <BooleanInput source="featured" label="Featured" />
        )} */}
      </SimpleForm>
    </Edit>
  );
};

function ListView(props) {
  return (
    <List
      {...props}
      exporter={true}
      filters={filters}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Comments"
    >
      <Datagrid
        rowClick="expand"
        bulkActionButtons={false}
        expand={<ExpandComment />}
        expandSingle
      >
        <TextField source="author_name" label="User" style={styles.sm} />
        <DateField source="created_at" label="Created" style={styles.lg} />
        <TextField source="user_email" label="Email" style={styles.sm} />
        <BooleanField source="approved" label="Approved" />
        <FunctionField
          label="Content"
          render={(record: { content: string }) => {
            const comment = record.content.slice(0, 50);
            if (comment.length < record.content.length) return comment + "...";
            return comment;
          }}
        />
        <TextField source="lecture_id" label="Lecture" />
        <TextField source="daily_dose.dose_number" label="Dose" />
        <BooleanField source="featured" label="Featured" />
      </Datagrid>
    </List>
  );
}

export const UserComments = {
  category: "manage",
  name: "manage-user-comments",
  icon: MessageSquarePlus,
  list: ListView,
  options: { label: "Comments" },
};
