import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";

import { PiggyBank } from "lucide-react";
import { EditorToolbar, validation } from "../../components";

const validate = {
  symbol: [
    validation.required(),
    validation.minLength(1),
    validation.maxLength(4),
  ],
  id: [validation.required(), validation.minLength(3), validation.maxLength(3)],
};

function ListView(props) {
  return (
    <List {...props} sort={{ field: "id", order: "ASC" }}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <NumberField source="id" />
        <TextField source="display_name" />
        <TextField source="symbol" />
        <TextField source="numeric_code" />
        <TextField source="decimals" />
        <BooleanField source="active" />
      </Datagrid>
    </List>
  );
}

function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <TextInput
        source="id"
        validate={validate.id}
        label="Id &nbsp;&nbsp;&nbsp; (ISO 4217 Currency Code)"
      />
      <TextInput source="display_name" />
      <TextInput
        source="name"
        label="Name &nbsp;&nbsp;&nbsp; (ISO 4217 Name)"
      />
      <BooleanInput source="active" defaultValue={true} />
      <TextInput source="symbol" validate={validate.symbol} />
      <NumberInput source="numeric_code" />
      <NumberInput source="decimals" label="Decimal places" />
      <TextField
        label="Note: Changes here are not currently reflected on the front end."
        className="bold"
      />
    </SimpleForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      {renderEditForm()}
    </Edit>
  );
}

export const Currencies = {
  category: "tanext",
  name: "currencies",
  create: CreateView,
  edit: EditView,
  icon: PiggyBank,
  list: ListView,
  options: { label: "Currencies" },
};
