import { Checkbox } from "@mui/material";
import { useNotify, useRecordContext } from "ra-core";
import React, { useEffect, useState } from "react";
import { Button } from "react-admin";
import { formatDate } from "../../components";
import { authClient } from "../../server";

//@todo @estherkatz styles should be in css files not js objects
const FollowingList = ({ styles }) => {
  const notify = useNotify();
  const record = useRecordContext();
  const [allSelected, setAllSelected] = useState(false);
  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await authClient
      .get(`/admin/users/${encodeURIComponent(record.id)}/following`)
      .catch((error) => notify(error.body.message, { type: "error" }));

    if (!res?.json) {
      return;
    }

    setItems(res.json);
  };

  const setSelected = (index) => {
    const itemsCopy = [...items];
    itemsCopy[index] = {
      ...itemsCopy[index],
      selected: !itemsCopy[index].selected,
    };
    setItems(itemsCopy);
    setAllSelected(false);
  };
  const toggleSelectAll = () => {
    const itemsCopy = [...items].map((item) => ({
      ...item,
      selected: !allSelected,
    }));
    setItems(itemsCopy);
    setAllSelected(!allSelected);
  };

  const unfollow = async () => {
    const selectedIds = items
      .filter((item) => item.selected)
      .map((item) => item.id);

    if (selectedIds.length < 1) return;

    if (
      !window.confirm(
        `Unfollow the ${selectedIds.length} selected speaker(s) for:\n${record.email}?` +
          "\n******************************\n" +
          "  NOTE: This CANNOT be undone!" +
          "\n******************************\n",
      )
    ) {
      return;
    }
    const res = await authClient
      .post(
        `/admin/users/${encodeURIComponent(record.id)}/unfollow`,
        selectedIds,
      )
      .catch((error) => notify(error.body.message, { type: "error" }));
    if (!res || !res.json) {
      return;
    }
    setItems(res.json);
    setAllSelected(false);
  };

  const updateFollowingEmailPreference = async (preference) => {
    const selectedIds = items
      .filter((item) => item.selected)
      .map((item) => item.id);

    if (selectedIds.length < 1) return;

    const res = await authClient
      .post(
        `/admin/users/${encodeURIComponent(
          record.id,
        )}/update_notify_by_email?state=${preference}`,
        selectedIds,
      )
      .catch((error) => notify(error.body.message, { type: "error" }));

    if (!res?.json) return;

    setItems(res.json);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <div style={styles.row}>
        <span style={styles.header}>
          <Checkbox checked={allSelected} onClick={() => toggleSelectAll()} />
        </span>
        <span style={styles.header}>Date</span>
        <span style={styles.header}>Following</span>
        <span style={styles.header}>Email subscription</span>
      </div>
      {items.map((item, index) => (
        <FollowingItem
          item={item}
          setSelected={() => setSelected(index)}
          styles={styles}
        />
      ))}
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Button
          variant="contained"
          size="small"
          onClick={unfollow}
          title="Unfollow the selected speakers."
        >
          <>Unfollow</>
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => updateFollowingEmailPreference(false)}
          title="Unsubscribe from the following emails."
        >
          <>Email Notifications: Off</>
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => updateFollowingEmailPreference(true)}
          title="Subscribe the following emails."
        >
          <>Email Notifications: On</>
        </Button>
      </div>
    </div>
  );
};

const FollowingItem = ({ item, setSelected, styles }) => {
  const subscribed = formatDate(item.date_subscribed);
  const { id, selected = false } = item;

  const type =
    (!!item.speaker_name && "Speaker") ||
    (!!item.category?.name && "Category") ||
    (!!item.organization?.name && "Organization") ||
    (!!item.series && "Series") ||
    "";

  const name =
    item.speaker_name ||
    item.category?.name ||
    item.organization?.name ||
    item.series.title ||
    "";

  const urlPattern = {
    Speaker: {
      url: "/speakers/",
      idKey: "speaker_id",
    },
    Category: {
      url: "/categories/",
      idKey: "topic_id",
    },
    Organization: {
      url: "/organizations/",
      idKey: "org_id",
    },
    Series: {
      url: "/series/",
      idKey: "series_id",
    },
  };

  const url = urlPattern[type].url + item[urlPattern[type].idKey];

  let component = (
    <span>
      {`${type}: `}
      <a href={url} target="_blank">
        {name}
      </a>
      <br />
      {item.series?.speaker_name && (
        <sub>
          <a href={`/speakers/${item.series.speaker.id}`} target="_blank">
            {item.series.speaker_name}
          </a>
        </sub>
      )}
    </span>
  );

  return (
    <div key={id} style={styles.row}>
      <span style={styles.col}>
        <Checkbox checked={selected} onClick={() => setSelected(id)} />
      </span>
      <span style={styles.col}>{subscribed}</span>
      <span style={styles.col}>{component}</span>
      <span style={styles.col}>{item.notify_by_email ? "On" : "Off"}</span>
    </div>
  );
};

export default FollowingList;
