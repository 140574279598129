export const authRoles = [
  { id: "super_admin", name: "Super Admin", prop: "isSuperAdmin" },
  {
    id: "administrator",
    name: "Administrator",
    prop: "isAdministrator",
  },
  {
    id: "author",
    name: "Author",
    prop: "isAuthor",
  },
  {
    id: "cnsclipper",
    name: "Clip 'N Share Clipper",
    prop: "isCnsclipper",
  },
  {
    id: "subscriber",
    name: "Subscriber",
    prop: "isSubscriber",
  },
  {
    id: "kiosk",
    name: "Kiosk",
    prop: "isKiosk",
  },
  {
    id: "partner",
    name: "Partner",
    prop: "isPartner",
  },
  {
    id: "newsletter_admin",
    name: "Newsletter Admin",
    prop: "isNewsletterAdmin",
  },
  {
    id: "clipper_admin",
    name: "Clipper Admin",
    prop: "isClipperAdmin",
  },
  {
    id: "stat_viewer",
    name: "Stat Viewer",
    prop: "isStatViewer",
  },
  {
    id: "campaign_admin",
    name: "Campaign Admin",
    prop: "isCampaignAdmin",
  },
  {
    id: "dose_email_admin",
    name: "Dose Email Admin",
    prop: "isDoseEmailAdmin",
  },
  {
    id: "dose_admin",
    name: "Dose Admin",
    prop: "isDoseAdmin",
  },
  {
    id: "dose_viewer",
    name: "Dose Viewer",
    props: "isDoseViewer",
  },
  {
    id: "dose_donor_admin",
    name: "Dose Donor Admin",
    props: "isDoseDonorAdmin",
  },
  {
    id: "dose_dedications_admin",
    name: "Dose Dose Dedications Admin",
    props: "isDoseDedicationsAdmin",
  },
  {
    id: "schedules_admin",
    name: "Schedules Admin",
    props: "isSchedulesAdmin",
  },
  {
    id: "corporate_sponsor_admin",
    name: "Corporate Sponsor Admin",
    prop: "isCorporateSponsorAdmin",
  },
  {
    id: "lecture_replacer",
    name: "Lecture Replacer",
    prop: "isReplacert",
  },
  {
    id: "series_admin",
    name: "Series Admin",
    prop: "isSeriesAdmin",
  },
];
