import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MuiToolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import { Title, useNotify } from "react-admin";
import { FetchJsonResponse, authClient } from "../../server";
import { Apple } from "lucide-react";

const AppleAppSite = () => {
  const notify = useNotify();

  const [changed, setChanged] = useState(false);
  const [data, setData] = useState<any>();

  useEffect(() => {
    authClient
      .get("/admin/apple-app-site/data")
      .then(onLoad)
      .catch((error) => notify(error.body.message, { type: "error" }));
  }, []);

  const handleSave = () => {
    if (changed) {
      const objData = JSON.parse(data);
      if (changed) {
        authClient
          .put("/admin/apple-app-site/data", objData)
          .then(onLoad)
          .catch((error) => notify(error.body.message, { type: "error" }));
      }
    }
  };

  const handleChangeField = (field: string, value: string) => {
    setData({ ...data, [field]: value });
  };

  const onLoad = (res: FetchJsonResponse) => {
    setData(JSON.stringify(res.json, null, 2));
    setChanged(false);
  };

  return (
    <Card>
      <Title title="Apple App Site" />
      <CardContent>
        <div>
          <TextField
            label="Association Data"
            placeholder="Association Data"
            value={data}
            onChange={(e) => handleChangeField("data", e.target.value)}
            multiline={true}
            rows={25}
            style={{ width: "100%" }}
          />
        </div>
        <MuiToolbar role="toolbar">
          <Button
            variant="contained"
            size="small"
            onClick={handleSave}
            disabled={!changed}
          >
            Save
          </Button>
        </MuiToolbar>
      </CardContent>
    </Card>
  );
};

export default AppleAppSite;

export const AppleAppSitePage = {
  category: "misc",
  icon: Apple,
  name: "apple-app-site",
  label: "Apple App Site",
  url: "/apple-app-site",
  exact: true,
  Element: <AppleAppSite />,
};
