import { MailCheck } from "lucide-react";
import { Fragment, useEffect, useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import {
  CardListActions,
  DefaultBulkActionButtons,
  EditorToolbar,
  MorePagination,
  validation,
} from "../../components";
import { authClient } from "../../server";

const validate = {
  required: [validation.required()],
};
function CreateView(props) {
  return (
    <Create {...props} redirect="edit" title="Upload Clip">
      <SimpleForm toolbar={<EditorToolbar />}>
        <TextInput
          fullWidth
          source="email"
          label="Email"
          validate={validate.required}
        />
        <TextInput fullWidth source="first_name" label="First Name" />
        <TextInput fullWidth source="last_name" label="Last Name" />
        <BooleanInput fullWidth source="subscribed" label="Subscribed" />
      </SimpleForm>
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      <SimpleForm toolbar={<EditorToolbar showDelete />}>
        <TextInput
          fullWidth
          source="email"
          label="Email"
          validate={validate.required}
        />
        <TextInput fullWidth source="first_name" label="First Name" />
        <TextInput fullWidth source="last_name" label="Last Name" />
        <BooleanInput fullWidth source="subscribed" label="Subscribed" />
      </SimpleForm>
    </Edit>
  );
}

const filters = [
  <TextInput label="Email" source="email" alwaysOn />,
  <TextInput label="First Name" source="first_name" alwaysOn />,
  <TextInput label="Last Name" source="last_name" alwaysOn />,
];

function RenderList(props) {
  const [subscribedCount, setSubscribedCount] = useState();
  const [unsubscribedCount, setUnsubscribedCount] = useState();
  useEffect(() => {
    authClient.get("/admin/dose-email-subscribers/count").then((res) => {
      const json = res.json;
      setSubscribedCount(json.subscribed);
      setUnsubscribedCount(json.unsubscribed);
    });
  }, []);
  return (
    <Fragment>
      {subscribedCount !== undefined && (
        <div
          style={{
            padding: 13,
            fontWeight: "bold",
            fontSize: 18,
            fontFamily: "Roboto",
          }}
        >
          <span style={{ marginRight: 25 }}>
            Total Subscribed: {subscribedCount}
          </span>
          <span>Total Unsubscribed: {unsubscribedCount}</span>
        </div>
      )}
      <Datagrid rowClick="edit" {...props}>
        <TextField source="id" />
        <TextField source="email" label="Email" />
        <TextField source="first_name" label="First Name" />
        <TextField source="last_name" label="Last Name" />
        <BooleanField source="subscribed" label="Subscribed" />
        {/* TODO: add subscribed by */}
      </Datagrid>
    </Fragment>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={filters}
      perPage={50}
      pagination={<MorePagination />}
      title="Dose Email Subscribers"
    >
      <RenderList bulkActionButtons={<DefaultBulkActionButtons />} />
    </List>
  );
}

export const DoseEmailSubscribers = {
  category: "dose",
  name: "dose-email-subscribers",
  list: ListView,
  create: CreateView,
  icon: MailCheck,
  options: { label: "Dose Subscribers" },
  edit: EditView,
};
