// Framework
import { Admin, AdminRouter, CustomRoutes, Login, Resource } from "react-admin";
// Application
import history from "./history";
import { AppLayout } from "./layout";
import HandleTokenRefresh from "./refresh-token";
import resources, { customPageRoutes, DashboardPage } from "./resources";
import { authorized, authProvider, rootProvider } from "./server";
import { MediaUploadProvider } from "./uploader";
// import { render } from "react-dom";
import { Route } from "react-router-dom";

const { REACT_APP_VERSION } = process.env;

const styles = {
  copyright: {
    position: "fixed",
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
    padding: 4,
    fontFamily: "Roboto Condensed, sans-serif",
    fontSize: 12,
  },
};

const CustomBGLoginPage = () => (
  <Login backgroundImage="https://torahanytime-files.sfo2.cdn.digitaloceanspaces.com/assets/flash/banner/Nice-kotel-image.jpg" />
);

const AuthorizedResource = (props) => {
  const {
    // #region Sanitize props that should not be passed to `<Resource />`
    category: _category,
    editId,
    hidden: _hidden,
    permissions,
    roles,
    // #endregion
    create,
    edit,
    list,
    ...rest
  } = props;
  // NOTE: If a user is not authorized to interact with a resource, we are
  // still rendering the resource specification but not the create/edit/list
  // views. This allows the restricted user to use related resource references
  // in the resources that they are allowed to access.
  return (
    <Resource
      key={props.name}
      create={authorized(roles, permissions, "create") ? create : null}
      edit={authorized(roles, permissions, "edit") ? edit : null}
      list={authorized(roles, permissions, "list") ? list : null}
      view={authorized(roles, permissions, "view") ? list : null}
      {...rest}
    />
  );
};

const App = () => {
  return (
    <AdminRouter>
      <MediaUploadProvider allowed={authorized(["author"])}>
        <Admin
          loginPage={CustomBGLoginPage}
          layout={AppLayout}
          authProvider={authProvider}
          dashboard={DashboardPage}
          dataProvider={rootProvider}
        >
          {/* I can't figure out why using JSX on the next line doesn't work. e.g. `.map((resourceObject)=>(<AuthorizedResource {...resourceObject}>))`*/}
          {resources.map(AuthorizedResource)}
          <CustomRoutes>
            {customPageRoutes.map((route) => (
              <Route path={route.url} element={route.Element} key={route.url} />
            ))}
          </CustomRoutes>
        </Admin>
        <HandleTokenRefresh />
        {history.location.search.indexOf("rafooter=0") < 0 && (
          <div style={styles.copyright}>
            Torah Anytime Admin v{REACT_APP_VERSION}
          </div>
        )}
      </MediaUploadProvider>
    </AdminRouter>
  );
};

export default App;
