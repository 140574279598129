import React, { useEffect, useState } from "react";
import { Button, Title, Toolbar, useNotify, useRedirect } from "react-admin";

import { Link } from "react-router-dom";
import { authClient } from "../../server";

import { Check, X } from "lucide-react";
//@ts-ignore
import { SortItem } from "../../../types/sortable";
import SortablePage from "../../components/page/SortableFormPage.tsx";
const styles = {
  button: { margin: 10 },
  sortableItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toolbar: {
    backgroundColor: "white",
    marginTop: 0,
    justifyContent: "center",
    flex: 1,
    display: "flex",
  },
};
const SortableItem = ({ item }) => {
  return (
    <div style={styles.sortableItem}>
      <span>{item.title}</span>
      <div title="display">{item.show ? <Check /> : <X />}</div>
    </div>
  );
};

const HomePageRowsReorderPage = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [items, setItems] = useState<SortItem[]>([]);
  const [loading, setLoading] = useState(true);
  const projectId = 1;

  useEffect(() => {
    authClient
      .get(
        `/admin/homepage-rows?_end=100&_order=ASC&_sort=row_order&_start=0&project_id=${projectId}`,
      )
      .then((res) => {
        setItems(res.json.sort((a, b) => a.row_order - b.row_order));
        setLoading(false);
      })
      .catch((error) => notify(error));
  }, [notify]);

  const save = async () => {
    if (loading) return;
    setLoading(true);
    const ids = items.map((row) => row.id);
    await authClient
      //@ts-ignore
      .put(`admin/homepage-rows/${projectId}/reorder`, ids)
      .catch((error) => notify(error));
    setLoading(false);
    redirect("/homepage-rows");
  };

  const restoreDefaultOrder = () => {
    const initialRows = [...items].sort((a, b) => a.id - b.id);
    setItems(initialRows);
  };

  const resetOrder = () => {
    const initialRows = [...items].sort((a, b) => a.row_order - b.row_order);
    setItems(initialRows);
  };

  return (
    <div>
      <Title title="Reorder Rows" />
      {loading && <h1>Saving.....</h1>}
      <SortablePage
        staticList
        info="Drag rows to reorder, then click 'Save'"
        items={items}
        renderItem={(item, index) => <SortableItem item={item} />}
        setItems={setItems}
        handleSave={save}
        title={""}
        createItem={() => ({})}
      />
      <Toolbar role="toolbar" style={styles.toolbar}>
        <Link to="/homepage-rows" style={{ textDecoration: "none" }}>
          <Button size="small" style={styles.button} label="Cancel" />
        </Link>
        <Button
          size="small"
          onClick={restoreDefaultOrder}
          style={styles.button}
          label="Restore Default Order"
        />
        <Button
          size="small"
          onClick={resetOrder}
          style={styles.button}
          label="Reset"
        />
      </Toolbar>
    </div>
  );
};

export const HomeRowsReorderPage = {
  hidden: true,
  url: "/homepage-rows/reorder",
  label: "Reorder Rows",
  exact: true,
  Element: <HomePageRowsReorderPage />,
};
