import React, { Component, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import {
  Title,
  useNotify,
  Toolbar,
  Button,
  BooleanField,
  useRedirect,
} from "react-admin";
// import { withRouter } from "react-router-dom";
import { withRouter } from "../../hooks/withRouter.jsx";
import { authClient } from "../../server/index.js";

import { Check, Rotate3d, X, ArrowUpDown } from "lucide-react";
//@ts-ignore
import SortablePage from "../../components/page/SortableFormPage.tsx";
// import { SortItem } from "../../../types/sortable.ts";
const styles = {
  button: { margin: 10 },
  sortableItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toolbar: {
    backgroundColor: "white",
    marginTop: 0,
    justifyContent: "center",
    flex: 1,
    display: "flex",
  },
};
const SortableItem = ({ item, remove }) => {
  return (
    <div style={styles.sortableItem}>
      <span>{item.name}</span>
      <span>
        <Button size="small" onClick={remove} label="Remove" />
      </span>
    </div>
  );
};

const TbavProgramsReorderPage = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [allItems, setAllItems] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    authClient
      .get(
        "/admin/tisha_bav_programs?_end=100&_order=ASC&_sort=name&_start=0&display_active=1",
      )
      .then((res) => {
        setAllItems(res.json);
        setItems(
          res.json.filter((p) => p.order < 0).sort((a, b) => a.order - b.order),
        );
        setLoading(false);
      })
      .catch((error) => notify(error));
  }, []);

  const add = async (id) => {
    setItems((i) => [...i, allItems.filter((p) => p.id == id)[0]]);
    await authClient
      //@ts-ignore
      .put("admin/tisha-bav/programs/reorder", [{ id: id, order: -1 }])
      .catch((error) => notify(error));
  };
  const remove = async (id) => {
    await authClient
      //@ts-ignore
      .put("admin/tisha-bav/programs/reorder", [{ id: id, order: null }])
      .catch((error) => notify(error));

    setItems(items.filter((p) => p.id != id));
  };
  const save = async () => {
    if (loading) return;
    setLoading(true);
    const ids = items.map((row, index, array) => {
      return {
        id: row.id,
        order: Number("-" + String(array.length - index)),
      };
    });
    await authClient
      //@ts-ignore
      .put("admin/tisha-bav/programs/reorder", ids)
      .catch((error) => notify(error));
    setLoading(false);
  };

  return (
    <div>
      <Title title="Reorder Programs" />
      {loading && <h1>Saving.....</h1>}
      <h3>Priority Programs</h3>
      <SortablePage
        staticList
        info="Drag rows to reorder, then click 'Save'"
        items={items}
        renderItem={(item, index) => (
          <SortableItem item={item} remove={() => remove(item.id)} />
        )}
        setItems={setItems}
        handleSave={save}
        title={""}
        createItem={() => ({})}
      />
      <h3>All Programs</h3>
      <ul style={{ width: "fit-content" }}>
        {allItems.map((program) => (
          <li
            style={{
              listStyle: "none",
              marginBlock: 8,
              paddingBlock: 8,
              borderBottom: "1px solid #a4a4a4",
            }}
          >
            <span
              style={{
                color: items.map((p) => p.id).includes(program.id)
                  ? "#a4a4a4"
                  : "",
              }}
            >
              {program.name}
            </span>
            {!items.map((p) => p.id).includes(program.id) && (
              <Button
                style={{ marginLeft: "auto" }}
                size="small"
                onClick={() => add(program.id)}
                label="add"
              />
            )}
          </li>
        ))}
      </ul>
      {/* <Toolbar role="toolbar" style={styles.toolbar}>
        <Link to="/homepage-rows" style={{ textDecoration: "none" }}>
          <Button size="small" style={styles.button} label="Cancel" />
        </Link>
        <Button
          size="small"
          onClick={restoreDefaultOrder}
          style={styles.button}
          label="Restore Default Order"
        />
        <Button
          size="small"
          onClick={resetOrder}
          style={styles.button}
          label="Reset"
        />
      </Toolbar> */}
    </div>
  );
};

export const ProgramsReorderPage = {
  category: "tbav",
  name: "programs_order",
  options: { label: "Reorder Programs" },
  exact: true,
  icon: ArrowUpDown,
  list: <TbavProgramsReorderPage />,
};
