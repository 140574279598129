import React from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { filterByName } from "../utils";

export class ConventionInput extends React.PureComponent {
  render() {
    const {
      allowEmpty = true,
      label = "Convention",
      source = "convention_id",
      reference = "manage-conventions",
      filterToQuery = filterByName,
      perPage = 100,
      sort = { field: "event_title", order: "ASC" },
      ...rest
    } = this.props;
    return (
      <ReferenceInput
        allowEmpty={allowEmpty}
        label={label}
        source={source}
        reference={reference}
        // filterToQuery={filterToQuery}
        perPage={perPage}
        sort={sort}
        {...rest}
      >
        <AutocompleteInput
          filterToQuery={filterToQuery}
          optionText="event_title"
        />
      </ReferenceInput>
    );
  }
}
