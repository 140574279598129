import { Launch } from "@mui/icons-material";
import { TextField as CoreTextField } from "@mui/material";
import { ScissorsLineDashed, X } from "lucide-react";
import { useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  FunctionField,
  List,
  NumberInput,
  RadioButtonGroupInput,
  SelectArrayInput,
  SimpleForm,
  TextField,
  TextInput,
  UpdateButton,
} from "react-admin";
import {
  CardListActions,
  EditorToolbar,
  getDurationStr,
  MorePagination,
} from "../../components";
import CopySnippet from "./CopySnippet";
import UpdateTypeButton from "./UpdateTypeButton";

function EditView(props) {
  return (
    <Edit {...props} redirect={false}>
      <SimpleForm toolbar={<EditorToolbar showDelete />}>
        <TextField source="id" />
        <TextField source="user_id" label="User ID" />
        <FunctionField
          label="Duration"
          render={(record) => getDurationStr(record.duration)}
        />
        <FunctionField
          style={{ width: "100%" }}
          label="Link"
          render={(record) =>
            record.link && (
              <a target="_blank" href={record.link} rel="noopener noreferrer">
                {record.link}
              </a>
            )
          }
        />
        <FunctionField
          label="Speaker"
          render={(r) => (
            <a
              target="_blank"
              href={`https://www.torahanytime.com/#/speaker?l=${r.lecture_view?.speaker}`}
              rel="noopener noreferrer"
            >
              {`${r.lecture_view?.speaker_short_title} ${r.lecture_view?.speaker_first_name} ${r.lecture_view?.speaker_last_name}`}
            </a>
          )}
        />
        <TextField source="status" label="Status" />
        <TextField label="Clipper Email" source="clipper_email" />
        <DateField
          source="created_at"
          label="Created At"
          showTime
          options={{
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />
        <DateField
          source="completed"
          label="Completed At"
          showTime
          options={{
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />
        <FunctionField
          style={{ width: "100%" }}
          label="Lecture ID"
          render={(record) =>
            record.link && (
              <a
                target="_blank"
                href={`https://www.torahanytime.com/#/lectures?v=${record.videoID}`}
                rel="noopener noreferrer"
              >
                {record.videoID}
              </a>
            )
          }
        />
        <RadioButtonGroupInput
          style={{ width: 200 }}
          label="Clip Type"
          source="type"
          choices={[
            { id: "dose", name: "Dose" },
            { id: "tat_clip", name: "TAT Clip" },
            { id: "plug", name: "Plug" },
            { id: "pending", name: "Pending" },
            { id: "standard", name: "Standard" },
          ]}
          alwaysOn
        />
        <TextField
          source="filename"
          label="filename"
          style={{ width: "100%" }}
        />
        <NumberInput source="dose_id" label="Dose ID" />
        <TextInput source="title" label="Title" />
        <TextInput source="ending" label="Ending" />
        <TextInput source="notes" label="Notes" multiline />
        <EmailClipInfo />
        <BooleanInput source="is_used" label="Clip Used" />
        <BooleanField source="has_intro" label="Clip Has Intro" />
        <TextField source="time_in" label="time_in" />
        <TextField source="uri" label="uri" />
        <FunctionField
          label="Snippet"
          render={(record) => <CopySnippet clip={record} />}
        />
        <FunctionField
          label="Reviewed"
          render={(record) => <UpdateReviewed record={record} />}
        />
      </SimpleForm>
    </Edit>
  );
}

const filters = [
  <SelectArrayInput
    style={{ width: 200 }}
    label="Clip Type"
    source="type"
    choices={[
      { id: "dose", name: "Dose" },
      { id: "tat_clip", name: "TAT Clip" },
      { id: "plug", name: "Plug" },
      { id: "pending", name: "Pending" },
      { id: "standard", name: "Standard" },
      { id: "other", name: "Other" },
    ]}
    alwaysOn
  />,
  <BooleanInput
    source="dose_id_ne"
    label="Unfilled Dose_IDs"
    alwaysOn
    format={(value) => value === 1}
    parse={(value) => (value === true ? 1 : 0)}
  />,
  <TextInput label="User Email" source="user.email" alwaysOn />,
  <TextInput label="Title" source="title" alwaysOn />,
  <TextInput label="Notes" source="notes" alwaysOn />,
  <TextInput label="User ID" source="user_id" />,
  <TextInput label="Lecture ID" source="videoID" />,
  <TextInput label="URL" source="link" />,
  <DateInput
    label="From"
    source="created_at.>"
    alwaysOn
    // format={formatDateTimeInput}
    // parse={parseDateTimeIntUTC}
  />,
  <DateInput
    label="To"
    source="created_at.<"
    alwaysOn
    // format={formatDateTimeInput}
    // parse={parseDateTimeIntUTC}
  />,
  <TextInput label="Min Sec" source="duration.>" />,
  <TextInput label="Max Sec" source="duration.<" />,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={filters}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title="Clip 'N Share"
    >
      <Datagrid
        bulkActionButtons={<UpdateTypeButton />}
        rowSx={(record, index) => ({
          backgroundColor:
            record.type === "tat_clip" || record.type === "dose"
              ? record.is_used
                ? "#d6ffe1"
                : "lightyellow"
              : record.type === "pending"
                ? record.is_used
                  ? "#d6ffe1"
                  : "#dbf2ff"
                : undefined,
          "&:hover": {
            backgroundColor:
              record.type === "tat_clip" || record.type === "dose"
                ? record.is_used
                  ? "#d6ffe1"
                  : "lightyellow"
                : record.type === "pending"
                  ? record.is_used
                    ? "#d6ffe1"
                    : "#dbf2ff"
                  : undefined,
          },
        })}
        isRowSelectable={false}
      >
        <EditButton />
        <FunctionField
          label="Reviewed"
          render={(record) => <UpdateReviewed record={record} />}
        />
        <FunctionField
          label="Snippet"
          render={(record) => <CopySnippet clip={record} />}
        />
        <FunctionField
          label="Clip Link"
          render={(record) => (
            <a rel="noopener noreferrer" target="_blank" href={record.link}>
              <Launch />
            </a>
          )}
        />
        <FunctionField
          label="Type"
          source="type"
          render={(record) =>
            [
              record.type ?? "",
              record.dose_id ? "#" + record.dose_id : "",
            ].join(" ")
          }
        />
        <FunctionField
          label="Duration"
          source="duration"
          render={(record) => getDurationStr(record.duration)}
        />
        <FunctionField
          label="Speaker"
          render={(r) => (
            <a
              target="_blank"
              href={`https://torahanytime.com/speakers/${r.lecture_view?.speaker}`}
              rel="noopener noreferrer"
            >
              {`${r.lecture_view?.speaker_short_title} ${r.lecture_view?.speaker_first_name} ${r.lecture_view?.speaker_last_name}`}
            </a>
          )}
        />
        <DateField
          source="created_at"
          label="Created"
          showTime
          options={{
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />
        <FunctionField
          label="Used"
          render={(record) =>
            !record.is_used ? (
              <UpdateUsed record={record} isUsed={true} />
            ) : (
              <UpdateUsed record={record} isUsed={false} />
            )
          }
        />
        {/* Used a FunctionField since sort is broken */}
        <FunctionField label="Email" render={(r) => `${r.user?.email}`} />
        <FunctionField
          style={{ width: "100%" }}
          label="Lecture ID"
          render={(record) =>
            record.videoID && (
              <a
                target="_blank"
                href={`https://torahanytime.com/lectures/${record.videoID}`}
                rel="noopener noreferrer"
              >
                {record.videoID}
              </a>
            )
          }
        />
        <TextField source="title" label="Title" />
        <TextField source="notes" label="Notes" />
      </Datagrid>
    </List>
  );
}

export const ClipNShare = {
  category: "database",
  name: "manage-clipnshare",
  list: ListView,
  icon: ScissorsLineDashed,
  options: { label: "Clip 'N Share" },
  edit: EditView,
};

const UpdateUsed = ({ record }) => {
  return record.is_used ? (
    <span style={{ display: "flex", alignItems: "center", gap: 6 }}>
      Used
      <UpdateButton
        label=""
        data={{ is_used: false }}
        icon={
          <X
            style={{
              position: "absolute",
              top: "-8px",
              left: 0,
              "&:hover": { backgroundColor: "gray" },
            }}
          />
        }
        sx={{
          color: "red",
          borderRadius: 100,
          minWidth: 0,
          position: "relative",
          ":hover": { background: "transparent" },
        }}
      />
    </span>
  ) : (
    <UpdateButton
      label="Mark Used"
      icon={<></>}
      data={{ is_used: true }}
      variant="outlined"
    />
  );
};

const UpdateReviewed = ({ record }) => {
  return record.reviewed ? (
    <span style={{ display: "flex", alignItems: "center", gap: 6 }}>
      Reviewed
      <UpdateButton
        label=""
        data={{ reviewed: false }}
        icon={
          <X
            style={{
              position: "absolute",
              top: "-8px",
              left: 0,
              "&:hover": { backgroundColor: "gray" },
            }}
          />
        }
        sx={{
          color: "red",
          borderRadius: 100,
          minWidth: 0,
          position: "relative",
          ":hover": { background: "transparent" },
        }}
      />
    </span>
  ) : (
    <UpdateButton
      label="Mark Reviewed"
      icon={<></>}
      data={{ reviewed: true }}
      variant="outlined"
    >
      Mark Reviewed
    </UpdateButton>
  );
};

const EmailClipInfo = () => {
  const [to, setTo] = useState(
    "rachamim@torahanytime.com,shimonkaylyakov@gmail.com",
  );

  return (
    <div style={{ display: "grid", marginBottom: 16 }}>
      <CoreTextField
        style={{
          width: 600,
          marginBottom: 20,
        }}
        title="Emails to send snippet to"
        value={to}
        onChange={(e) => setTo(e.target.value)}
        label="Email To"
      />
      <div>
        <UpdateButton
          variant="outlined"
          resource="clipnshare/email"
          label="Update emails"
          data={{ to }}
        />
      </div>
    </div>
  );
};
