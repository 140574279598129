import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Typography from "@mui/material/Typography";
import React from "react";
import { AppBar } from "react-admin";
import { Link } from "react-router-dom";
import { withRouter } from "../hooks/withRouter";
import { MediaUploadTool } from "../uploader";
import { menuItemsByName } from "./MenuItems";

const styles = {
  crumbs: {
    flex: 1,
    overflow: "hidden",
    textAlign: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  crumb: {
    display: "inline",
  },
  appBar: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: { color: "inherit", cursor: "pointer" },
  arrow: { marginTop: -5, verticalAlign: "middle" },
};

class TitleContentView extends React.PureComponent {
  render() {
    const {
      location: { hash },
    } = window;
    const parts = hash.split("/");
    const menuItem = menuItemsByName[parts[1]];
    let parentTitle;
    let parentURL;
    if (menuItem) {
      if (parts.length > 2) {
        if (menuItem.hasList) {
          parentTitle = menuItem.label;
          parentURL = menuItem.url;
        }
      }
      window.document.title = `${menuItem.label} | TA Admin`;
    } else {
      window.document.title = `TA Admin`;
    }
    return (
      <div style={styles.crumbs}>
        {parentTitle && (
          <>
            <Link to={parentURL} style={styles.link}>
              <ArrowLeftIcon style={styles.arrow} />
              <Typography variant="title" color="inherit" style={styles.crumb}>
                {parentTitle}
              </Typography>
            </Link>
            <Typography variant="title" color="inherit" style={styles.crumb}>
              &nbsp;&nbsp;/&nbsp;&nbsp;
            </Typography>
          </>
        )}
        <Typography
          variant="title"
          color="inherit"
          id="react-admin-title"
          style={styles.crumb}
        />
      </div>
    );
  }
}

const TitleContent = withRouter(TitleContentView);

export default class CustomAppBar extends React.Component {
  render() {
    return (
      <AppBar {...this.props} position="fixed">
        <div className="appBar" style={styles.appBar}>
          <TitleContent />
          <MediaUploadTool />
        </div>
      </AppBar>
    );
  }
}
