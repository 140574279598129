import { Component } from "react";
// import { createFullImgUrl } from '../../lib';

import { Paper } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = {
  paper: {
    position: "relative",
    margin: 50,
    height: 90,
    width: 180,
  },
  container: {
    position: "relative",
    overflowY: "hidden",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    // backgroundSize: 'cover',
    "&:hover": {
      boxShadow: "0 0 6px grey",
    },
  },
  followIcon: {
    borderRadius: 15,
    border: "solid 1px black",
    background: "white",
    position: "absolute",
    right: 0,
    marginTop: 5,
    marginRight: 5,
    cursor: "pointer",
  },
  tile: {
    height: 72,
  },
  title: {
    fontSize: 18,
    lineHeight: 1.6,
    letterSpacing: 0.5,
  },
  info: {
    fontSize: 12,
    opacity: 0.75,
    lineHeight: 2,
    letterSpacing: 0.5,
  },
  lectureList: {
    marginBottom: 4,
  },
  lectureItem: {
    height: 75,
    padding: 10,
    // backgroundColor: 'white',
    // padding: '12px 0',
    // fontSize: 12,
    // lineHeight: 1.4,
    "&:hover": {
      background: "rgba(0,0,0,.05)",
    },
  },
  lectureItemPic: {
    width: 94,
    height: 62,
    overflow: "hidden",
    objectFit: "cover",
  },
  img: {
    maxHeight: "100%",
    margin: "0 auto",
  },
  lectureItemText: {
    width: 72,
    padding: "0 0 0 6%",
    height: 66,
    overflow: "hidden",
  },
  lectureItemTitle: {
    height: 34,
    overflow: "hidden",
    fontWeight: "bold",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  collapse: {
    position: "relative",
  },
  info_container: {
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
  },
};

class WideCardView extends Component {
  render() {
    const { classes, photo, containImage } = this.props;

    const backgroundImage = `url("${photo}")`;

    return (
      <Paper className={classes.paper}>
        <div
          className={classes.container}
          style={{
            height: "100%",
            backgroundImage,
            backgroundSize: containImage ? "contain" : "cover",
          }}
        />
      </Paper>
    );
  }
}

export const WideCard = withStyles(styles)(WideCardView);
