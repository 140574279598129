import Typography from "@mui/material/Typography";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { returnArg, sanitizeFieldProps } from "../utils";

export class UrlTextField extends React.PureComponent {
  static defaultProps = {
    addLabel: true,
  };
  static propTypes = {
    addLabel: PropTypes.bool,
    basePath: PropTypes.string,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    sortBy: PropTypes.string,
    source: PropTypes.string.isRequired,
  };
  render() {
    const {
      className,
      formatURL = returnArg,
      source,
      sourceText,
      record = {},
      ...rest
    } = this.props;
    return (
      <Typography
        component="a"
        body1="body1"
        className={className}
        href={formatURL(get(record, source))}
        {...sanitizeFieldProps(rest)}
      >
        {get(record, sourceText)}
      </Typography>
    );
  }
}
