// import { Infinity } from "lucide-react";
import resourceIcon from "@mui/icons-material/SwapHoriz";
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  useInput,
  required,
  // AutocompleteInput,
  NumberField,
  SimpleForm,
  TextField,
  useRecordContext,
  RadioButtonGroupInput,
  TextInput,
} from "react-admin";
import { useController } from "react-hook-form";
import { CardListActions, EditorToolbar, validation } from "../../components";
import DatePickerInputViewer from "../../components/input/DatePickerInputViewer";
import HebrewDatepickerInput from "../../components/input/HebrewDatepickerInput";
import AudioRecorderInput from "../../components/input/AudioRecorderInput";
import DeleteIcon from "@mui/icons-material/Delete";
import Icon from "@mui/material/Icon";
import { useState } from "react";

// const dedication_type_choice = [
//   {
//     id: "L'iluy Nishmat / Soul Elevation",
//     name: "L'iluy Nishmat / Soul Elevation",
//   },
//   {
//     id: "Refuah Shelema / Speedy Recovery",
//     name: "Refuah Shelema / Speedy Recovery",
//   },
// ];
// const package_choices = [
//   { id: "100 Classes Dedicated ($100)", name: "100 Classes Dedicated ($100)" },
//   { id: "500 Classes Dedicated ($360)", name: "500 Classes Dedicated ($360)" },
//   {
//     id: "1500 Classes Dedicated ($1000)",
//     name: "1500 Classes Dedicated ($1000)",
//   },
// ];
// const list_states = [
//   { id: "AL", name: "AL" },
//   { id: "AK", name: "AK" },
//   { id: "AZ", name: "AZ" },
//   { id: "AR", name: "AR" },
//   { id: "CA", name: "CA" },
//   { id: "CO", name: "CO" },
//   { id: "DE", name: "DE" },
//   { id: "DC", name: "DC" },
//   { id: "FL", name: "FL" },
//   { id: "GA", name: "GA" },
//   { id: "HI", name: "HI" },
//   { id: "ID", name: "ID" },
//   { id: "IL", name: "IL" },
//   { id: "IN", name: "IN" },
//   { id: "IA", name: "IA" },
//   { id: "KS", name: "KS" },
//   { id: "KY", name: "KY" },
//   { id: "LA", name: "LA" },
//   { id: "ME", name: "ME" },
//   { id: "MD", name: "MD" },
//   { id: "MA", name: "MA" },
//   { id: "MI", name: "MI" },
//   { id: "MN", name: "MN" },
//   { id: "MS", name: "MS" },
//   { id: "MO", name: "MO" },
//   { id: "MT", name: "MT" },
//   { id: "NE", name: "NE" },
//   { id: "NV", name: "NV" },
//   { id: "NH", name: "NH" },
//   { id: "NJ", name: "NJ" },
//   { id: "NM", name: "NM" },
//   { id: "NY", name: "NY" },
//   { id: "NC", name: "NC" },
//   { id: "ND", name: "ND" },
//   { id: "OH", name: "OH" },
//   { id: "OK", name: "OK" },
//   { id: "OR", name: "OR" },
//   { id: "PA", name: "PA" },
//   { id: "RI", name: "RI" },
//   { id: "SC", name: "SC" },
//   { id: "SD", name: "SD" },
//   { id: "TN", name: "TN" },
//   { id: "TX", name: "TX" },
//   { id: "UT", name: "UT" },
//   { id: "VT", name: "VT" },
//   { id: "VA", name: "VA" },
//   { id: "WA", name: "WA" },
//   { id: "WV", name: "WV" },
//   { id: "WI", name: "WI" },
//   { id: "WY", name: "WY" },
// ];

const filters = [
  <NumberField source="id" label="Id" alwaysOn key="id" />,
  <DateInput
    source="dedication_date"
    label="Dedication Date"
    alwaysOn
    key="dedication_date"
  />,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={filters}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Sponsor Dedications"
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="ID" validate={[validation.required()]} />
        <TextField source="dedication_sponsor" label="Dedication Sponsor" />
        <TextField
          source="dedication_sponsor_relationship"
          label="Dedication Sponsor Relationship"
        />
        {/* <TextField
          source="dedication_type"
          label="Dedication Type"
        /> */}
        {/* <TextField source="email" label="Email" /> */}
        {/* <TextField
          source="dedication_relationship"
          label="Dedication Relationship"
        /> */}
        <DateField source="dedication_date" label="Dedication Date" />
        <TextField source="dedication_hebrew_date" label="Hebrew Date" />
      </Datagrid>
    </List>
  );
}
const SponsorNameField = () => {
  const { field: dedicationBy } = useController({
    name: "dedication_anonymous",
    defaultValue: "",
  });
  const { field: dedicationSponsor } = useController({
    name: "dedication_sponsor",
    defaultValue: "",
  });
  // console.log('dedicationBy.value',dedicationBy.value)
  return (
    dedicationBy &&
    dedicationBy.value == 1 && (
      <TextInput source="dedication_sponsor" label="Sponsor Name" />
    )
  );
};
const DedicationRecordingField = () => {
  const record = useRecordContext(); // Get the current record
  const [showRecording, setShowRecording] = useState(true);
  const {
    field,
    formState: { isSubmitting },
  } = useInput({ source: "audio_url" });

  return record?.audio_path && showRecording ? (
    <>
      <h4>Dedication Recording</h4>
      {/* biome-ignore lint/a11y/useMediaCaption: we don't have captions for on-the-fly recordings */}
      <div className="recorder-item d-flex justify-center items-center">
        <audio
          id="audioPlayback-list"
          src={record.audio_url + `?v=` + Date.now()}
          controls
          preload="auto"
        />
        <Icon
          component={DeleteIcon}
          onClick={() => {
            setShowRecording(false);
            field.onChange("");
          }}
          sx={{
            color: "red",
            cursor: "pointer",
            //   position: "absolute",
            //   right: "10px",
            //   zIndex: "9",
            //   top: "10px",
          }}
        />
        <br />
      </div>
    </>
  ) : null;
};

const RenderEditForm = (redirect) => {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      {/* <NumberField source="id" label="Id" /> */}
      <h4>Dedication Date</h4>
      {/* <DatePickerInputViewer /> */}
      <HebrewDatepickerInput source="dedication_date" />
      {/* <TextInput
        source="dedication_hebrew_date"
        label="Hebrew Date"
        validate={[validation.required()]}
      /> */}
      <h4>Dedication by</h4>
      <RadioButtonGroupInput
        source="dedication_anonymous"
        label=""
        choices={[
          { id: "1", name: "Sponsor Name" },
          { id: "2", name: "Anonymous" },
          { id: "3", name: "Not to show" },
        ]}
        validate={[required()]}
      />
      <SponsorNameField />
      {/* <TextInput source="dedication_sponsor" label="Sponsor Name" /> */}
      <h4>Dedication to</h4>
      <TextInput
        source="dedication_sponsor_relationship"
        label="Sponsor Relationship"
        validate={[validation.required()]}
      />
      <DedicationRecordingField />
      <AudioRecorderInput source="audio" />
    </SimpleForm>
  );
};

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {RenderEditForm({ redirect: false })}
    </Edit>
  );
}
function CreateView(props) {
  return <Create {...props}>{RenderEditForm({ redirect: "edit" })}</Create>;
}
export const SponsorDedications = {
  category: "database",
  name: "sponsor_dedications",
  icon: resourceIcon,
  list: ListView,
  edit: EditView,
  create: CreateView,
  options: { label: "Sponsor Dedications" },
};
