import { withStyles } from "@mui/styles";
import { Layout, Sidebar, useSidebarState } from "react-admin";
import AppBar from "./AppBar";
import Menu from "./Menu";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginLeft: 10,
    padding: theme.spacing.unit * 3,
    paddingTop: 60,
    // [theme.breakpoints.up("xs")]: {
    //   paddingLeft: 5,
    // },
    // NOTE: Removed this rule as it conflicts with a fixed app bar.
    // [theme.breakpoints.down("sm")]: {
    //   padding: 0,
    // },
  },
});

const AppSidebar = (props) => {
  const [open, setOpen] = useSidebarState();
  return (
    <Sidebar
      {...props}
      size={300}
      style={{ position: "relative", minWidth: open ? 300 : undefined }}
    />
  );
};
export const AppLayout = withStyles(styles)((props) => (
  <Layout {...props} appBar={AppBar} menu={Menu} sidebar={AppSidebar} />
));
