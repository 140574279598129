import { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useNotify } from "react-admin";

import { authClient, authUser } from "../../server";

export const EmailTab = function (props) {
  const {
    showNotification,
    record: { id },
  } = props;
  const [testEmailAddresses, setTestEmailAddresses] = useState(
    authUser.email || "",
  );
  const notify = useNotify();

  const [dialogOpen, setDialogOpen] = useState(false);

  async function submit() {
    await authClient.post(`/admin/daily_dose/${id}/email`, {
      recipients: testEmailAddresses
        .split(/[\n,]/)
        .map((e) => e.trim())
        .filter((e) => e !== ""),
    });
    setDialogOpen(false);
    notify("email.submitted");
  }

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you would like to send the email to all subscribers?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Pressing the send button will send this Dose to all subscribers.
            They may have already gotten it.
            <br />
            Also, please make sure to cancel any pending scheduled jobs for this
            email.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => submit()} color="primary" autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <TextField
        fullWidth
        label="Email Addresses"
        name="emailAddresses"
        value={testEmailAddresses}
        onChange={(event) => setTestEmailAddresses(event.target.value)}
        // style={{
        //   marginTop: 20,
        //   marginBottom: 20,
        //   display: "block",
        // }}
        multiline
        placeholder="Email address(es)"
        helperText="If this is empty, it will send to all subscribers. Put a comma or new lines for multiple addresses."
      />
      <Button
        onClick={() =>
          testEmailAddresses.length > 0 ? submit() : setDialogOpen(true)
        }
      >
        {testEmailAddresses.length > 0
          ? "Send Test Emails"
          : "Send To All Subscribers"}
      </Button>
    </div>
  );
};
