import RemoveCircle from "@mui/icons-material/RemoveCircle";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { Component } from "react";

export class SimpleFileInputPreview extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    file: PropTypes.object,
    onRemove: PropTypes.func.isRequired,
    revokeObjectURL: PropTypes.func,
  };

  static defaultProps = {
    file: undefined,
    translate: (id) => id,
  };

  componentWillUnmount() {
    const { file, revokeObjectURL } = this.props;

    if (file.preview) {
      revokeObjectURL
        ? revokeObjectURL(file.preview)
        : window.URL.revokeObjectURL(file.preview);
    }
  }

  render() {
    const {
      children,
      classes = {},
      className,
      onRemove,
      revokeObjectURL,
      file,
      translate,
      ...rest
    } = this.props;

    return (
      <div className={className} {...rest}>
        <IconButton
          className={classes.removeButton}
          onClick={onRemove}
          title={translate("ra.action.delete")}
        >
          <RemoveCircle className={classes.removeIcon} />
        </IconButton>
        {children}
      </div>
    );
  }
}

export default SimpleFileInputPreview;
