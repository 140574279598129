import React, { useState, useEffect, useRef } from "react";
import { useInput } from "react-admin";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Icon from "@mui/material/Icon";
import "../../resources/Sponsor/DatePicker.css";

const AudioRecorderInput = ({ source }) => {
  const {
    field,
    formState: { isSubmitting },
  } = useInput({ source });

  const [recordingStarted, setRecordingStarted] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  // const audioRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(false);
  // const [currentTime, setCurrentTime] = useState(0); // Current time of the audio
  // const [progress, setProgress] = useState(0); // Progress of the audio (percentage)

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);
    // Pad single digit seconds with leading zero
    const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${paddedSeconds}`;
  };

  // const togglePlayPause = () => {
  //   if(currentTime >= secondsElapsed){
  //     setProgress(0)
  //   }
  //   if (isPlaying) {
  //     audioRef.current.pause();
  //   } else {
  //     audioRef.current.play();
  //   }
  //   setIsPlaying(!isPlaying); // Toggle the play/pause state
  // };
  
  // const handleTimeUpdate = () => {
  //   const current = audioRef.current.currentTime;
  //   setCurrentTime(current);
  //   setProgress((current / secondsElapsed) * 100);
  // };

  // const handleAudioEnded = () => {
  //   setIsPlaying(false);
  // };

  // Timer effect
  useEffect(() => {
    let interval = null;
    if (recordingStarted) {
      interval = setInterval(() => {
        setSecondsElapsed((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Cleanup on component unmount or stop recording
  }, [recordingStarted]);

  const handleRecord = async () => {
    setRecordedAudio("");
    setRecordingStarted(true);
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const newMediaRecorder = new MediaRecorder(stream);

    function blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    newMediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.push(event.data);
      }
    };

    newMediaRecorder.onstop = async () => {
      const blob = new Blob(recordedChunks, { type: "audio/mp3" });
      const base64audio = await blobToBase64(blob)
        .then((base64String) => {
          // base64String contains the data in the format "data:audio/mp3;base64,...."
          // console.log('base64audioPRE',base64String);
          return base64String;
        })
        .catch((error) => {
          console.error("Error converting blob to base64:", error);
        });
      // console.log(base64audio,'base64audio');
      const audioUrl = URL.createObjectURL(blob);

      setRecordedAudio(audioUrl);
      field.onChange(base64audio);
      setRecordedChunks([]);
      setRecordingStarted(false);
    };

    newMediaRecorder.start();
    setMediaRecorder(newMediaRecorder);
  };

  const handleRecordStoped = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setMediaRecorder(null);
      setRecordedChunks([]);
    }
  };

  // console.log('currentTime',currentTime)
  // console.log('seconds',secondsElapsed)

  const recordingBarWidth = `${(secondsElapsed % 60) * (100 / 60)}%`;

  return (
    <div>
      <h1>Record and Save Audio</h1>

      {recordedAudio && (
        <>
          {/* biome-ignore lint/a11y/useMediaCaption: we don't have captions for on-the-fly recordings */}
          <div className="recorder-item">
            <audio
              // ref={audioRef}
              id="audioPlayback"
              src={recordedAudio}
              // onTimeUpdate={handleTimeUpdate} // Update time on timeupdate event
              // onEnded={handleAudioEnded}
              controls
              preload="auto"
            />
            {/* <div className="recording-section">
              <Icon
                onClick={togglePlayPause}
                className=""
                component={isPlaying ? PauseIcon : PlayArrowIcon}
                sx={{
                  cursor:"pointer"
                }}
              />
              <div className="recording-section-inner">
                <div
                  style={{
                    width: `${progress}%`, 
                    height: '100%', 
                    backgroundColor: "black",
                    transition: "width 1s linear",
                  }}
                />
              </div>
              <span>{formatTime(currentTime)}</span> / <span>{formatTime(secondsElapsed )}</span>
            </div> */}
            
            <Icon
              component={DeleteIcon}
              onClick={() => {
                setRecordedChunks([]);
                setRecordedAudio("");
                field.onChange("");
                setSecondsElapsed(0);
                // setProgress(0);
                // setCurrentTime(0)
              }}
              sx={{
                color: "red",
                cursor: "pointer",
              }}
            />
          </div>{" "}
        </>
      )}
      {recordingStarted && (
        <div className="recording-section recorder">
          <Icon
            className="blinking-rec"
            component={RadioButtonCheckedIcon}
            sx={{
              color: "red",
            }}
          />
          <div className="recording-section-inner">
            <div
              style={{
                width: recordingBarWidth,
                height: "100%",
                backgroundColor: "black",
                transition: "width 1s linear",
              }}
            />
          </div>
          {secondsElapsed ? formatTime(secondsElapsed) : "00:00"}
        </div>
      )}

      {!recordedAudio && (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleRecord()}
            disabled={recordingStarted || isSubmitting}
          >
            {recordingStarted ? "Recording..." : "Start Recording"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleRecordStoped()}
            disabled={!recordingStarted || isSubmitting}
          >
            Stop Recording
          </Button>
        </>
      )}
    </div>
  );
};

export default AudioRecorderInput;
