import React from "react";
import {
  // BulkDeleteButton,
  Button,
  useListContext,
  useNotify,
  useRecordSelection,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { authClient } from "../../server";

export const PublishingStatuses = [
  { id: 500, name: "error" },
  { id: 1, name: "submitting" },
  { id: 2, name: "queued" },
  { id: 3, name: "rendering" },
  { id: 4, name: "encoding" },
  { id: 5, name: "finished" },
];

class ArchiveButtonView extends React.PureComponent {
  onClick = async () => {
    if (!window.confirm("Archive the selected uploads?")) {
      return;
    }
    const { resource, selectedIds } = this.props;
    // console.log("ARCHIVING: ", { resource, selectedIds });
    // TODO: Warn the user if any items didn't get sent.
    const [selected, setListSelectedIds] = useRecordSelection(selectedIds);

    const refreshView = useRefresh();
    const showNotification = useNotify();

    const results = await authClient
      .post("/uploads/send-to-log", {
        ids: selectedIds,
      })
      .catch((err) => {
        console.error(err);
        showNotification("uploads.archived", "warning");
      });
    if (results) {
      // console.log('ARCHIVED: ', results);
      showNotification("uploads.archived");
      setListSelectedIds.select(resource, []);
      refreshView();
    }
  };
  render() {
    return <Button label="Archive" onClick={this.onClick} />;
  }
}
const ArchiveButton = () => {
  const { selectedIds, resource } = useListContext();
  const refreshView = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  // const [updateMany, { isLoading }] = useUpdateMany();
  const handleClick = async () => {
    if (!window.confirm("Archive the selected uploads?")) {
      return;
    }
    // console.log("ARCHIVING: ", { resource, selectedIds });
    // TODO: Warn the user if any items didn't get sent.

    const results = await authClient
      .post("/uploads/send-to-log", {
        ids: selectedIds,
      })
      .catch((err) => {
        console.error(err);
        notify("uploads.archived", "warning");
      });
    if (results) {
      // console.log('ARCHIVED: ', results);
      notify("uploads.archived");
      unselectAll();
      refreshView();
    }
  };

  return <Button label="Archive" onClick={handleClick}></Button>;
};

const VimeoDownloadButton = () => {
  const { selectedIds, resource } = useListContext();
  const refreshView = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  // const [updateMany, { isLoading }] = useUpdateMany();
  const handleClick = async () => {
    if (!window.confirm("ReAttempt downloading the selected uploads?")) {
      return;
    }

    // console.log("ARCHIVING: ", { resource, selectedIds });
    // TODO: Warn the user if any items didn't get sent.
    const results = await authClient
      .post("/uploads/reattempt-vimeo-download", {
        ids: selectedIds,
      })
      .catch((err) => {
        console.error(err);
        notify(err?.body?.message || "Something Went Wrong", "warning");
      });
    if (results) {
      // console.log('ARCHIVED: ', results);
      notify("Vimeo-Download Successful");
      unselectAll();
      refreshView();
    }
  };

  return <Button label="Vimeo-Download" onClick={handleClick}></Button>;
};

const RepublishButton = () => {
  const { selectedIds, resource } = useListContext();
  const refreshView = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  // const [updateMany, { isLoading }] = useUpdateMany();
  const handleClick = async () => {
    if (!window.confirm("Republish the selected uploads?")) {
      return;
    }

    // console.log("REPUBLISHING: ", { resource, selectedIds });
    // TODO: Warn the user if any items didn't get republished.
    const results = await authClient
      .post("/uploads/republish", {
        ids: selectedIds,
      })
      .catch((err) => {
        console.error(err);
        notify("uploads.republished", "warning");
      });
    if (results) {
      // console.log('REPUBLISHED: ', results);
      notify("uploads.republished");
      unselectAll();
      refreshView();
    }
  };

  return <Button label="Republish" onClick={handleClick}></Button>;
};

export function BulkActionsView(props) {
  const { resource } = props;
  return (
    <React.Fragment>
      {resource === "uploads-rendering" && <ArchiveButton {...props} />}
      <VimeoDownloadButton {...props} />
      <RepublishButton {...props} />
      {/* <BulkDeleteButton {...props} /> */}
    </React.Fragment>
  );
}
