import { Type } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CardListActions, EditorToolbar } from "../../components";

const EditTitle = () => <span>{`Speaker Title`}</span>;

function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <TextInput source="title" label="Title" />
      <TextInput source="title_short" label="Short title" />
    </SimpleForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  console.log({ edit: props });
  return (
    <Edit {...props} redirect={false} undoable={false} title={<EditTitle />}>
      {renderEditForm()}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      perPage={25}
      sort={{ field: "title", order: "ASC" }}
      title="Speaker Titles"
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="title" label="Title" />
        <TextField source="title_short" label="Short title" />
        <NumberField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const SpeakerTitles = {
  category: "database",
  name: "speaker-titles",
  create: CreateView,
  edit: EditView,
  icon: Type,
  list: ListView,
  options: { label: "Speaker Titles" },
};
