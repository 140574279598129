import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { authClient } from "../../server";
//@ts-ignore
import SortablePage from "../../components/page/SortableFormPage.tsx";
import { SortItem } from "../../../types/sortable";
import { useNotify } from "ra-core";
import { ChevronDown } from "lucide-react";

const Player = () => {
  const notify = useNotify();
  const [items, setItems] = useState<SortItem[]>([]);
  const [playerName, setPlayerName] = useState("");

  useEffect(() => {
    setPlayerName(window.location.hash.split("/")[3]);
  }, [window.location]);

  useEffect(() => {
    if (playerName)
      authClient
        .get(`/admin/manage-player?playerName=${playerName}`)
        .then((res) => {
          console.log({ res });
          setItems(res.json.items);
        })
        .catch((error) => notify(error.body.message, { type: "error" }));
  }, [playerName]);

  const handleSave = async () => {
    const data = { items, playerName };
    //@ts-ignore
    return authClient.put("/admin/manage-player", data);
  };

  return (
    <SortablePage
      title={"Manage Embedded Video Player"}
      items={items}
      setItems={setItems}
      renderItem={(item, index, onChange) => (
        <PlayerItem item={item} index={index} onChange={onChange} />
      )}
      handleSave={handleSave}
      createItem={() => {
        return {
          code: "",
          link: "",
          title: "",
        };
      }}
    />
  );
};

export default Player;

const PlayerItem = ({ item, index, onChange }) => {
  return (
    <details>
      <summary className="playerSummary">
        <div>
          <span>{item.title}</span>
          <ChevronDown className="openclose" />
        </div>
      </summary>
      <TextField
        fullWidth
        label="Title"
        value={item.title}
        onChange={(e) => onChange(index, "title", e.target.value)}
        required
        error={!item.title}
      />
      <TextField
        fullWidth
        label="Link URL"
        value={item.link}
        onChange={(e) => onChange(index, "link", e.target.value)}
        required
        error={!item.link}
      />
      <TextField
        fullWidth
        label="code (for use in url - no spaces and all lowercase)"
        value={item.code}
        onChange={(e) => onChange(index, "code", e.target.value)}
        required
        error={!item.code}
      />
    </details>
  );
};

export const PlayerPage = {
  menu: false,
  url: "/misc/manage-player/:player",
  Element: <Player />,
};
