import { ShieldCheck } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  FunctionField,
  List,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CardListActions, EditorToolbar, SpeakerInput } from "../../components";

const EditTitle = () => <span>{`User Speaker Permissions`}</span>;

function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <SpeakerInput source="speaker_id" />
      <TextInput source="user_id" />
      <SelectInput
        source="permission"
        choices={[
          { id: "upload-lecture", name: "Upload Lectures" },
          { id: "replace-lecture", name: "Replace Lectures" },
          { id: "view-stats", name: "View Stats" },
        ]}
      />
    </SimpleForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  console.log({ edit: props });
  return (
    <Edit {...props} redirect={false} undoable={false} title={<EditTitle />}>
      {renderEditForm()}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      //   exporter={true}
      filters={[
        <TextInput source="user_id" alwaysOn placeholder="User ID" />,
        <SpeakerInput
          label="Speaker"
          source="speaker_id"
          reference="speakers"
          alwaysOn
        />,
      ]}
      perPage={25}
      sort={{ field: "user_id", order: "ASC" }}
      title="User Speaker Permissions"
    >
      <Datagrid rowClick="edit">
        <ReferenceField source="speaker_id" reference="speakers">
          <FunctionField
            label="Name"
            render={(record) => `${record.name_first} ${record.name_last}`}
          />
        </ReferenceField>
        <TextField source="user_id" label="User ID" />
        <ReferenceField source="user_id" reference="users" label="User Email">
          <TextField source="email" />
        </ReferenceField>
        <TextField source="permission" />
        {/* <TextField source="title" label="Title" />
        <TextField source="title_short" label="Short title" />
        <NumberField source="id" label="Id" /> */}
      </Datagrid>
    </List>
  );
}

export const UserSpeakerPermissions = {
  category: "stats",
  name: "user-speaker-permissions",
  create: CreateView,
  edit: EditView,
  icon: ShieldCheck,
  list: ListView,
  options: { label: "User-Speaker Permissions" },
};
