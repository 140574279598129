import { Infinity } from "lucide-react";
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  // AutocompleteInput,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CardListActions, EditorToolbar, validation } from "../../components";

const filters = [
  <NumberField source="id" label="Id" alwaysOn />,
  <TextInput source="first_name" label="First Name" alwaysOn />,
  <TextInput source="last_name" label="Last Name" alwaysOn />,
  <TextInput source="email" label="Email" alwaysOn />,
  <DateInput source="start_date" label="Start Date" />,
  <DateInput source="end_date" label="End Date" />,
  <TextInput source="hebrew_date" label="Hebrew Date" />,
  <ReferenceInput
    label="Campaign Package"
    source="campaign_pckg_id"
    reference="campaign_packages"
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    label="Transaction ID"
    source="txn_id"
    reference="campaign_donations"
  >
    <SelectInput optionText="txn_id" />
  </ReferenceInput>,
  <TextInput source="message" label="Dedication Message" />,
  <ReferenceInput
    label="dedication Lecture"
    source="lecture_id"
    reference="lectures"
  >
    <NumberInput source="lecture_id" label="Lecture ID" />
  </ReferenceInput>,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={filters}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Campaign Dedications"
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="ID" />
        <TextField source="first_name" label="First Name" />
        <TextField source="last_name" label="Last Name" />
        <TextField source="email" label="Email" />
        <DateField source="start_date" label="Start Date" />
        <DateField source="end_date" label="End Date" />
        <TextField source="hebrew_date" label="Hebrew Date" />
        <ReferenceField
          label="Campaign Package"
          source="campaign_pckg_id"
          reference="campaign_packages"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Transaction ID"
          source="txn_id"
          reference="campaign_donations"
          allowEmpty
        >
          <TextField source="txn_id" />
        </ReferenceField>
        <TextField source="message" label="Dedication Message" />
        <ReferenceField
          label="dedication Lecture"
          source="lecture_id"
          reference="lectures"
          allowEmpty
        >
          <NumberField source="id" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
}
function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <NumberField source="id" label="Id" />
      <TextInput source="first_name" label="First Name" />
      <TextInput source="last_name" label="Last Name" />
      <TextInput source="email" label="Email" />
      <DateInput
        source="start_date"
        label="Start Date"
        validate={[validation.required()]}
      />
      <DateInput
        source="end_date"
        label="End Date"
        validate={[validation.required()]}
      />
      <TextInput source="hebrew_date" label="Hebrew Date" />
      <ReferenceInput
        label="Campaign Package"
        source="campaign_pckg_id"
        reference="campaign_packages"
        validate={[validation.required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      {/* TODO: Consider making this a reference input */}
      <TextInput source="txn_id" label="Transaction ID" />
      <TextInput
        source="message"
        label="Dedication Message"
        validate={[validation.required()]}
      />
      <NumberInput source="lecture_id" label="Lecture ID" />
    </SimpleForm>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      {renderEditForm()}
    </Edit>
  );
}
function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm()}
    </Create>
  );
}
export const CampaignDedications = {
  category: "database",
  name: "campaign_dedications",
  icon: Infinity,
  list: ListView,
  edit: EditView,
  create: CreateView,
  options: { label: "Campaign Dedications" },
};
