import { ListTree } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  FunctionField,
  List,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";
import { CardListActions } from "../../components";
import { JsonInput } from "../../components/input/JsonEditor";

function BasicToolbar() {
  return (
    <Toolbar>
      <SaveButton label="Save" />
    </Toolbar>
  );
}

function RenderEditForm() {
  return (
    <SimpleForm toolbar={<BasicToolbar />}>
      <TextInput source="id" />
      <JsonInput source="data" />
    </SimpleForm>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} mutationMode="optimistic">
      {RenderEditForm({ props: props })}
    </Edit>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit" mutationMode="optimistic">
      {RenderEditForm()}
    </Create>
  );
}
function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={false}
      perPage={25}
      sort={{ field: "id", order: "ASC" }}
      title="Dynamic Files"
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="ID" />
        <FunctionField
          source="data"
          label="Contents"
          render={(record) => JSON.stringify(record.data)}
        />
      </Datagrid>
    </List>
  );
}

export const DynamicFiles = {
  category: "manage",
  name: "dynamic_files",
  edit: EditView,
  icon: ListTree,
  list: ListView,
  create: CreateView,
  options: { label: "Arbitrary JSON" },
};
