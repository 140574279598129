import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { authClient } from "../../server";

export const SchedulesTab = function (props) {
  const {
    useNotify,
    record: { id },
  } = props;

  const [schedules, setSchedules] = useState(undefined);

  useEffect(() => {
    load();
  }, []);

  async function load() {
    const response = await authClient.get(
      `/admin/manage-schedules?_end=10&_order=DESC&_sort=run_at&_start=0&payload.id=${id}`,
    );

    setSchedules(response.json);
  }

  return (
    <div>
      {/* {schedules} */}
      {schedules &&
        schedules.map((schedule) => (
          <div key={schedule.id}>
            <div>{schedule.note}</div>
            <div>
              <span> {new Date(schedule.run_at).toLocaleString()}</span>{" "}
              {schedule.status === "pending" && (
                <Link to={`/manage-schedules/${schedule.id}`}>Edit</Link>
              )}
            </div>

            <div>Status: {schedule.status}</div>

            <br />
          </div>
        ))}
    </div>
  );
};
