import MuiToolbar from "@mui/material/Toolbar";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Children, Fragment, useEffect } from "react";
import {
  DeleteButton,
  SaveButton,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useFormState } from "react-hook-form";

const styles = (theme) => ({
  toolbar: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
  },
  desktopToolbar: {
    marginTop: theme.spacing.unit * 2,
  },
  mobileToolbar: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "16px",
    width: "100%",
    boxSizing: "border-box",
    flexShrink: 0,
    zIndex: 2,
  },
  defaultToolbar: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  spacer: {
    [theme.breakpoints.down("xs")]: {
      height: "5em",
    },
  },
});

const valueOrDefault = (value, defaultValue) =>
  typeof value === "undefined" ? defaultValue : value;

class EditorToolbarView extends React.PureComponent {
  handleSubmitWithRedirect = (redirect) => {
    const {
      handleSubmitWithRedirect,
      pristine,
      savePristine = false,
    } = this.props;
    if (pristine && !savePristine) {
      return () => undefined;
    }
    return handleSubmitWithRedirect(redirect);
  };

  componentDidMount() {
    const { registerTrigger } = this.props;
    if (registerTrigger) {
      registerTrigger(this);
    }
  }

  componentWillUnmount() {
    const { registerTrigger } = this.props;
    if (registerTrigger) {
      registerTrigger(null);
    }
  }

  render() {
    const {
      basePath,
      children,
      classes,
      className,
      handleSubmit,
      handleSubmitWithRedirect: _handleSubmitWithRedirect,
      invalid,
      pristine,
      record,
      redirect,
      registerTrigger: _registerTrigger,
      resource,
      saving,
      showDelete = false,
      showSave = true,
      submitOnEnter,
      undoable: _undoable,
      width,
      alwaysEnable,
      ...rest
    } = this.props;
    return (
      <Fragment>
        <MuiToolbar
          className={classnames(
            styles.toolbar,
            {
              [styles.mobileToolbar]: width === "xs",
              [styles.desktopToolbar]: width !== "xs",
            },
            className,
          )}
          role="toolbar"
          {...rest}
        >
          <div className={styles.defaultToolbar}>
            {showSave && (
              <SaveButton
                handleSubmitWithRedirect={this.handleSubmitWithRedirect}
                invalid={invalid}
                redirect={redirect}
                saving={saving}
                alwaysEnable={alwaysEnable}
                // submitOnEnter={submitOnEnter} //depracated
              />
            )}
            {Children.map(children, (button) =>
              button
                ? button.props.toolbarProps
                  ? React.cloneElement(button, {
                      basePath,
                      handleSubmit: valueOrDefault(
                        button.props.handleSubmit,
                        handleSubmit,
                      ),
                      handleSubmitWithRedirect: valueOrDefault(
                        button.props.handleSubmitWithRedirect,
                        this.handleSubmitWithRedirect,
                      ),
                      invalid,
                      pristine,
                      record,
                      saving,
                      submitOnEnter: valueOrDefault(
                        button.props.submitOnEnter,
                        submitOnEnter,
                      ),
                    })
                  : button
                : null,
            )}
            {showDelete && record && typeof record.id !== "undefined" && (
              <DeleteButton
                basePath={basePath}
                record={record}
                resource={resource}
                undoable={false}
              />
            )}
          </div>
        </MuiToolbar>
        <div className={styles.spacer} />
      </Fragment>
    );
  }
}

EditorToolbarView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleSubmitWithRedirect: PropTypes.func,
  invalid: PropTypes.bool,
  showDelete: PropTypes.bool,
  showSave: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  registerTrigger: PropTypes.func,
  resource: PropTypes.string,
  savePristine: PropTypes.bool,
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  width: PropTypes.string,
};

EditorToolbarView.defaultProps = {
  submitOnEnter: true,
};

const EditorToolbarFunc = (props) => {
  const record = useRecordContext();
  const { isDirty, isValid } = useFormState();
  const showNotification = useNotify();

  const handleSubmitWithRedirect = (redirect) => {
    const { handleSubmitWithRedirect, savePristine = false } = props;
    if (!isDirty && !savePristine) {
      return () => undefined;
    }
    return handleSubmitWithRedirect(redirect);
  };

  useEffect(() => {
    const { registerTrigger } = props;
    if (registerTrigger) {
      // registerTrigger(this);
      registerTrigger({
        props: {
          record,
          pristine: !isDirty,
          invalid: !isValid,
          handleSubmitWithRedirect,
          showNotification,
        },
      });
    }
    return () => {
      if (registerTrigger) {
        registerTrigger(null);
      }
    };
  }, []);

  const {
    basePath,
    children,
    classes,
    className,
    handleSubmit,
    handleSubmitWithRedirect: _handleSubmitWithRedirect,
    redirect,
    registerTrigger: _registerTrigger,
    resource,
    saving,
    showDelete = false,
    showSave = true,
    submitOnEnter,
    undoable: _undoable,
    width,
    alwaysEnable,
    ...rest
  } = props;

  return (
    <Fragment>
      <MuiToolbar
        className={classnames(
          styles.toolbar,
          {
            [styles.mobileToolbar]: width === "xs",
            [styles.desktopToolbar]: width !== "xs",
          },
          className,
        )}
        role="toolbar"
        {...rest}
      >
        <div className={styles.defaultToolbar}>
          {showSave && (
            <SaveButton
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              invalid={!isValid}
              redirect={redirect}
              saving={saving}
              alwaysEnable={alwaysEnable}
              // submitOnEnter={submitOnEnter} //depracated
            />
          )}
          {Children.map(children, (button) =>
            button
              ? button.props.toolbarProps
                ? React.cloneElement(button, {
                    basePath,
                    handleSubmit: valueOrDefault(
                      button.props.handleSubmit,
                      handleSubmit,
                    ),
                    handleSubmitWithRedirect: valueOrDefault(
                      button.props.handleSubmitWithRedirect,
                      handleSubmitWithRedirect,
                    ),
                    invalid: !isValid,
                    pristine: !isDirty,
                    record,
                    saving,
                    submitOnEnter: valueOrDefault(
                      button.props.submitOnEnter,
                      submitOnEnter,
                    ),
                  })
                : button
              : null,
          )}
          {showDelete && record && typeof record.id !== "undefined" && (
            <DeleteButton
              basePath={basePath}
              record={record}
              resource={resource}
              undoable={false}
            />
          )}
        </div>
      </MuiToolbar>
      <div className={styles.spacer} />
    </Fragment>
  );
};

export const EditorToolbar = EditorToolbarFunc;
