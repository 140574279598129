import React, { Component } from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  Edit,
  FunctionField,
  Labeled,
  List,
  maxValue,
  minValue,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";

import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import { HomeIcon } from "lucide-react";
import { CategoryInput, SubCategoryInput, validation } from "../../components";
import { authClient } from "../../server";

class ReorderLink extends React.PureComponent {
  render() {
    return (
      <Link to="/homepage-rows/reorder" style={{ textDecoration: "none" }}>
        <Button variant="contained" color="primary" size="small">
          Reorder rows
        </Button>
      </Link>
    );
  }
}

const ListPaginationToolbar = () => {
  return (
    <Toolbar
      style={{
        backgroundColor: "white",
        marginTop: 0,
        justifyContent: "center",
      }}
    >
      <ReorderLink />
    </Toolbar>
  );
};

const customRowStyle = (record, index) => ({
  height: 42,
  opacity: record.show ? 1 : 0.3,
  boxShadow: record.is_custom ? "inset 0 0 9px 1px #7084b3" : "none",
});

const ConditionalTextField = ({ record, ...rest }) =>
  record && record.is_custom ? <TextField record={record} {...rest} /> : null;

function ListView(props) {
  return (
    <List
      {...props}
      sort={{ field: "row_order", order: "ASC" }}
      filters={[
        <ReferenceInput
          label="Project"
          source="project_id"
          reference="projects"
          alwaysOn
        >
          <AutocompleteInput source="name" />
        </ReferenceInput>,
      ]}
      perPage={100}
      pagination={<ListPaginationToolbar />}
      title="Homepage Rows"
    >
      <Datagrid
        rowClick="edit"
        rowSx={customRowStyle}
        bulkActionButtons={false}
      >
        <ReferenceField
          label="Project"
          source={"project_id"}
          reference="projects"
          sortable={false}
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="row_order" sortable={false} />
        <TextField source="title" sortable={false} />
        <BooleanField source="show" sortable={false} />
        <NumberField source="limit" label="Items to show" />
        <BooleanField
          source="is_custom"
          label="Customizable?"
          sortable={false}
        />
        <ReferenceField
          label="Topic"
          source="topic_id"
          reference="categories"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ConditionalTextField
          source="topic_id"
          label="Topic ID"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
}

const EditViewToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ConditionalAutocompleteInput = ({ record, ...rest }) =>
  record && record.is_custom ? (
    <>
      <CategoryInput
        validate={[validation.required()]}
        source="topic_id"
        record={record}
        {...rest}
      />
      <SubCategoryInput
        parentSource="topic_id"
        source="subtopic_id"
        record={record}
        {...rest}
      />
    </>
  ) : null;

const editForm = (props) => {
  const { redirect } = props;

  return (
    <SimpleForm redirect={redirect} toolbar={<EditViewToolbar />}>
      <BooleanInput source="show" />
      <NumberInput
        source="row_order"
        validate={[required(), minValue(1), maxValue(16)]}
      />
      <NumberInput source="limit" label="Amount of items to show" />
      <Labeled>
        <TextField source="name" label="Internal Name" />
      </Labeled>
      <TextInput source="title" validate={required()} />
      <FunctionField
        render={(record) => <ConditionalAutocompleteInput record={record} />}
      />
    </SimpleForm>
  );
};

const EditTitle = () => <span>{`Homepage Row`}</span>;

class EditView extends Component {
  state = { categories: [] };

  topicId = null;
  setTopicId = (record) => {
    if (!this.topicId && record && record.topic_id)
      this.topicId = record.topic_id;
  };

  getCategories = async () => {
    await authClient
      .get("/topics/flat?min_lectures=6")
      .then((res) => {
        this.setState({ categories: res.json });
      })
      .catch((er) => console.log(er));
  };

  componentDidMount() {
    this.getCategories();
  }

  render() {
    return (
      <Edit
        {...this.props}
        title={<EditTitle />}
        categories={this.categories}
        undoable={false}
      >
        {editForm({
          ...this.props,
          categories: this.state.categories,
          setTopicId: this.setTopicId,
          redirect: "list",
        })}
      </Edit>
    );
  }
}

export const Homepage = {
  category: "tanext",
  name: "homepage-rows",
  edit: EditView,
  icon: HomeIcon,
  list: ListView,
  options: { label: "Homepage" },
};
