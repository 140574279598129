import React from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { filterByName } from "../utils";

export class LanguageInput extends React.PureComponent {
  render() {
    const {
      label = "Language",
      source = "language",
      reference = "languages",
      filterToQuery = filterByName,
      perPage = 100,
      sort = { field: "name", order: "ASC" },
      ...rest
    } = this.props;
    return (
      <ReferenceInput
        label={label}
        source={source}
        reference={reference}
        // filterToQuery={filterToQuery}
        perPage={perPage}
        sort={sort}
        {...rest}
      >
        <AutocompleteInput filterToQuery={filterToQuery} optionText="name" />
      </ReferenceInput>
    );
  }
}
