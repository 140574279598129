import { MailCheck } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import {
  CardListActions,
  DefaultBulkActionButtons,
  EditorToolbar,
} from "../../components";

function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar showDelete />}>
      <TextInput source="id" label="Email Address" />
    </SimpleForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      {renderEditForm()}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={[<TextInput source="id" alwaysOn label="Email Address" />]}
      perPage={50}
      sort={{ field: "id", order: "ASC" }}
      title="Subscribers"
      bulkActionButtons={<DefaultBulkActionButtons />}
    >
      <Datagrid
        rowClick="edit"
        bulkActionButtons={<DefaultBulkActionButtons />}
      >
        <TextField source="id" label="Email Address" />
      </Datagrid>
    </List>
  );
}

export const EmailSubscribers = {
  category: "email",
  name: "email_subscribers",
  create: CreateView,
  edit: EditView,
  icon: MailCheck,
  list: ListView,
  options: { label: "Subscribers" },
};
