import { ScissorsSquare } from "lucide-react";
import {
  BooleanInput,
  Button,
  Create,
  Datagrid,
  DateInput,
  Edit,
  FormTab,
  ImageField,
  List,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  UrlField,
} from "react-admin";

import {
  CardListActions,
  CategoryInput,
  EditorToolbar,
  EventSpeakersInput,
  HostInput,
  InputRow,
  LanguageInput,
  LocationInput,
  MorePagination,
  OrganizationInput,
  SpeakerInput,
  SubCategoryInput,
  validation,
} from "../../components";
import DateTimeField from "../../components/field/DateTimeField";

const validate = {
  title: [validation.required(), validation.minLength(2)],
  email: [validation.required()],
  lectureUrl: [validation.required()],
  location: [validation.required()],
  clipLink: [validation.required()],
  category: [validation.required()],
  date_recorded: [validation.required()],
  language: [validation.required()],
  speaker: [validation.required()],
};

function CreateView(props) {
  return (
    <Create {...props} redirect="edit" title="Upload Clip">
      <SimpleForm toolbar={<EditorToolbar />}>
        <TextInput
          source="clipper_email"
          label="Clipper Email"
          validate={validate.email}
        />
        <TextInput
          source="lecture_url"
          label="Lecture URL or Id"
          validate={validate.lectureUrl}
        />
        <TextInput
          source="clip_title"
          label="Clip Title"
          validate={validate.title}
        />
        <InputRow validate={validate.category}>
          <TextInput source="cat_id" label="Category Id" />
          <span>or choose:</span>
          <CategoryInput source="cat_id" allowEmpty />
        </InputRow>
        <InputRow>
          <TextInput source="sub_cat_id" label="Sub-Category Id" />
          <span>or choose:</span>
          <SubCategoryInput source="sub_cat_id" />
        </InputRow>
        <TextInput
          source="clip_link"
          label="Clip Link"
          validate={validate.clipLink}
        />
      </SimpleForm>
    </Create>
  );
}

const EditActions = () => (
  <CardListActions>
    <Button
      color="primary"
      onClick={() => window.alert("OK")}
      label="Export Emails"
      title="Exports the emails of all followers..."
    />
  </CardListActions>
);

function EditView(props) {
  return (
    <Edit
      {...props}
      redirect={false}
      undoable={false}
      actions={<EditActions />}
    >
      <TabbedForm toolbar={<EditorToolbar />}>
        <FormTab label="Details" replace>
          <UrlField
            source="url"
            label="Website"
            target="_blank"
            rel="noopener noreferrer"
          />
          <UrlField
            source="vimeo_url"
            label="Vimeo"
            target="_blank"
            rel="noopener noreferrer"
          />
          <UrlField
            source="download_url"
            label="File"
            target="_blank"
            rel="noopener noreferrer"
          />
          <UrlField
            source="cloud_url"
            label="Cloud"
            target="_blank"
            rel="noopener noreferrer"
          />
          <TextField source="original_file" />
          <TextInput source="thumbnail" />
          <ImageField source="thumbnail_url" title="Thumbnail" />
          <BooleanInput source="display_active" label="Display" />
          <BooleanInput
            source="auto_display"
            label="Auto Send Email and Publish When Encoding Completes"
          />
          <TextInput source="title" validate={validate.title} fullWidth />
          <DateInput source="date_recorded" validate={validate.date_recorded} />

          <LanguageInput validate={validate.language} />
          <LocationInput validate={validate.location} />
          <HostInput allowEmpty />
          <OrganizationInput allowEmpty />
          <SpeakerInput />
          <CategoryInput />
          <SubCategoryInput allowEmpty />
          <EventSpeakersInput allowEmpty />
          <BooleanInput
            source="on_recent_list"
            label="Show on 'Recent Lectures' on Home Page"
          />
          <BooleanInput
            source="ladies"
            label="Only Ladies can View After Login"
          />
          <BooleanInput source="private" label="Private Lecture" />
          <TextInput
            source="private_code"
            label="Private Lecture Access Code"
          />
          <TextInput source="dedication" label="Dedication message" fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

const filters = [
  <TextInput source="id" alwaysOn />,
  <TextInput source="title" alwaysOn />,
  <SpeakerInput
    label="Speaker"
    source="speaker"
    reference="speakers"
    alwaysOn
  />,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={filters}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "date_created",
        order: "DESC",
      }}
      title="Clips"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ImageField source="thumbnail_url" label="Thumbnail" />
        <TextField source="speaker_name" label="Speaker" />
        <TextField source="title" />
        <DateTimeField source="date_created" style={{ minWidth: 150 }} />
        <TextField source="published_by_email" label="Published by" />
      </Datagrid>
    </List>
  );
}

export const Clips = {
  category: "database",
  name: "clips",
  list: ListView,
  create: CreateView,
  icon: ScissorsSquare,
  options: { label: "Clips" },
  edit: EditView,
};
