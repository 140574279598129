import { MapPin } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CardListActions, EditorToolbar } from "../../components";

function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <TextInput source="name" label="Name" />
    </SimpleForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      {renderEditForm()}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={[
        <TextInput source="id" alwaysOn />,
        <TextInput label="Name" source="name" alwaysOn />,
      ]}
      perPage={50}
      sort={{ field: "name", order: "ASC" }}
      title="Locations"
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" label="Name" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const Locations = {
  category: "database",
  name: "locations",
  create: CreateView,
  edit: EditView,
  icon: MapPin,
  list: ListView,
  options: { label: "Locations" },
};
