import { DateField } from "react-admin";

const DateTimeField = (props) => {
  const {
    addLabel = true,
    locale = "en-US",
    options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    showTime = true,
  } = props;
  return (
    <DateField
      {...props}
      addLabel={addLabel}
      locale={locale}
      options={options}
      showTime={showTime}
    />
  );
};

export default DateTimeField;
