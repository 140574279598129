import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { authClient } from "../../server";

export const SpeakerRethink = _SpeakerRethink;
function _SpeakerRethink() {
  const [settings, setSettings] = useState({});
  const [speakerToSave, setSpeakerToSave] = useState({});
  const [speakerId, setSpeakerId] = useState("");
  const [speakerChanged, setSpeakerChanged] = useState(false);

  const saveSettings = async () => {
    setSpeakerChanged(false);

    await authClient.post(
      `/admin/rethink-speakers/${speakerId}`,
      JSON.parse(speakerToSave),
    );
  };

  useEffect(() => {
    (async function () {
      let _speakerId = window.location.hash.split("/")[2];
      setSpeakerId(_speakerId);
      const response = await authClient.get(
        `/admin/rethink-speakers/${_speakerId}`,
      );
      setSettings(JSON.parse(response.body));
    })();
  }, [speakerId]);

  const styles = {
    flex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "20px",
    },
  };

  return (
    <div>
      <JSONInput
        style={{
          width: "100%",
        }}
        id="a_unique_id"
        placeholder={settings}
        // colors={darktheme}
        locale={locale}
        height="550px"
        width="100%"
        onChange={(e) => {
          setSpeakerChanged(true);
          console.log("e.json", e.json);
          setSpeakerToSave(e.json);
        }}
      />
      <div style={styles.flex}>
        <Button
          disabled={!speakerChanged}
          variant={"outlined"}
          onClick={saveSettings}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}
