const SanitizedP = ({ basePath, ...props }) => {
  return (
    <p
      style={{
        marginBottom: 0,
        width: "auto",
        color: "rgba(0, 0, 0, 0.54)",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: 14,
      }}
      {...props}
    />
  );
};

export default SanitizedP;
