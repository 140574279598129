import { useEffect, useState } from "react";
import { UrlField, useRecordContext } from "react-admin";

export function ExistingUrlField({ source }) {
  const record = useRecordContext();
  const url = record[source];
  const [isUrlAvailable, setIsUrlAvailable] = useState(false);
  useEffect(() => {
    if (!isUrlAvailable && typeof url !== "undefined") {
      fetch(url, { method: "HEAD" }).then((response) => {
        if (response.status === 200) {
          setIsUrlAvailable(true);
        }
      });
    }
  }, [isUrlAvailable, url]);

  return (
    isUrlAvailable && <UrlField source={source} target="_blank"></UrlField>
  );
}
