import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNotify } from "ra-core";
import { useEffect, useState } from "react";
import { Title } from "react-admin";
import { authClient } from "../server";

const style = {};
const cardStyle = {
  fontFamily: "Roboto Condensed, sans-serif",
};
const containerStyle = {
  display: "flex",
};
const columnStyle = {
  padding: 10,
  fontSize: 39,
  lineHeight: 1,
};
const headerStyle = {
  fontSize: 23,
  fontWeight: 400,
};
const linkItemStyle = { padding: 6 };

const Dashboard = (props) => {
  const notify = useNotify();
  const [summary, setSummary] = useState({
    categories: 0,
    languages: 0,
    speakers: 0,
    lectures: 0,
  });
  const [profile, setProfile] = useState();

  useEffect(() => {
    getUserProfile().then(getSummary);
  }, []);

  const getSummary = async () => {
    const res = await authClient
      .get("/admin/dashboard/summary")
      .catch((error) => notify(error.body.message, { type: "error" }));
    if (!res || !res.json) {
      return;
    }
    setSummary(res.json);
  };

  const getUserProfile = async () => {
    const res = await authClient
      .get("/users/mine")
      .catch((error) => notify(error.body.message, { type: "error" }));
    if (!res || !res.json) {
      return;
    }
    setProfile(res.json);
  };

  return (
    <Card>
      <Title title="Dashboard" />
      <CardContent style={cardStyle}>
        <h2 style={headerStyle}>Summary</h2>
        {!!profile && (
          <div
            style={{
              position: "absolute",
              right: 50,
              top: 100,
            }}
          >
            Welcome {profile.screen_name}
          </div>
        )}
        <div style={containerStyle}>
          <div style={columnStyle}>
            <div>{summary.speakers.toLocaleString()}</div>
            <h2 style={headerStyle}>Speakers</h2>
          </div>
          <div style={columnStyle}>
            <div>{summary.categories.toLocaleString()}</div>
            <h2 style={headerStyle}>Categories</h2>
          </div>
          <div style={columnStyle}>
            <div>{summary.languages.toLocaleString()}</div>
            <h2 style={headerStyle}>Languages</h2>
          </div>
        </div>
        <div style={containerStyle}>
          <div style={columnStyle}>
            <div>{summary.lectures.toLocaleString()}</div>
            <h2 style={headerStyle}>Lectures</h2>
          </div>
        </div>
        <h2 style={headerStyle}>Links</h2>
        <ul>
          <li style={linkItemStyle}>
            <a
              href="https://www.torahanytime.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Front Page
            </a>
          </li>
          <li style={linkItemStyle}>
            <a
              href="https://vimeo.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Vimeo
            </a>
          </li>
        </ul>
      </CardContent>
    </Card>
  );
};
export const DashboardPage = Dashboard;
