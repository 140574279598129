import get from "lodash/get";
import React from "react";

export class PreformattedTextField extends React.PureComponent {
  static defaultProps = {
    addLabel: true,
  };

  render() {
    const { record, source } = this.props;
    return <pre>{get(record, source)}</pre>;
  }
}
