import { isAfter, subWeeks } from "date-fns";
import { useEffect } from "react";
import { useInterval } from "./hooks";
import { authClient, authUser } from "./server";

export default function HandleTokenRefresh() {
  const handleActions = async () => {
    const jwtExpiry = localStorage.getItem("jwtExpiry");
    const token = localStorage.getItem("token");
    if (token && !jwtExpiry) {
      // need this because we are currently not persisting the exp date
      await fetchToken();
      return;
    }
    const now = new Date();
    const expDate = new Date(parseInt(jwtExpiry));
    const threeWeeksBeforeExp = subWeeks(expDate, 3);
    if (isAfter(now, threeWeeksBeforeExp)) {
      await fetchToken();
    }
  };

  useInterval(handleActions, 24 * 60 * 60 * 1000); // once a day

  useEffect(() => {
    // Run handleActions on initial load
    handleActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}

async function fetchToken() {
  const response = await authClient
    .post("/refresh-token")
    .catch((err) => ({ err }));
  if (response.err) {
    return null;
  }
  const { token, expiration } = response.json;
  console.log({ token, expiration });
  localStorage.setItem("token", token);
  localStorage.setItem("jwtExpiry", expiration);
  authUser.load(token);
}
