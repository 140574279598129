import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  ImageField,
  ImageInput,
  List,
  NumberField,
  required,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";

import { DollarSign } from "lucide-react";
import { EditorToolbar } from "../../components";

function ListView(props) {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <TextField source="name" />
        <ImageField source="img" sortable={false} />
        <BooleanField source="display_active" />
      </Datagrid>
    </List>
  );
}
function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <BooleanInput source="display_active" defaultValue={0} />
      <TextInput source="name" validate={required()} />
      <br />
      <br />
      <h4>Image</h4>
      <p>Image aspect ratio 1:1 (square)</p>
      <TextInput source="img" label="Image URL" />
      <p>-or-</p>
      <ImageInput
        source="FILE_photo"
        label="Image Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      {renderEditForm()}
    </Edit>
  );
}

export const TbavSponsors = {
  category: "tbav",
  name: "tisha_bav_sponsors",
  create: CreateView,
  edit: EditView,
  icon: DollarSign,
  list: ListView,
  options: { label: "Sponsors" },
};
