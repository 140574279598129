import { CalendarRange } from "lucide-react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  Labeled,
  List,
  SimpleForm,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import {
  CardListActions,
  EditorToolbar,
  OrganizationInput,
} from "../../components";

const validateDateCreated = (values) => {
  const errors = {};
  if (!values.date_start) {
    errors.date_start = ["Date start is required"];
  }
  if (!values.date_end) {
    errors.date_end = ["Date end is required"];
  }
  if (values.date_start > values.date_end) {
    errors.date_start = ["Date start must be less than Date end!"];
    errors.date_end = ["Date end must be greater than Date start!"];
  }
  return errors;
};

function RenderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />} validate={validateDateCreated}>
      <Labeled>
        <TextField source="id" />
      </Labeled>
      <ConventionUrl />
      <TextInput source="event_title" />
      <BooleanInput source="display_active" />
      <DateTimeInput source="date_start" />
      <DateTimeInput source="date_end" />
      <OrganizationInput source="organization_id" />
      <TextInput source="event_picture" label="Event Picture" />
    </SimpleForm>
  );
}

const ConventionUrl = () => {
  const record = useRecordContext();
  if (!record) return;
  return (
    <a
      href={`https://new.torahanytime.com/live-events/conventions/${record.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >{`https://new.torahanytime.com/live-events/conventions/${record.id}`}</a>
  );
};

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      {RenderEditForm({ props: props })}
    </Edit>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {RenderEditForm()}
    </Create>
  );
}
function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={[
        <TextInput label="Title" source="event_title" alwaysOn />,
        <OrganizationInput
          label="Organization"
          source="organization"
          reference="organizations"
          alwaysOn
        />,
      ]}
      perPage={25}
      sort={{ field: "date_start", order: "DESC" }}
      title="Conventions"
    >
      <Datagrid rowClick="edit">
        <TextField source="event_title" label="Title" />
        <BooleanField source="display_active" label="Active" />
        <DateField
          source="date_start"
          showTime
          label="Start"
          style={{ minWidth: 160 }}
        />
      </Datagrid>
    </List>
  );
}

export const Conventions = {
  category: "manage",
  name: "manage-conventions",
  edit: EditView,
  icon: CalendarRange,
  list: ListView,
  create: CreateView,
  options: { label: "Conventions" },
};
