import React, { useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiToolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Title, useNotify } from "react-admin";
import { authClient } from "../../server";
import { BellDot } from "lucide-react";

const notificationTypes = {
  tempone: "Standard Message using Notification Bar",
  temptwo: "Pop-Up with OK button",
  tempthree: "Pop-Up with custom button text. Play Store",
  tempfour: "Pop-Up with custom button text. Donate Page",
  tempfive: "Pop-Up with custom button text. Message Center",
  tempsix: "Pop-Up with custom button text. Live Lectures",
  tempseven: "Pop-Up with custom button text. Register Page",
};

const showUrlFieldTypes = ["tempone", "temptwo"];

const platformOptions = {
  all: "All",
  android: "Android",
  ios: "iOS",
};

const appOptions = {
  all: "ALL",
  old: "2.0 App",
  new: "3.0 App",
};

const PushNotify = () => {
  const notify = useNotify();

  const [type, setType] = useState("tempone");
  const [title, setTitle] = useState<string>();
  const [msg, setMsg] = useState("");
  const [url, setUrl] = useState<string>();
  const [buttonText, setButtonText] = useState("OK");
  const [apps, setApps] = useState("all");
  const [platform, setPlatform] = useState("all");
  const [sending, setSending] = useState(false);

  const handleSend = (testing: boolean) => {
    if (!!sending && !!msg && !!buttonText) {
      return;
    }

    const data: any = {
      type,
      title,
      msg,
      url,
      apps,
      buttontxt: buttonText,
      platform,
      _sending: sending,
      testing,
    };

    // We don't want to include the URL if it won't be supported.
    // Since the user may have filled in the URL field, and then
    // changed to another notification type, we just delete it before
    // submitting it.
    if (!showUrlFieldTypes.includes(data.type)) {
      delete data.url;
    }
    setSending(true);
    authClient
      .put("/admin/push-notify", data)
      .then((result) => {
        const { count } = result.json;
        if (count < 1) {
          window.alert("No devices found.");
        } else {
          window.alert(
            `OK, sent ${count} ${testing ? "test " : ""} notifications.`,
          );
        }
        setSending(false);
      })
      .catch((err) => {
        setSending(false);
        notify(err, { type: "error" });
      });
  };

  const styles = {
    textField: { width: "100%" },
    radioGroup: { width: 220 },
    button: { marginRight: 20 },
  };
  return (
    <Card>
      <Title title="Push Notification" />
      <CardContent>
        <div>
          <NativeSelect value={type} onChange={(e) => setType(e.target.value)}>
            {Object.keys(notificationTypes).map((key) => (
              <option key={key} value={key}>
                {notificationTypes[key]}
              </option>
            ))}
          </NativeSelect>
          <br />
          <br />
          <TextField
            label="Message Title"
            placeholder="TorahAnytime"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={styles.textField}
          />
          <TextField
            label="Message Text"
            placeholder="Message Text"
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
            multiline={true}
            rows={15}
            style={styles.textField}
          />
          {showUrlFieldTypes.includes(type) && (
            <TextField
              label="URL"
              placeholder="https://torahanytime.com/donate"
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              style={styles.textField}
            />
          )}
          <br />
          <br />
          <TextField
            label="Button Text"
            placeholder="Button Text"
            value={buttonText}
            onChange={(e) => setButtonText(e.target.value)}
          />
          <br />
          <br />
          <InputLabel htmlFor="apps">Send to</InputLabel>
          <br />
          <RadioGroup
            value={apps}
            onChange={(e) => setApps(e.target.value)}
            name="apps"
            style={styles.radioGroup}
          >
            {Object.keys(appOptions).map((key) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio color="primary" />}
                label={appOptions[key]}
                labelPlacement="start"
              />
            ))}
          </RadioGroup>
          <br />
          <InputLabel htmlFor="platform">Platform</InputLabel>
          <br />
          <RadioGroup
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
            name="platform"
            style={styles.radioGroup}
          >
            {Object.keys(platformOptions).map((key) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio color="primary" />}
                label={platformOptions[key]}
                labelPlacement="start"
              />
            ))}
          </RadioGroup>
          <br />
          <br />
        </div>
        <MuiToolbar role="toolbar">
          <Button
            variant="contained"
            size="small"
            onClick={() => handleSend(true)}
            style={styles.button}
            disabled={sending || !msg || !buttonText}
          >
            Test Send to myself
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleSend(false)}
            style={styles.button}
            disabled={sending || !msg || !buttonText}
          >
            Send
          </Button>
        </MuiToolbar>
      </CardContent>
    </Card>
  );
};

export default PushNotify;

export const PushNotifyPage = {
  category: "misc",
  icon: BellDot,
  name: "push-notify",
  label: "Push Notifications",
  url: "/push-notify",
  exact: true,
  Element: <PushNotify />,
};
