import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useEffect, useState } from "react";
import { CopyToClipboard as Copy } from "react-copy-to-clipboard";

export function CopyToClipboard({
  textToCopy = "",
  iconStyle = {},
  copiedStyle = {},
  style = {},
}) {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);
  return (
    <span style={{ whiteSpace: "nowrap", display: "flex", ...style }}>
      <Copy text={textToCopy} onCopy={() => setCopied(true)}>
        <AttachFileIcon
          style={{ cursor: "pointer", fontSize: 19, ...iconStyle }}
        />
      </Copy>
      {copied && <span style={{ color: "red", ...copiedStyle }}>Copied.</span>}
    </span>
  );
}
