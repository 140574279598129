import { LayoutTemplate } from "lucide-react";
import { useEffect, useState } from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  Filter,
  FormDataConsumer,
  FormTab,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { EditorToolbar } from "../../../components";
import SortablePage from "../../../components/page/SortableFormPage.tsx";
import { authClient } from "../../../server";

const banners = [
  { field: "cell_one", label: "Cell 1" },
  { field: "cell_two", label: "Cell 2" },
  { field: "cell_three", label: "Cell 3" },
  { field: "cell_four", label: "Cell 4" },
  { field: "cell_five", label: "Cell 5" },
  { field: "cell_six", label: "Cell 6" },
  { field: "cell_seven", label: "Cell 7" },
  { field: "cell_eight", label: "Cell 8" },
];

function validate(values) {
  console.log({ values, v: Object.values(values) });
  const errors = {};
  if (!values.project_id) {
    errors.project_id = ["Required"];
  }
  if (!values.layout) {
    errors.layout = ["Please add at least one cell"];
  } else if (values.layout > 8) {
    errors.layout = ["8 cells maximum"];
  } else {
    for (let cell of banners) {
      if (!values[cell.field]) {
        errors[cell.field] = ["Required"];
      }
    }
  }

  // in progress / TODO: check for duplicate cells by checking the value. this is probably not the vest wayt o
  // const project_id = values.project_id;
  // delete values.project_id;

  // const valueArray = Object.values(values);
  // const set = new Set(valueArray);
  // values.project_id = project_id;

  const cells = Object.entries(values)
    .filter(([key, value]) => key.startsWith("cell"))
    .map(([key, value]) => value);
  const cellSet = new Set(cells);

  if (cells.length > cellSet.length) {
    errors.layout = ["Duplicate cells!"];
  }

  if (!values.layout_name) {
    errors.layout_name = ["Layout name is required"];
  }
  return errors;
}

const LayoutFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="layout_name" alwaysOn />
  </Filter>
);

function renderEditForm(create = false) {
  return (
    <TabbedForm
      // validate={validate}
      submitOnEnter={false}
      toolbar={<EditorToolbar />}
    >
      <FormTab label="Details" replace>
        <ReferenceInput source="project_id" reference={"projects"}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="layout_name" required />
        {!create && <BooleanInput source="is_active" defaultValue={true} />}
        <NumberInput
          source="layout"
          max={8}
          min={1}
          defaultValue={1}
          label="Banner Count"
        />
        <div>
          {banners.map((banner, i) => (
            <CellInput key={banner.field} i={i} />
          ))}
        </div>
      </FormTab>
      <FormTab label="Reorder Layout" replace>
        <Banners />
      </FormTab>
    </TabbedForm>
  );
}

const Banners = () => {
  const [banners, setBanners] = useState([]);
  const refresh = useRefresh();
  const record = useRecordContext();
  useEffect(() => {
    console.log(record);
    const newBanners = Object.entries(record).filter(
      ([key, value]) => key.startsWith("cell") && value,
    );

    setBanners(newBanners);
  }, [record.cell_one]);

  const handleSave = async () => {
    try {
      const cells = {
        cell_one: banners[0][1],
        cell_two: banners[1]?.[1],
        cell_three: banners[2]?.[1],
        cell_four: banners[3]?.[1],
        cell_five: banners[4]?.[1],
        cell_six: banners[5]?.[1],
        cell_seven: banners[6]?.[1],
        cell_eight: banners[7]?.[1],
      };
      await authClient
        //@ts-ignore
        .put(`/admin/layouts/${record.id}`, { ...record, ...cells });
      refresh();
    } catch {
      console.error("Failed to reorder: cell_one cannot be empty");
    }
  };

  return (
    <SortablePage
      staticList
      info="Drag rows to reorder, then click 'Save'"
      items={banners}
      renderItem={(item, index) => (
        <div>
          <ReferenceField
            label={item[0] || ""}
            source={item[0] || ""}
            reference="banners"
            resource=""
            perPage={10000}
            required
          >
            <TextField source="title" />
          </ReferenceField>
        </div>
      )}
      setItems={setBanners}
      handleSave={handleSave}
      title={""}
      createItem={() => ["", ""]}
    />
  );
};

function renderCellInput(props) {
  const { formData = {}, i, ...rest } = props;
  if (!formData.layout || formData.layout <= i) {
    return null;
  }

  return (
    <>
      <ReferenceInput
        label={banners[i].label || ""}
        source={banners[i].field || ""}
        reference="banners"
        resource=""
        perPage={10000}
        required
      >
        <AutocompleteInput
          optionText="title"
          optionValue="id"
          filterToQuery={(searchText) => ({
            title: searchText,
          })}
        />
      </ReferenceInput>
    </>
  );
}

function CellInput(props) {
  return <FormDataConsumer {...props}>{renderCellInput}</FormDataConsumer>;
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm(true)}
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      {renderEditForm()}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List {...props} title="Layouts" filters={<LayoutFilter />}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        <NumberField source="project_id" />
        <TextField source="layout_name" />
        <BooleanField source="is_active" />
        <NumberField source="layout" />
      </Datagrid>
    </List>
  );
}

export const Layouts = {
  category: "tanext",
  name: "layouts",
  create: CreateView,
  edit: EditView,
  icon: LayoutTemplate,
  list: ListView,
};
