import { HelpCircle } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { EditorToolbar } from "../../components";

function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <TextInput source="question" label="Question" fullWidth />
      <TextInput source="answer" label="Answer" multiline fullWidth />
      <TextInput source="video_answer" label="Video Tutorial Url" />
      <SelectInput
        label="Display On"
        source="displayOn"
        choices={[
          { id: "both", name: "Both" },
          { id: "web", name: "Website" },
          { id: "app", name: "App" },
        ]}
        defaultValue="both"
      />
    </SimpleForm>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} title="FAQ">
      {renderEditForm()}
    </Edit>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm()}
    </Create>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      // actions={<CardListActions />}
      exporter={false}
      filters={[
        <SelectInput
          label="Display On"
          source="displayOn"
          choices={[
            { id: "both", name: "Both" },
            { id: "web", name: "Website" },
            { id: "app", name: "App" },
          ]}
          alwaysOn
        />,
      ]}
      title="News & announcements"
      // bulkActionButtons={false}
      sort={{ field: "updated_at", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="question" label="Question" />
        <TextField source="answer" label="Answer" />
        <TextField source="displayOn" label="Display On" />
        <TextField source="video_answer" label="Video Tutorial" />
      </Datagrid>
    </List>
  );
}

export const FAQ = {
  category: "manage",
  name: "manage-faq",
  edit: EditView,
  create: CreateView,
  list: ListView,
  icon: HelpCircle,
  options: { label: "FAQ" },
};
