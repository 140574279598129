// Framework
import { useState } from "react";
import { DashboardMenuItem, MenuItemLink, useSidebarState } from "react-admin";
// Custom Components
import SubMenu from "./SubMenu";
// Data
import { LayoutDashboard } from "lucide-react";
import { authorized } from "../server";
import { categories, noCategory } from "./MenuItems";

const styles = {
  closed: {
    // position: "fixed",
    width: "max-content",
  },
  open: {
    overflowY: "auto",
    height: "90vh",
    width: "300px",
  },
  menuItem: {
    display: "flex",
    justifyContent: "start",
    padding: "8px 16px",
    width: "100%",
    height: "40px",
    textAlign: "left",
    fontSize: "14px",
    borderRadius: "0px 50px 50px 0px",
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
};

const Menu = (props) => {
  const [sidebarOpen, setSidebarOpen] = useSidebarState();
  const [openCategories, setOpenCategories] = useState([]);

  const handleToggle = (c_name) => {
    if (!sidebarOpen) setSidebarOpen(true);
    openCategories?.includes(c_name)
      ? setOpenCategories(openCategories.filter((id) => id != c_name))
      : setOpenCategories([...openCategories, c_name]);
  };

  const renderMenuItem = (item, topLevelItem) => {
    const { icon: Icon, label, name, url, roles } = item;
    if (!authorized(roles)) {
      return null;
    }
    return (
      <MenuItemLink
        key={name}
        to={url}
        style={{
          ...styles.menuItem,
          padding: sidebarOpen && !topLevelItem ? "8px 32px" : "8px 16px",
        }}
        primaryText={label}
        leftIcon={<Icon />}
        onClick={props.onMenuClick}
      />
    );
  };

  const renderCategory = (category) => {
    const { icon, items, label, name, item_roles } = category;
    if (items.length < 1 || (item_roles && !authorized(item_roles))) {
      return null;
    }

    return (
      <SubMenu
        key={name}
        handleToggle={() => handleToggle(name)}
        icon={icon}
        isClosed={!openCategories?.includes(name)}
        sidebarIsOpen={sidebarOpen}
        name={label}
      >
        {items.map((i) => renderMenuItem(i, false))}
      </SubMenu>
    );
  };
  console.log(noCategory);
  return (
    <div
      style={sidebarOpen ? styles.open : styles.closed}
      className={sidebarOpen ? "sidebarBreakpoints" : ""}
    >
      <DashboardMenuItem
        leftIcon={<LayoutDashboard />}
        onClick={props.onMenuClick}
        style={styles.menuItem}
      />
      {categories.map(renderCategory)}
      {noCategory.items.map((item) => renderMenuItem(item, true))}
    </div>
  );
};

export default Menu;
