import {
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  ImageField,
  List,
  NumberField,
  regex,
  required,
  SimpleForm,
  TextField,
  TextInput,
  UrlField,
  useRecordContext,
} from "react-admin";

import { Star } from "lucide-react";
import { EditorToolbar, WideCard } from "../../components";

const validateImageLink = [
  required(),
  regex(
    /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
    "Must be a valid URL (http://...)",
  ),
];

const validateLink = regex(
  /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
  "Must be a valid URL (http://...)",
);

function ListView(props) {
  return (
    <List {...props} sort={{ field: "name", order: "ASC" }}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <NumberField source="id" />
        <ImageField source="image" sortable={false} />
        <TextField source="name" />
        <UrlField
          source="link"
          target="_blank"
          rel="noopener noreferrer"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
}

const PreviewImage = (props) => {
  const record = useRecordContext();
  return (
    <a
      href={record.link}
      target="_blank"
      title={record.name}
      rel="noopener noreferrer"
    >
      <WideCard photo={record.image} containImage />
    </a>
  );
};

function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <TextInput source="name" fullWidth />
      <TextInput source="image" validate={validateImageLink} fullWidth />
      <TextInput source="link" validate={validateLink} fullWidth />
      <TextInput source="description" multiline fullWidth />
      <BooleanInput source="display_active" />
      <PreviewImage />
    </SimpleForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      {renderEditForm()}
    </Edit>
  );
}

export const Partners = {
  category: "tanext",
  name: "partners",
  create: CreateView,
  edit: EditView,
  icon: Star,
  list: ListView,
  options: { label: "Partners" },
};
