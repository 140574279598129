import { ListTodo } from "lucide-react";
import {
	BooleanInput,
	Create,
	Datagrid,
	DateField,
	DateTimeInput,
	Edit,
	List,
	NumberField,
	NumberInput,
	ReferenceField,
	SimpleForm,
	TextField,
	TextInput,
	ReferenceInput,
	SelectInput,
	required,
	ReferenceManyField,
	Filter,
	SearchInput,
	BooleanField,
	AutocompleteInput,
} from "react-admin";
import { EditorToolbar } from "../../components";
import DateTimeField from "../../components/field/DateTimeField";

const styles = {
	programTable: {
		position: "absolute",
		right: 20,
		top: 300,
	},
};
const validate = (values) => {
	const tishaBavEve = new Date(2024, 7, 12, 0, 0, 0);
	const tishaBavDate = new Date(2024, 7, 13, 23, 59, 59);

	const errors = {};
	if (!values.start_time) {
		errors.start_time = ["Date start is required"];
	}
	if (!values.end_time) {
		errors.end_time = ["Date end is required"];
	}
	if (new Date(values.start_time) < tishaBavEve) {
		errors.start_time = [`Date start should be after ${tishaBavEve}`];
	}
	if (new Date(values.end_time) > tishaBavDate) {
		errors.end_time = [`Date end should be before ${tishaBavDate}`];
	}
	if (new Date(values.start_time) > new Date(values.end_time)) {
		errors.start_time = ["Date start must be less than Date end!"];
		errors.end_time = ["Date end must be greater than Date start!"];
	}

	// console.log(errors);
	return errors;
};

function ListView(props) {
	return (
		<List
			{...props}
			sort="start_time"
			filters={[
				<SearchInput source="name" alwaysOn />,
				<ReferenceInput
					source="tisha_bav_program"
					reference="tisha_bav_programs"
					label="Program"
					alwaysOn
					perPage={100}
					sort={{ field: "name", order: "ASC" }}
					filter={{ display_active: 1 }}
				>
					<AutocompleteInput
						// filterToQuery={(searchText) => ({ name: `%${searchText}%` })}
						optionText="name"
					/>
				</ReferenceInput>,
			]}
		>
			<Datagrid rowClick="edit" bulkActionButtons={false}>
				<NumberField source="id" />
				<ReferenceField
					source="tisha_bav_program"
					reference="tisha_bav_programs"
				>
					<TextField source="name" />
				</ReferenceField>
				<TextField source="name" />
				<DateTimeField source="start_time" />
				<DateTimeField source="end_time" />
				<BooleanField source="display_active" />
			</Datagrid>
		</List>
	);
}
function renderEditForm() {
	return (
		<SimpleForm validate={validate} toolbar={<EditorToolbar />}>
			<BooleanInput source="display_active" defaultValue={0} />
			<ReferenceInput
				reference="tisha_bav_programs"
				source="tisha_bav_program"
				validate={required()}
				perPage={10000}
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<TextInput source="name" validate={required()} />
			<div style={styles.programTable}>
				<h4>
					Other classes for this program: Please ensure there are no scheduling
					conflicts
				</h4>
				<ReferenceManyField
					label=""
					reference="tisha_bav_classes"
					target="tisha_bav_program"
					source="tisha_bav_program"
					className="program_table"
					sort={{ field: "start_time", order: "ASC" }}
				>
					<Datagrid bulkActionButtons={false}>
						<TextField source="name" />
						<DateField
							source="start_time"
							addLabel
							options={{
								month: "short",
								day: "numeric",
								hour: "numeric",
								minute: "2-digit",
							}}
							showTime
						/>
						<DateField
							source="end_time"
							addLabel
							options={{
								month: "short",
								day: "numeric",
								hour: "numeric",
								minute: "2-digit",
							}}
							showTime
						/>
					</Datagrid>
				</ReferenceManyField>
			</div>
			<p>Note: times will be rounded to closest half hour</p>
			<DateTimeInput
				// min={tishaBavEve}
				source="start_time"
				validate={required()}
			/>
			<DateTimeInput source="end_time" validate={required()} />
			<BooleanInput source="is_female" defaultValue={false} />
			<br />
			<br />
			<h4 style={{ width: 400 }}>
				For all day, pre-recorded classes, put the length of the class (in
				minutes). Please make sure the end time is the FINAL end time.
			</h4>
			<NumberInput
				source="recurring_interval"
				label="Length (recurring interval)"
			/>
			<TextInput source="speakers" label="Speakers (optional)" />

			<p style={{ width: 300, color: "rgb(126, 126, 126)", marginBottom: -8 }}>
				For completed classes with a replay link separate from the program
				livestream
			</p>
			<TextInput source="replay_link" label="Replay link (optional)" />
		</SimpleForm>
	);
}

function CreateView(props) {
	return (
		<Create {...props} redirect="edit">
			{renderEditForm()}
		</Create>
	);
}

function EditView(props) {
	return (
		<Edit {...props} redirect={false} undoable={false}>
			{renderEditForm()}
		</Edit>
	);
}

export const TbavClasses = {
	category: "tbav",
	name: "tisha_bav_classes",
	create: CreateView,
	edit: EditView,
	icon: ListTodo,
	list: ListView,
	options: { label: "Tisha Bav Classes" },
};
