import React from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { filterByName } from "../utils";

export class SpeakerInput extends React.PureComponent {
  render() {
    const {
      label = "Speaker",
      source = "speaker",
      reference = "speakers",
      filterToQuery = filterByName,
      // TODO: implement some sort of infinite scroll because this is too many
      perPage = 1500,
      sort = { field: "name_last", order: "ASC" },
      ...rest
    } = this.props;
    return (
      <ReferenceInput
        label={label}
        source={source}
        reference={reference}
        // filterToQuery={filterToQuery}
        perPage={perPage}
        sort={sort}
        {...rest}
      >
        <AutocompleteInput
          filterToQuery={filterToQuery}
          optionText={(o) =>
            `${o.name} ${o.display_active ? "" : "- inactive"}`
          }
        />
      </ReferenceInput>
    );
  }
}
