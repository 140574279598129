import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { authClient } from "../../../server";
//@ts-ignore
import SortablePage from "../../../components/page/SortableFormPage.tsx";
import { PopupBanner, SortItem } from "../../../../types/sortable";
import { AlertCircle } from "lucide-react";

export const ManagePopupList = () => {
  const [items, setItems] = useState<SortItem[]>([]);

  useEffect(() => {
    getPopupItems().then((res) => {
      setItems(
        res.json.items.map((item) => {
          return {
            ...item,
            display: item.display ? true : false,
            interval: item.interval ? item.interval / 3600000 : 0,
          };
        }),
      );
    });
  }, []);

  const createItem = () => {
    return {
      display: true,
      image: "",
      title: "",
      link: "",
      onclick: () => {},
      height: 0,
      interval: 0,
      width: 0,
    };
  };

  const getPopupItems = async () => {
    return authClient.get("/admin/manage-banners/popup");
  };

  const handleSave = async () => {
    const data = {
      items: items.map((item) => {
        return {
          ...items,
          display: item.display ? 1 : 0,
          interval: (item.interval || 0) * 3600000,
        };
      }),
    };
    //@ts-ignore I know this is not a great idea @todo figure out what's wrong with data- stringify made typescript happy but then obv the request failed
    return authClient.put("/admin/manage-banners/popup", data);
  };

  return (
    <SortablePage
      title="Popup Banners"
      info="NOTE: Checks are triggered on navigation only."
      items={items}
      setItems={setItems}
      renderItem={(item, rowIndex, onChange) => (
        <PopupItem item={item} rowIndex={rowIndex} onChange={onChange} />
      )}
      renderFields={() => {}}
      handleSave={handleSave}
      createItem={createItem}
      maxItems={1}
    />
  );
};

type PopupItemProps = {
  item: SortItem;
  rowIndex: number;
  onChange: (rowIndex: number, field: string, value?: any) => void;
};

const PopupItem = ({ item, rowIndex, onChange }: PopupItemProps) => {
  return (
    <div>
      <FormControl>
        <FormControlLabel
          label="Display"
          control={
            <Checkbox
              checked={!!item.display}
              onChange={(e) =>
                onChange(rowIndex, "display", e.currentTarget.checked)
              }
            />
          }
        />
      </FormControl>
      <TextField
        fullWidth
        label=""
        placeholder="Image URL"
        value={item.image}
        onChange={() => onChange(rowIndex, "image")}
      />
      <TextField
        fullWidth
        label=""
        placeholder="Title"
        value={item.title}
        onChange={() => onChange(rowIndex, "title")}
      />
      <TextField
        fullWidth
        label=""
        placeholder="Link URL"
        value={item.link}
        onChange={() => onChange(rowIndex, "link")}
      />
      <TextField
        fullWidth
        label=""
        placeholder="JS onclick function()"
        value={item.onclick}
        onChange={() => onChange(rowIndex, "onclick")}
      />
      <TextField
        fullWidth
        label="Height"
        value={item.height}
        onChange={(e) =>
          onChange(rowIndex, "height", e.currentTarget.value || 0)
        }
        type="number"
      />
      <TextField
        fullWidth
        label="Width"
        value={item.width}
        onChange={(e) =>
          onChange(rowIndex, "width", e.currentTarget.value || 0)
        }
        type="number"
      />
      <TextField
        fullWidth
        label="Interval Between Popups (hour)"
        value={item.interval}
        onChange={(e) =>
          onChange(rowIndex, "interval", e.currentTarget.value || 0)
        }
        type="number"
      />
    </div>
  );
};

export const PopupList = {
  category: "banners",
  icon: AlertCircle,
  name: "popup-banners",
  url: "/manage-banners/popup",
  label: "Popup",
  exact: true,
  Element: <ManagePopupList />,
};
