import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { FetchJsonResponse, authClient } from "../../../server";
import icon from "@mui/icons-material/FeaturedVideo";
//@ts-ignore
import SortablePage from "../../../components/page/SortableFormPage.tsx";
import { SortItem } from "../../../../types/sortable";
import { useNotify } from "ra-core";
import { Video } from "lucide-react";

const VideoAdList = () => {
  const notify = useNotify();
  const [items, setItems] = useState<SortItem[]>([]);

  useEffect(() => {
    authClient
      .get("/admin/manage-banners/video")
      .then((res) => setItems(res.json.items))
      .catch((error) => notify(error.body.message, { type: "error" }));
  }, []);

  const handleSave = async () => {
    //@ts-ignore
    return authClient.put("/admin/manage-banners/video", { items });
  };

  const createItem = () => {
    return {
      image: "",
      link: "",
      onclick: "",
      speaker: "", // NOTE: Can be a blank string OR number! @todo - why would we do something like that? change in db, api, and here to number or *undefined*
      title: "",
    };
  };

  return (
    <SortablePage
      title={"Video Ads"}
      items={items}
      setItems={setItems}
      renderItem={(item, index, onChange) => (
        <VideoAdItem item={item} index={index} onChange={onChange} />
      )}
      handleSave={handleSave}
      createItem={createItem}
    />
  );
};

export default VideoAdList;

const VideoAdItem = ({ item, index, onChange }) => {
  return (
    <>
      <TextField
        fullWidth
        label="Image URL"
        value={item.image}
        onChange={(e) => onChange(index, "image", e.target.value)}
      />
      <TextField
        fullWidth
        label="Title"
        value={item.title}
        onChange={(e) => onChange(index, "title", e.target.value)}
      />
      <TextField
        fullWidth
        label="Link URL"
        onChange={(e) => onChange(index, "link", e.target.value)}
        value={item.link}
      />
      <TextField
        fullWidth
        label="Speaker ID to show exclusively"
        value={item.speaker}
        onChange={(e) => onChange(index, "speaker", e.target.value)}
        type="number"
      />
      <TextField
        fullWidth
        label="JS onclick function()"
        value={item.onclick}
        onChange={(e) => onChange(index, "onclick", e.target.value)}
      />
    </>
  );
};

export const VideoAdPage = {
  category: "banners",
  icon: Video,
  name: "video-banners",
  label: "Video",
  url: "/manage-banners/video",
  Element: <VideoAdList />,
};
