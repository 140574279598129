import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { authClient } from "../../../server";
import icon from "@mui/icons-material/BorderTop";
//@ts-ignore
import SortablePage from "../../../components/page/SortableFormPage.tsx";
import { SortItem } from "../../../../types/sortable";
import { useNotify } from "ra-core";
import { LayoutPanelTop } from "lucide-react";

const TopBanners = () => {
  const notify = useNotify();
  const [items, setItems] = useState<SortItem[]>([]);

  useEffect(() => {
    authClient
      .get("/admin/manage-banners/top")
      .then((res) => setItems(res.json.items))
      .catch((error) => notify(error.body.message, { type: "error" }));
  }, []);

  const handleSave = async () => {
    //@ts-ignore
    return authClient.put("/admin/manage-banners/top", { items });
  };

  const createItem = () => {
    return {
      image: "",
      link: "",
      onclick: "",
      title: "",
    };
  };

  return (
    <SortablePage
      title={"Top banners"}
      items={items}
      setItems={setItems}
      renderItem={(item, index, onChange) => (
        <TopBannerItem item={item} index={index} onChange={onChange} />
      )}
      handleSave={handleSave}
      createItem={createItem}
    />
  );
};

export default TopBanners;

const TopBannerItem = ({ item, index, onChange }) => {
  return (
    <>
      <TextField
        fullWidth
        label="Image URL"
        value={item.image}
        onChange={(e) => onChange(index, "image", e.target.value)}
      />
      <TextField
        fullWidth
        label="Title"
        value={item.title}
        onChange={(e) => onChange(index, "title", e.target.value)}
      />
      <TextField
        fullWidth
        label="Link URL"
        value={item.link}
        onChange={(e) => onChange(index, "link", e.target.value)}
      />
      <TextField
        fullWidth
        label="JS onclick function()"
        value={item.onclick}
        onChange={(e) => onChange(index, "onclick", e.target.value)}
      />
    </>
  );
};

export const TopAdBannerPage = {
  category: "banners",
  icon: LayoutPanelTop,
  name: "top-banners",
  url: "/manage-banners/top",
  label: "Top",
  exact: true,
  Element: <TopBanners />,
};
