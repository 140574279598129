import { useEffect, useState, useCallback } from "react";

import {
  BooleanInput,
  Create,
  DateInput,
  Edit,
  FileField,
  FileInput,
  FormTab,
  FunctionField,
  ImageField,
  NumberInput,
  TabbedForm,
  TextInput,
  UrlField,
  Toolbar,
  SaveButton,
  NumberField,
} from "react-admin";
import { TemporalProgressField } from "../../components/field/TemporalProgressField";
import { ExistingUrlField } from "../../components/field/ExistingUrlField";

import { EmailTab } from "./EmailTab";
import { InstagramTemplate } from "./InstagramTemplate";
import { SchedulesTab } from "./ScheduleTab";
import { WhatsAppTemplate } from "./WhatsAppTemplate";

import { baseURL } from "../../server";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as tus from "tus-js-client";
import { Link } from "react-router-dom";

const {
  REACT_APP_TUS_BUCKET_DAILY_DOSE: TUS_BUCKET_DAILY_DOSE = "daily-dose-prod",
  REACT_APP_TUS_PREFIX_DAILY_DOSE: TUS_PREFIX_DAILY_DOSE = "originals/",
  REACT_APP_TUS_ENDPOINT: TUS_ENDPOINT = "https://tusd.torahanytime.com/",
} = process.env;

const tusEndpoint = TUS_ENDPOINT;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function UploadThenSaveButton() {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isFileFinishedUploading, setIsFileFinishedUploading] = useState(false);
  // `progress` is 0-100, due to MaterialUI
  const [progress, setProgress] = useState(0);
  const convertTusUploadToFileInfo = useCallback(async (doseRecord) => {
    const file = doseRecord.FILE_original_video?.rawFile;
    if (!file) {
      return doseRecord;
    }
    const uploadFinished = new Promise((resolve) => {
      const upload = new tus.Upload(file, {
        // Endpoint is the upload creation URL from your tus server
        endpoint: tusEndpoint,
        // Retry delays will enable tus-js-client to automatically retry on errors
        retryDelays: [0, 3000, 5000, 10000, 20000],
        // Attach additional meta data about the file for the server
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        // Callback for errors which cannot be fixed using retries
        onError: (error) => {
          console.log(`Tus Upload failed because: ${error}`);
          setIsFileUploading(false);
          setIsFileFinishedUploading(false);
        },
        // Callback for reporting upload progress
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = (bytesUploaded / bytesTotal) * 100;
          setProgress(percentage);
        },
        // Callback for once the upload is completed
        onSuccess: () => {
          console.log("Download %s from %s", upload.file.name, upload.url);
          setIsFileFinishedUploading(true);
          setIsFileUploading(false);
          resolve(upload);
        },
      });

      // Check if there are any previous uploads to continue.
      upload.findPreviousUploads().then((previousUploads) => {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        setIsFileUploading(true);
        setIsFileFinishedUploading(false);
        setProgress(0);
        // Start the upload
        upload.start();
      });
    });

    const upload = await uploadFinished;

    delete doseRecord.FILE_original_video;
    doseRecord.FILEINFO_original_video = {
      bucket: TUS_BUCKET_DAILY_DOSE,
      prefix: TUS_PREFIX_DAILY_DOSE,
      /** Example: From `https://tusd.torahanytime.com/8d9e7d72d525cb5cb607b7d9f274238a+2~tQmI2C9TxIOw361bEx9tSlPtbNIECy5`, we want `8d9e7d72d525cb5cb607b7d9f274238a` */
      filename: upload.url.split("/").pop().split("+")[0],
      downloadUrl: upload.url,
      filetype: upload.file.type,
    };

    console.log("transformed doseRecord", doseRecord);

    return doseRecord;
  }, []);

  return (
    <Toolbar>
      <SaveButton type="button" transform={convertTusUploadToFileInfo} />
      {isFileUploading && <CircularProgressWithLabel value={progress} />}
    </Toolbar>
  );
}

export function CreateDoseView(props) {
  const validate = (values) => {
    const errors = {};

    if (!values.title) errors.title = "Title is required.";
    if (!values.dose_number && values.dose_number !== 0)
      errors.dose_number = "Dose number  is required.";
    if (!values.lecture_id) errors.lecture_id = "Lecture Id is required.";
    if (!values.released_at)
      errors.released_at = "Released at date is required.";
    if (!values.FILE_original_video)
      errors.FILE_original_video = "Original Video is required.";

    return errors;
  };

  return (
    <Create {...props} redirect="edit">
      <TabbedForm
        {...props}
        validate={(values) => validate(values)}
        toolbar={<UploadThenSaveButton />}
      >
        <FormTab label="Create" {...props}>
          <TextInput source="title" />
          <NumberInput source="dose_number" type="number" />
          <NumberInput source="lecture_id" type="number" label="Lecture Id" />
          <BooleanInput source="display" label="Display" defaultValue={false} />
          <BooleanInput
            source="double_dose"
            label="Double Dose"
            defaultValue={false}
          />
          <DateInput source="released_at" />
          <FileInput
            source="FILE_original_video"
            accept="video/*"
            label="Original Video file"
          >
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
}

export function EditDoseView(props) {
  const {
    location: { hash = "" },
    id,
  } = window;

  const [onEditTab, setOnEditTab] = useState(false);

  useEffect(() => {
    console.log({ hash, id });
    const tabIndex = hash.split(`/daily_dose/${id}`).pop();
    if (tabIndex === "") {
      setOnEditTab(true);
    } else {
      setOnEditTab(false);
    }
  }, [id, hash]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) errors.title = "Title is required.";
    // if (!values.dose_number) errors.dose_number = "Dose number is required.";
    if (!values.lecture_id) errors.lecture_id = "Lecture Id is required.";
    if (!values.released_at)
      errors.released_at = "Released at date is required.";

    return errors;
  };

  return (
    <Edit {...props} redirect={false}>
      <TabbedForm
        {...props}
        validate={(values) => validate(values)}
        toolbar={onEditTab ? undefined : <UploadThenSaveButton />}
      >
        <FormTab label="Edit" {...props} replace>
          <TextInput source="title" />
          <NumberInput source="dose_number" type="number" label="Dose Number" />
          <FunctionField
            label="Speaker"
            render={(record) => (
              <a
                href={`/speakers/${record.speaker_id}`}
                target="_blank"
                rel="noreferrer"
              >
                {`${record.speaker_title_short} ${record.speaker_name_first} ${record.speaker_name_last}`}
              </a>
            )}
          />
          <FunctionField
            label="Corresponding Lecture"
            render={(record) => (
              <Link
                to={`https://www.torahanytime.com/lectures/${record.analog_lecture_id}`}
                target="_blank"
              >
                Corresponding Lecture
              </Link>
            )}
          />
          <FunctionField
            label="Admin for Corresponding Lecture"
            render={(record) => (
              <Link
                to={`/lectures/${record.analog_lecture_id}`}
                target="_blank"
              >
                Admin for Corresponding Lecture
              </Link>
            )}
          />
          <FunctionField
            label="Parent Lecture"
            render={(record) => (
              <Link
                to={`https://www.torahanytime.com/lectures/${record.lecture_id}`}
                target="_blank"
              >
                Parent Lecture
              </Link>
            )}
          />
          <NumberInput source="lecture_id" type="number" label="Lecture Id" />
          <BooleanInput source="display" label="Display" defaultValue={false} />
          <BooleanInput
            source="double_dose"
            label="Double Dose"
            defaultValue={false}
          />
          <DateInput source="released_at" />
          <ExistingUrlField source="audioUrl" />
          <ExistingUrlField source="videoUrl" />
          <ExistingUrlField source="videoUrlEmail" />
          <ExistingUrlField source="videoUrlWhatsapp" />
          <ExistingUrlField source="videoUrlHq" />
          <FunctionField
            label="Pipeline Progress"
            render={(record) => (
              <TemporalProgressField
                workflowId={`DailyDoseTranscoding-${record.dose_number}`}
                activityNames={[
                  "DailyDoseAudio",
                  "DailyDoseEmailQuality",
                  "DailyDoseLowQuality",
                  "DailyDoseWhatsApp",
                  "DailyDoseHighQualityFast",
                  "DailyDoseHighQualitySlow",
                ]}
                total={record.duration}
                pollInterval={600}
              />
            )}
          />
          <FileInput
            source="FILE_original_video"
            accept="video/*"
            label="Original Video file"
          >
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
        <FormTab label="Photos">
          <ImageField
            source="speaker_dose_app_photo_url"
            label="Dose app photo"
          />
          <ImageField
            source="speaker_dose_email_photo_url"
            label="Dose email photo"
          />
          <ImageField
            source="speaker_double_dose_email_photo_url"
            label="Double Dose email photo"
          />
          <FunctionField
            label="Instagram photo"
            render={(record) => (
              <img
                style={{ maxWidth: 200 }}
                src={`${baseURL}/images/proxy?filename=${
                  record.dose_number
                }-instagram&url=${
                  record.speaker_dose_instagram_photo_url
                }&text=Dose %23${record.dose_number.toLocaleString(
                  "en",
                )}&textX=617&textY=18&textFont=montserrat-regular-80-white&textMaxWidth=100`}
                alt=""
              />
            )}
          />
          <FunctionField
            label="Podcast photo"
            render={(record) => (
              <img
                style={{ maxWidth: 200 }}
                src={`${baseURL}/images/proxy?filename=${
                  record.dose_number
                }-podcast&url=${
                  record.speaker_dose_podcast_photo_url
                }&text=Dose %23${record.dose_number.toLocaleString(
                  "en",
                )}&textX=497&textY=10&textFont=in901xl-63-blue&textMaxWidth=100`}
                alt=""
              />
            )}
          />
        </FormTab>

        <FormTab label="WhatsApp" replace fullWidth>
          <FunctionField
            label="WhatsApp text"
            render={(record) => <WhatsAppTemplate record={record} id={id} />}
          />
        </FormTab>
        <FormTab label="Instagram" replace fullWidth>
          <FunctionField
            label="Instagram text"
            render={(record) => <InstagramTemplate record={record} id={id} />}
          />
        </FormTab>
        <FormTab label="Schedules">
          <FunctionField
            label="Schedules"
            render={(record) => <SchedulesTab record={record} />}
          />
        </FormTab>
        <FormTab label="Email">
          <FunctionField
            label="Email"
            render={(record) => <EmailTab record={record} />}
          />
        </FormTab>
        <FormTab label="Text">
          <TextInput label="Text" multiline fullWidth source="text" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}
