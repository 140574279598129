import { Component, Fragment } from "react";
import {
  BulkDeleteButton,
  Button,
  useNotify,
  useRecordSelection,
  useRefresh,
} from "react-admin";
import { authClient } from "../../server";
import UpdateType from "./UpdateType";

class UpdateTypeButton extends Component {
  state = {
    isOpen: false,
  };

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleUpdate = async (type) => {
    this.setState({ isOpen: false });
    const { resource, selectedIds } = this.props;

    const showNotification = useNotify();
    const refreshView = useRefresh();
    const [selected, setListSelectedIds] = useRecordSelection(selectedIds);

    let url = "/admin/clipnshare/update-type";
    const payload = {
      ids: selectedIds,
    };
    if (type === "mark_used") {
      url = "/admin/clipnshare/update-clips";
      payload.update = { is_used: 1 };
    } else {
      payload.type = type;
    }

    setListSelectedIds.select(resource);
    refreshView();
    const results = await authClient.post(url, payload).catch((err) => {
      console.error(err);
      showNotification("actionUpdate.fail", "warning");
    });
    if (results) {
      showNotification("actionUpdate.success");
      setListSelectedIds.select(resource);
      refreshView();
    }
  };

  handleUpdateUsed = () => {
    this.handleUpdate("mark_used");
  };

  render() {
    const { selectedIds } = this.props;

    return (
      <Fragment>
        <Button label={`Mark Used`} onClick={this.handleUpdateUsed} />
        <Button
          label={`Update Type${selectedIds.length > 1 ? "s" : ""}`}
          onClick={this.handleClick}
        />
        <UpdateType
          open={this.state.isOpen}
          onClose={this.handleDialogClose}
          selectedIds={selectedIds}
          handleUpdate={this.handleUpdate}
        />
        <BulkDeleteButton {...this.props} undoable={false} />
      </Fragment>
    );
  }
}

export default UpdateTypeButton;
