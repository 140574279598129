import { Component, Fragment } from "react";
import { Button, Confirm, useNotify, useRefresh } from "react-admin";
import { authClient } from "../../server";

class SendEmailButton extends Component {
  state = {
    isOpen: false,
  };

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = async () => {
    this.setState({ isOpen: false });

    const refreshView = useRefresh();

    const { resource, selectedIds, setListSelectedIds } = this.props;

    refreshView();

    const notify = useNotify();
    const results = await authClient
      .post("/beta_users/send_emails", {
        ids: selectedIds,
      })
      .catch((err) => {
        console.error(err);
        notify("Emails Failed", "warning");
      });
    if (results) {
      notify("Emails sent");
      refreshView();
    }
  };

  render() {
    const { selectedIds } = this.props;

    return (
      <Fragment>
        <Button label="Send Email" onClick={this.handleClick} />
        <Confirm
          isOpen={this.state.isOpen}
          title="Send Beta Emails"
          content={`You are about to send ${selectedIds.length} emails.`}
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </Fragment>
    );
  }
}

export default SendEmailButton;
