import { AddCircleOutline } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { RefreshCcw } from "lucide-react";
import React from "react";
import { authClient, baseURL } from "../../server";

const commonUrls = [
  {
    name: "Home page rows (TA)",
    urls: [`${baseURL}/homepage-rows?project_id=1`],
  },
  {
    name: "Slideshow banners (App)",
    urls: [`${baseURL}/slideshow_banners/homepage`],
  },
  {
    name: "Slideshow banners (3.0)",
    urls: [`${baseURL}/slideshow_banners/homepage?project_id=1`],
  },
  {
    name: "Live Events",
    urls: [`${baseURL}/lectures/live`],
  },
  {
    name: "Lecture",
    urls: [`${baseURL}/lectures/{lectureId}`],
    parameter: "lectureId",
  },
  {
    name: "Ads",
    urls: [`${baseURL}/announcements/current`],
  },
];

const StaticPathField = ({ conf, onClick }) => {
  return (
    <div>
      {conf.name}
      <Button onClick={() => onClick(conf.urls)}>
        <AddCircleOutline />
      </Button>
    </div>
  );
};

const DynamicPathField = ({ conf, onClick }) => {
  const [dynamicValue, setDynamicValue] = React.useState();
  const urls = conf.urls.map((url) =>
    url.replace(new RegExp(`{${conf.parameter}}`), dynamicValue),
  );

  return (
    <div>
      {conf.name}
      <TextField
        placeholder={conf.parameter}
        onChange={(e) => setDynamicValue(e.target.value)}
      />
      <Button onClick={() => onClick(urls)}>
        <AddCircleOutline />
      </Button>
    </div>
  );
};

export const ManageCache = () => {
  const [selectedUrls, setSelectedUrls] = React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);

  const onAddUrls = (urls) => {
    setSelectedUrls([...selectedUrls, ...urls]);
  };
  return (
    <div>
      <h1>Manage Cache</h1>

      <TextField
        placeholder="https://api.torahanytime.com/homepage-rows"
        multiline
        value={selectedUrls.join("\n")}
        onChange={(e) => {
          setSelectedUrls(e.target.value.split("\n"));
        }}
        rows={5}
        style={{ width: "100%" }}
      />

      {commonUrls.map((conf) => (
        <div key={conf.name}>
          {conf.parameter ? (
            <DynamicPathField conf={conf} onClick={onAddUrls} />
          ) : (
            <StaticPathField conf={conf} onClick={onAddUrls} />
          )}
        </div>
      ))}

      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={async () => {
          setSubmitted(true);
          const res = await authClient.post(`/admin/manage-cache`, {
            urls: selectedUrls.filter(Boolean),
          });

          setSubmitted(false);

          if (res.json.errors.length > 0) {
            alert(
              "Error while requesting cache invalidation. Please see console logs for more details",
            );
            console.log("Request invalidation response", res.json);
          } else {
            alert("Requested cache invalidation. Can take up to 30 seconds.");
          }
        }}
        style={{ marginRight: 20 }}
        disabled={submitted}
      >
        Submit
      </Button>
    </div>
  );
};

export const Cache = {
  category: "misc",
  icon: RefreshCcw,
  name: "manage-cache",
  url: "/misc/manage-cache",
  label: "Manage Cache",
  exact: true,
  Element: <ManageCache />,
};
