import { AutocompleteInput, ReferenceInput } from "react-admin";
import { filterByName } from "../utils";

const OrganizationInput = (props) => {
  const {
    allowEmpty = true,
    label = "Organization",
    source = "org",
    reference = "organizations",
    filterToQuery = filterByName,
    perPage = 100,
    sort = { field: "name", order: "ASC" },
    ...rest
  } = props;
  return (
    <ReferenceInput
      allowEmpty={allowEmpty}
      label={label}
      source={source}
      reference={reference}
      // filterToQuery={filterToQuery}
      perPage={perPage}
      sort={sort}
      {...rest}
    >
      <AutocompleteInput filterToQuery={filterToQuery} optionText="name" />
    </ReferenceInput>
  );
};
export default OrganizationInput;
