import { JSONEditor } from "vanilla-jsoneditor";
import { useEffect, useRef, useState } from "react";
import { useInput } from "react-admin";
import "../../assets/css/JsonEditor.css";

function SvelteJSONEditor(props) {
  const refContainer = useRef(null);
  const refEditor = useRef(null);

  useEffect(() => {
    // create editor
    console.log("create editor", refContainer.current);
    refEditor.current = new JSONEditor({
      target: refContainer.current,
      props: {},
    });

    return () => {
      // destroy editor
      if (refEditor.current) {
        console.log("destroy editor");
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  // update props
  useEffect(() => {
    if (refEditor.current) {
      console.log("update props", props);
      refEditor.current.updateProps(props);
    }
  }, [props]);

  return <div className="vanilla-jsoneditor-react" ref={refContainer}></div>;
}

export function JsonInput(props) {
  const {
    field: { value, onChange },
  } = useInput({
    // Pass the event handlers to the hook but not the component as the field property already has them.
    // useInput will call the provided onChange and onBlur in addition to the default needed by react-hook-form.
    ...props,
  });
  const [content, setContent] = useState({
    json: value,
    text: undefined,
  });
  function handleChange(newValue) {
    setContent(newValue);
    onChange?.(newValue.json);
  }
  return (
    <SvelteJSONEditor
      content={content}
      readOnly={false}
      onChange={handleChange}
    />
  );
}
