import { DatabaseZap } from "lucide-react";
import {
  ArrayField,
  Datagrid,
  DateInput,
  List,
  TextField,
  TextInput,
} from "react-admin";
import { formatDateTimeInput } from "../../components";
import DateTimeField from "../../components/field/DateTimeField";

function ListView(props) {
  return (
    <List
      {...props}
      exporter={false}
      filters={[
        <TextInput label="User" source="user_name" alwaysOn />,
        <TextInput label="Table" source="table_name" alwaysOn />,
        <TextInput label="ID in Table" source="table_row_id" alwaysOn />,
        <TextInput label="Field" source="table_row_id" alwaysOn />,
        <DateInput
          label="From"
          source="created_at.>"
          alwaysOn
          format={formatDateTimeInput}
        />,
        <DateInput
          label="To"
          source="created_at.<"
          alwaysOn
          format={formatDateTimeInput}
        />,
      ]}
      title="DB Operations"
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="User" source="user_name" />
        <TextField label="Operation" source="operation" />
        <TextField label="Table" source="table_name" />
        <TextField label="ID in Table" source="table_row_id" />
        <ArrayField label="Changes" source="changes">
          <Datagrid>
            <TextField label="Field" source="field" />
            <TextField label="From" source="previous_value" />
            <TextField label="To" source="new_value" />
          </Datagrid>
        </ArrayField>
        <DateTimeField label="Date/Time" source="timestamp" />
      </Datagrid>
    </List>
  );
}

export const DbOperationLogs = {
  category: "misc",
  name: "db_operation_logs",
  //create: CreateView,
  //edit: EditView,
  icon: DatabaseZap,
  list: ListView,
  options: { label: "DB Operations Log" },
};
