import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  FormDataConsumer,
  FunctionField,
  Labeled,
  List,
  NumberField,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
// import { change } from "redux-form";
import { TextField, Typography } from "@mui/material";
import { Settings as Settings2 } from "lucide-react";
import {
  CardListActions,
  EditorToolbar,
  SubCategoryInput,
  validation,
} from "../components";
import JSONInputViewer from "./JSONInputViewer";

const styles = {
  hide: { display: "none" },
};
const defaultValue = {
  replace: 1,
};

const TypeName = ({ record }) => {
  // const split = record.type && record.type?.split("_") || '';
  // if (!split || !split.length) {
  //   return "";
  // }
  return <></>;
};
const CustomName = ({ record, source }) => {
  if (record?.type_id !== 1) {
    return <Typography>{record.display_name}</Typography>;
  }
  const data = record[source];
  return data.title ? (
    <Typography>{data.title}</Typography>
  ) : data.tag ? (
    <Typography>Vast Tag</Typography>
  ) : null;
};
const CustomValue = ({ record, source }) => {
  console.log({ settings: record });
  if (record.type_id !== 1 && record.value) {
    return <pre>{JSON.stringify(record.value)}</pre>;
  }
  const data = record[source];
  return data.title ? (
    <Typography>{data.display_title}</Typography>
  ) : data.tag ? (
    <Typography>{data.tag}</Typography>
  ) : null;
};
const DataConsumer = (props) => {
  return (
    <FormDataConsumer {...props}>
      {({ formData, dispatch, ...rest }) => {
        if (formData.type_id === 4) {
          return (
            <>
              <TextField
                style={styles.hide}
                source="name"
                defaultValue="vast_tag"
              />
              <TextInput source="value.tag" label="Vast tag" />
              <BooleanInput
                source="value.active"
                label="Active"
                format={(value) => value === 1}
                parse={(value) => (value === true ? 1 : 0)}
              />
            </>
          );
        }
        return (
          <>
            <TextInput
              source="display_name"
              style={{
                display: "block",
                width: 256,
              }}
            />
            {formData.type_id === 1 && (
              <SubCategoryInput
                filter={{
                  parent:
                    formData.name === "weekly_parsha"
                      ? 23
                      : formData.name === "upcoming_holiday"
                        ? 8
                        : undefined,
                }}
                source="value.id"
                label="Category"
                validate={[validation.required()]}
                {...rest}
              />
            )}
            {formData.type_id === 2 && (
              <>
                <TextField
                  style={styles.hide}
                  source="name"
                  defaultValue="featured_series"
                />
                <ReferenceInput
                  source="value.id"
                  reference="series"
                  label="Series"
                  validate={[validation.required()]}
                  filterToQuery={(searchText) => ({ title: searchText })}
                  style={{ width: 256 }}
                  {...rest}
                >
                  <AutocompleteInput optionText="title" />
                </ReferenceInput>
              </>
            )}
            {formData.type_id === 3 && (
              <>
                <TextField
                  style={styles.hide}
                  source="name"
                  defaultValue="featured_org"
                />
                <ReferenceInput
                  source="value.id"
                  reference="organizations"
                  label="Organizations"
                  validate={[validation.required()]}
                  filterToQuery={(searchText) => ({ name: searchText })}
                  style={{ width: 256 }}
                  {...rest}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </>
            )}
            {(formData.type_id === 5 || formData.type_id === 6) && (
              //<FunctionInput render={r => <SettingsInput record={r} />} />
              <>
                {/* <JSONInput
                  height={350}
                  onKeyPressUpdate={false}
                  placeholder={
                    typeof formData.value !== "object"
                      ? JSON.parse(formData.value)
                      : formData.value
                  }
                  onChange={(c) => {
                    console.log(c);
                    if (c.error === false) {
                      // return dispatch(change(REDUX_FORM_NAME, "value", c.json)); //@todo what is this
                    }
                  }
                  }
                /> */}
                {/*Hack to check if done so that it will reformat*/}
                {/* <Button >Done Editing</Button> */}
                <JSONInputViewer />
              </>
            )}
          </>
        );
      }}
    </FormDataConsumer>
  );
};

const filters = [
  <NumberInput label="ID" source="id" alwaysOn />,
  <TextInput label="Name" source="display_name" alwaysOn />,
  <SelectInput
    label="Active"
    source="display_active"
    alwaysOn
    choices={[
      { id: 1, name: "active" },
      { id: 0, name: "Not Active" },
    ]}
  ></SelectInput>,
];

function ListView(props) {
  return (
    <List
      {...props}
      filters={filters}
      sort={{ field: "name", order: "ASC" }}
      // bulkActionButtons={false}
      actions={<CardListActions />}
    >
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <NumberField source="display_active" label="Active" />
        <NumberField source="is_public" label="Public" />
        <FunctionField
          source="value"
          label="Name"
          render={(record) => (
            <CustomName source="value" label="Name" record={record} />
          )}
        />
        <FunctionField
          source="value"
          label="Value"
          render={(record) => (
            <CustomValue source="value" label="Value" record={record} />
          )}
        />
      </Datagrid>
    </List>
  );
}

const RenderCreateForm = ({ ...rest }) => {
  return (
    <SimpleForm
      toolbar={<EditorToolbar />}
      defaultValue={defaultValue}
      {...rest}
    >
      <ReferenceInput
        source="type_id"
        reference="settings_types"
        label="Setting Type"
      >
        <SelectInput />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData.type_id === 1 && (
              <>
                <SelectInput
                  source="name"
                  label="Type"
                  choices={[
                    {
                      value: "weekly_parsha",
                      name: "Weekly Parsha",
                    },
                    { value: "upcoming_holiday", name: "Upcoming Holidays" },
                  ]}
                  optionText="name"
                  optionValue="value"
                  {...rest}
                />
              </>
            )
          );
        }}
      </FormDataConsumer>
      <DataConsumer />
      <BooleanInput
        source="replace"
        label="Replace the current record"
        style={{ marginTop: 50 }}
      />
      <BooleanInput
        source="display_active"
        label="Active"
        style={{ marginTop: 50 }}
      />
      <BooleanInput
        source="is_public"
        label="Public"
        style={{ marginTop: 50 }}
      />
    </SimpleForm>
  );
};

function CreateView(props) {
  return (
    <Create {...props} redirect="list">
      {<RenderCreateForm />}
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      <SimpleForm toolbar={<EditorToolbar />}>
        <Labeled>
          <NumberField source="id" />
        </Labeled>
        <BooleanInput source="display_active" label="Active" />
        <BooleanInput source="is_public" label="Public" />
        <ReferenceInput
          source="type_id"
          reference="settings_types"
          label="Setting Type"
          // validate={[validation.required()]}
        >
          <SelectInput optionText={"type"} />
        </ReferenceInput>
        <DataConsumer />
      </SimpleForm>
    </Edit>
  );
}

export const Settings = {
  category: "tanext",
  name: "settings",
  create: CreateView,
  edit: EditView,
  icon: Settings2,
  list: ListView,
  options: { label: "Settings" },
};

export const SettingsTypes = {
  name: "settings_types",
  hidden: true,
};
