import ClearIcon from "@mui/icons-material/Clear";
import { FormControlLabel } from "@mui/material";
import classNames from "classnames";
import { Menu, Trash } from "lucide-react";
import React, { ReactNode } from "react";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { SortItem } from "../../../types/sortable";

const DragHandle = SortableHandle(() => (
  <span className={"dragHandle"} title="Drag to move.">
    <Menu />
  </span>
));

type SortableItemProps = {
  item: SortItem;
  renderItem: (
    item: SortItem,
    rowIndex: number,
    onChange: (index: number, field: string, value?: any) => void,
  ) => ReactNode;
  actions: { [key: string]: any };
  rowIndex: number;
  maxItems?: number;
};

const SortableItem = SortableElement((props: SortableItemProps) => {
  const { renderItem, actions, rowIndex, maxItems, item } = props;
  const { clearRow, deleteRow } = actions;

  return (
    <div className={"sortableRow"}>
      {!maxItems || maxItems > 1 ? <DragHandle /> : <></>}
      <div className={"sortableRowBody"}>
        <div className={"css.rowButtons"}>
          {clearRow && (
            <FormControlLabel
              control={<ClearIcon />}
              label="Clear"
              className={classNames("hover50", "css.rowButton")}
              title="Clear all fields."
              onClick={() => clearRow(rowIndex)}
            />
          )}
          {deleteRow && (!maxItems || maxItems > 1) ? (
            <FormControlLabel
              control={<Trash />}
              label="Delete"
              className={classNames("hover50", "css.rowButton")}
              title="Delete this row."
              onClick={() => deleteRow(rowIndex)}
            />
          ) : (
            <></>
          )}
        </div>
        {renderItem(item, rowIndex, actions.onChange)}
      </div>
    </div>
  );
});

export default SortableItem;
