import { id } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { formatDate, formatDateTime } from "../../components";
import { authClient } from "../../server";
import { Check, X } from "lucide-react";
import { useRecordContext, useNotify } from "ra-core";

const DeviceList = ({ styles }) => {
  const record = useRecordContext();
  const notify = useNotify();
  const [items, setItems] = useState<any>([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const res = await authClient
      .get(`/admin/users/${encodeURIComponent(record.id)}/devices`)
      .catch((error) => notify(error.body.message, { type: "error" }));
    if (res?.json) {
      setItems(res.json);
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      <div style={styles.row}>
        <span style={styles.header_120}>Created</span>
        <span style={styles.header_180}>Updated</span>
        <span style={styles.header_wide}>Device</span>
        <span style={styles.header_140}>IP</span>
        <span style={styles.header_100}>Verified</span>
        <span style={styles.header}>ID</span>
      </div>
      {items.map((item) => (
        <DeviceItem item={item} styles={styles} />
      ))}
    </div>
  );
};

const DeviceItem = ({ item, styles }) => {
  const created = formatDate(item.time_created);
  const updated = formatDateTime(item.time_updated);
  const verified = !!item.verified;
  return (
    <div key={item.id} style={styles.row} title={"id: " + item.id}>
      <span style={styles.col_120}>{created}</span>
      <span style={styles.col_180}>{updated}</span>
      <span style={styles.col_wide}>{item.agent_string}</span>
      <span style={styles.col_140}>{item.ip}</span>
      <span style={styles.col_100}>{verified ? <Check /> : <X />}</span>
      <span style={styles.col}>{item.id}</span>
    </div>
  );
};
export default DeviceList;
