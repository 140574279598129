import { UserCheck } from "lucide-react";
import { Datagrid, DateInput, List, NumberField, TextField } from "react-admin";
import {
  CardListActions,
  formatDateTimeInput,
  MorePagination,
} from "../../components";

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={[
        <DateInput
          label="From"
          source="date_start"
          alwaysOn
          format={formatDateTimeInput}
        />,
        <DateInput
          label="To"
          source="date_end"
          alwaysOn
          format={formatDateTimeInput}
        />,
      ]}
      perPage={100}
      pagination={<MorePagination />}
      sort={{ field: "total", order: "DESC" }}
      title="Speaker Stats"
    >
      <Datagrid rowClick="expand" bulkActionButtons={false}>
        <TextField source="name" style={{ minWidth: 180 }} />
        <NumberField source="total" />
        <NumberField source="video" />
        <NumberField source="audio" />
        <NumberField source="download" />
        <NumberField source="phone" />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
}

export const SpeakerStats = {
  category: "stats",
  name: "stats-speakers",
  icon: UserCheck,
  list: ListView,
  options: { label: "Speaker Stats" },
  roles: ["stat_viewer"],
  permissions: {
    stat_viewer: ["list"],
  },
};
