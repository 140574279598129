import React from "react";
import { ReferenceArrayInput, SelectArrayInput } from "react-admin";
import { filterByName } from "../utils";

export class ProjectsInput extends React.PureComponent {
  render() {
    return (
      <ReferenceArrayInput
        reference="projects"
        source="projects"
        label="Projects"
        // filterToQuery={filterByName}
        validate={(value) => {
          if (!value[0]) return "Project is required";
        }}
        {...this.props}
      >
        <SelectArrayInput
          filterToQuery={filterByName}
          allowEmpty={false}
          optionText="name"
        />
      </ReferenceArrayInput>
    );
  }
}
