import {
  AlignCenter,
  Database,
  Flame,
  Landmark,
  LayoutTemplate,
  List,
  Mail,
  MonitorCheck,
  PartyPopper,
  Pill,
  Puzzle,
} from "lucide-react";

const categories = [
  {
    name: "manage",
    icon: AlignCenter,
  },
  {
    label: "Corporate Sponsors",
    name: "corporate_sponsors",
    icon: Landmark,
  },
  {
    label: "Daily Dose",
    name: "dose",
    icon: Pill,
  },
  {
    name: "banners",
    icon: LayoutTemplate,
  },
  {
    name: "database",
    icon: Database,
  },
  {
    name: "stats",
    icon: List,
  },
  {
    name: "email",
    icon: Mail,
  },
  {
    name: "campaigns",
    icon: PartyPopper,
  },
  {
    name: "misc",
    icon: Puzzle,
  },
  {
    label: "TorahAnytime 3.0",
    name: "tanext",
    icon: MonitorCheck,
  },
  {
    label: "Tisha B'av",
    name: "tbav",
    icon: Flame,
  },
];

export default categories;
