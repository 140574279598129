import { useEffect, useState } from "react";
import { authClient } from "../../server";

const maxEmptyHeartbeats = 20;

export function TemporalProgressField({
  workflowId,
  activityNames,
  pollInterval = 600,
}) {
  const [shouldContinueUpdating, setShouldContinueUpdating] = useState(true);
  const [pendingActivitiesData, setPendingActivitiesData] = useState([]);
  const [numberOfEmptyHeartbeats, setNumberOfEmptyHeartbeats] = useState(0);

  useEffect(() => {
    if (shouldContinueUpdating) {
      const progressUpdaterId = setInterval(async () => {
        authClient
          // .get(`/admin/daily_dose/${doseNumber}/transcoding-progress`)
          .post("/admin/temporal/get-activity-heartbeats", {
            workflowId,
            activityNames,
          })
          .then((response) => {
            if (Array.isArray(response.json) && response.json.length > 0) {
              setPendingActivitiesData(response.json);
              // reset counter:
              setNumberOfEmptyHeartbeats(0);
            } else {
              if (numberOfEmptyHeartbeats >= maxEmptyHeartbeats) {
                setShouldContinueUpdating(false);
              } else {
                setNumberOfEmptyHeartbeats((prev) => prev + 1);
              }
            }
          });
      }, pollInterval);
      return () => {
        clearInterval(progressUpdaterId);
      };
    }
  }, [shouldContinueUpdating, numberOfEmptyHeartbeats]);

  return (
    <div>
      {pendingActivitiesData.map(
        ({ name, heartbeat }) =>
          heartbeat !== null && (
            <span>
              {name} -{" "}
              {heartbeat === null
                ? "0.0"
                : (heartbeat.progressRatio * 100).toFixed(1)}
              %
            </span>
          ),
      )}
    </div>
  );
}
