import Typography from "@mui/material/Typography";
import get from "lodash/get";
import PropTypes from "prop-types";

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  basePath,
  cellClassName,
  className,
  formClassName,
  headerClassName,
  label,
  linkType,
  locale,
  record,
  resource,
  sortable,
  sortBy,
  source,
  textAlign,
  translateChoice,
  ...rest
}) => rest;

const TextFieldEx = ({ className, max, source, record = {}, ...rest }) => {
  let value = get(record, source);
  value = value && typeof value !== "string" ? JSON.stringify(value) : value;
  let titleValue;
  if (max) {
    titleValue = value;
    if (value.length > max) {
      value = value.substr(0, max) + "...";
    }
  }
  return (
    <Typography
      component="span"
      body1="body1"
      className={className}
      title={titleValue}
      {...sanitizeRestProps(rest)}
    >
      {value}
    </Typography>
  );
};

TextFieldEx.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.number,
  record: PropTypes.object,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
TextFieldEx.displayName = "TextFieldEx";
// const PureTextFieldEx = pure(TextFieldEx);

// PureTextFieldEx.defaultProps = {
//   addLabel: true,
// };

export default TextFieldEx;
