import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { FetchJsonResponse, authClient } from "../../../server";
import icon from "@mui/icons-material/School";
//@ts-ignore
import SortableFormPage from "../../../components/page/SortableFormPage.tsx";
import { SortItem } from "../../../../types/sortable";
import { useNotify } from "ra-core";
import { GraduationCap } from "lucide-react";

const TodaysLearning = () => {
  const notify = useNotify();
  const [messages, setMessages] = useState<SortItem[]>([]);
  const [interval, setInterval] = useState(0);
  
  useEffect(() => {
    authClient
      .get("/admin/manage-banners/todays-learning")
      .then((res) => {
        setMessages(
          res.json.msgs.map((msg: string) => {
            return { text: msg };
          }),
        );
        setInterval(res.json.interval / 1000);
      })
      .catch((error) => notify(error.body.message, { type: "error" }));
  }, []);

  const handleSave = async () => {
    const data = {
      interval: interval * 1000,
      msgs: messages
        .map((i) => i.text)
        .filter((item) => ("" + item).trim().length > 0),
    };
    //@ts-ignore
    return authClient.put("/admin/manage-banners/todays-learning", data);
  };

  const handleChange = (index: number, value: string) => {
    const itemsCopy = messages.map((message, i) =>
      i == index ? { text: value } : message,
    );
    setMessages(itemsCopy);
  };
  
  return (
    <SortableFormPage
      title={"Today's Learning"}
      items={messages}
      setItems={setMessages}
      renderItem={(item, index, onChange) => (
        <TextField
          fullWidth
          label="Dedication message"
          value={item.text}
          onChange={(e) => handleChange(index, e.target.value)}
        />
      )}
      renderFields={() => (
        <>
          <TextField
            style={{ marginLeft: 24, width: 280 }}
            type="number"
            label="Seconds to display each message"
            value={interval}
            onChange={(e) => setInterval(+e.currentTarget.value || 0)}
            InputLabelProps={{ shrink: !!interval }}
          />
        </>
      )}
      handleSave={handleSave}
      createItem={() => {
        return { text: "" };
      }}
    />
  );
};

export default TodaysLearning;

export const TodaysLearningPage = {
  category: "banners",
  icon: GraduationCap,
  name: "todays-learning",
  url: "/manage-banners/todays-learning",
  label: "Today's Learning",
  exact: true,
  Element: <TodaysLearning />,
};
