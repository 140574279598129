import IconExpand from "@mui/icons-material/ChevronRight";
import IconCollapse from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { withStyles } from "@mui/styles";
import React from "react";

const styles = {
  listItem: {
    paddingLeft: "1rem",
    borderRadius: "0px 50px 50px 0px",
  },
  listItemText: {
    paddingLeft: 0,
    fontSize: "1rem",
    textAlign: "left",
  },
  closedListItem: {
    backgroundColor: "blue",
  },
  sidebarIsOpen: {
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    borderRadius: "0px 50px 50px 0px",
  },
  sidebarIsClosed: {
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
};

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  icon: Icon,
  isClosed,
  name,
  classes,
  children,
}) => (
  <React.Fragment>
    <ListItemButton
      onClick={handleToggle}
      // sx={classes.listItem}
      title={sidebarIsOpen ? "" : name}
    >
      <ListItemIcon sx={{ minWidth: 40 }}>
        <Icon />
      </ListItemIcon>
      <ListItemText
        primary={isClosed ? "" : name}
        secondary={isClosed ? name : ""}
        // sx={classes.listItemText}
      />
      {isClosed ? <IconExpand /> : <IconCollapse />}
    </ListItemButton>
    <Collapse in={!isClosed} timeout="auto" unmountOnExit>
      <List
        dense
        component="div"
        disablePadding
        className={
          sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
        }
      >
        {children}
      </List>
      <Divider />
    </Collapse>
  </React.Fragment>
);

const enhance = withStyles(styles);

export default enhance(SubMenu);
