import { CopyToClipboard } from "../../components";

export default function CopySnippet({ clip }) {
  const { lecture_view = {}, link, notes, title, ending } = clip;
  const {
    speaker,
    speaker_short_title,
    speaker_first_name,
    speaker_last_name,
  } = lecture_view || {};
  return (
    <CopyToClipboard
      textToCopy={`${title || ""}

${ending || ""}

${speaker_short_title} ${speaker_first_name} ${speaker_last_name}

${link}

MyTAT.me/s${speaker}
${notes ? "\n" + notes : ""}`}
    />
  );
}
