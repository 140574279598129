import { ImagePlus } from "lucide-react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  List,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin";
import { EditorToolbar } from "../../../components";

function validate(values) {
  const errors = {};
  if (!values.title) {
    errors.title = ["TITLE IS REQUIRED!"];
  }
  return errors;
}

function renderEditForm() {
  return (
    <TabbedForm
      validate={validate}
      // submitOnEnter={false} //deprecated, replaced with type='button'
      toolbar={<EditorToolbar type="button" />}
    >
      <FormTab label="Summary" replace>
        <ImageField
          source="photo"
          title="Default/Mobile Banner"
          style={{
            float: "right",
            width: "70%",
          }}
        />
        <ImageField
          source="photo_desktop_size"
          title="Wide/Desktop Banner"
          style={{
            float: "right",
            width: "70%",
          }}
        />
        <TextInput source="title" required fullWidth />
        <TextInput source="sub_title" />
        <TextInput source="link" />
        <TextInput source="link_text" />
        <BooleanInput source="target_blank" label="Open in new window" />
        <TextInput source="photo" label="Default/Mobile Banner URL" fullWidth />
        <ImageInput
          source="FILE_photo"
          label="Default/Mobile Banner Upload"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput
          source="photo_desktop_size"
          label="Wide/Desktop Banner URL"
          fullWidth
        />
        <ImageInput
          source="FILE_desktop"
          label="Wide/Desktop Banner Upload"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
      <FormTab label="Long Content" replace>
        <TextInput source="long_content" fullWidth />
      </FormTab>
      <FormTab label="Short Content" replace>
        <TextInput source="short_content" fullWidth />
      </FormTab>
      <FormTab label="HTML" replace>
        <TextInput source="html" fullWidth multiline />
      </FormTab>
    </TabbedForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      {renderEditForm()}
    </Edit>
  );
}

const listViewFilters = [<TextInput source="title" alwaysOn key="title" />];

function ListView(props) {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: "id", order: "DESC" }}
      filters={listViewFilters}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        <ImageField
          source="photo"
          title="Photo"
          style={{
            float: "right",
            width: "100%",
          }}
        />
        <TextField source="title" />
        <TextField source="sub_title" />
        <TextField source="html" />
        <TextField source="link" />
        <BooleanField source="target_blank" />
        <TextField source="link_text" />
      </Datagrid>
    </List>
  );
}

export const Banners = {
  category: "tanext",
  name: "banners",
  create: CreateView,
  edit: EditView,
  icon: ImagePlus,
  list: ListView,
  options: { label: "Banners" },
};
