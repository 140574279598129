import { Send } from "lucide-react";
import {
  Datagrid,
  DateField,
  FunctionField,
  ImageField,
  List,
  TextField,
  TextInput,
} from "react-admin";
import { Link } from "react-router-dom";
import { MorePagination } from "../../components";

const filters = [
  <TextInput label="Email" source="email" alwaysOn />,
  <TextInput label="Title" source="title" alwaysOn />,
  <TextInput label="Lecture ID" source="parent_lecture_id" alwaysOn />,
  <TextInput label="Clip" source="clip_number" alwaysOn />,
];

function ListView(props) {
  return (
    <List
      {...props}
      exporter={false}
      filters={filters}
      perPage={50}
      pagination={<MorePagination />}
      title="Dose Email Subscribers"
    >
      <Datagrid {...props}>
        <FunctionField
          source="id"
          render={(r) => (
            <Link to={`/email/daily-dose-emails?id=${r.id}`}>{r.id}</Link>
          )}
        />
        <TextField source="clip_number" label="Clip Number" />
        <TextField source="parent_lecture_id" label="Lecture ID" />
        <TextField source="title" label="Title" />
        <TextField source="recipients_count" label="Recipients" />
        <TextField source="feedbacks" label="Feedback" />
        <ImageField source="image_link" label="Image" />
        <DateField
          source="created_at"
          label="Created"
          showTime
          options={{
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />
      </Datagrid>
    </List>
  );
}

export const DoseEmailSent = {
  category: "dose",
  name: "dose-emails",
  list: ListView,
  icon: Send,
  options: { label: "Dose Emails Sent" },
  roles: ["dose_email_admin"],
};
