import React from "react";
import ReactDOM from "react-dom";

import history from "../history";
import { authUser } from "../server";

const { REACT_APP_UPLOADER, NODE_ENV } = process.env;
const __DEV__ = NODE_ENV === "development";

/**
 * Actions from `uploader/api/ParentWindowAPI.js`.
 */
export const Actions = {
  active_uploads: "active_uploads",
  create_lecture: "create_lecture",
  editing_lecture: "editing_lecture",
  init: "init",
  save_lecture: "save_lecture",
  saved_lecture: "saved_lecture",
  selected_upload: "selected_upload",
};

const styles = {
  iframeBg: {
    position: "fixed",
    top: 68,
    right: 40,
    width: 470,
    bottom: 0,
    zIndex: 999,
    backgroundColor: "white",
    textAlign: "right",
  },
  iframe: {
    border: "1px solid rgba(0, 0, 0, 0.42)",
    borderRadius: 3,
    borderRight: "none",
    padding: 4,
    paddingBottom: 30,
    backgroundColor: "white",
  },
};

function consoleLogDev(...args) {
  if (!__DEV__) {
    return;
  }
  console.log(...args);
}

function editingLecture(pathname) {
  const matches = (pathname || "").match(/^#\/lectures\/([0-9]+|create)/);
  if (matches && matches.length > 1) {
    if (matches[1] === "create") {
      // Creating
      return 0;
    }
    // Editing
    return parseInt(matches[1]);
  }
  // None
  return -1;
}

export const MediaUploadContext = React.createContext({});
/**
 * Panel to render uploader iframe.
 */
class MediaUploadPanel extends React.PureComponent {
  static contextType = MediaUploadContext;

  render() {
    const { iframeSrc, refIframe, showing } = this.context;
    return (
      <div
        style={{
          ...styles.iframeBg,
          visibility: showing ? "visible" : "hidden",
        }}
      >
        <iframe
          ref={refIframe}
          id="uploader"
          src={iframeSrc}
          title="Media Uploader"
          frameBorder="0"
          scrolling="yes"
          width="100%"
          height="100%"
          style={{
            ...styles.iframe,
            visibility: showing ? "visible" : "hidden",
          }}
        />
      </div>
    );
  }
}
/**
 * Portal renderering of `MediaUploadPanel`.
 */
const MediaUploadPortal = ReactDOM.createPortal(
  <MediaUploadPanel />,
  document.getElementById("uploader-root"),
);
/**
 * Provides the `MediaUploadContext` state and renders the `MediaUploadPortal`.
 */
export class MediaUploadProvider extends React.PureComponent {
  state = {
    // #region Values

    /** Count of files being actively uploaded. */
    activeUploadCount: 0,
    /** True if the user is allowed to access the media uploader. */
    allowed: this.props.allowed,
    /**
     * Reference to the iframe hosting the uploader.
     * @type {HTMLIFrameElement}
     */
    iframe: null,
    /** Source URL to load into the uploader host iframe. */
    iframeSrc: REACT_APP_UPLOADER,
    /** True if the upload iframe panel is showing. */
    showing: false,
    /**
     * Information about the currently selected upload file.
     * @type {{id:string,ext:string,mime_type:string,name:string,size:number,user_device_id:string}}
     */
    selected: null,

    // #endregion
    // #region Functions

    /**
     * Saves a reference to the uploader iframe.
     * @param {HTMLIFrameElement} iframe
     */
    refIframe: (iframe) => this.setState({ iframe }),
    /**
     * Toggles the `showing` state of the iframe.
     */
    toggle: () =>
      this.setState((state) => ({
        ...state,
        showing: !state.showing,
      })),

    // #endregion
  };

  onLogout = () => {
    this.setState({ showing: false });
  };

  onWindowMessage = (e) => {
    if (REACT_APP_UPLOADER.indexOf(e.origin) !== 0) {
      return;
    }
    const { action } = e.data;
    consoleLogDev("HANDLING: ", action, e.data);
    switch (action) {
      case Actions.active_uploads:
        this.setState({ activeUploadCount: e.data.count || 0 });
        break;
      case Actions.create_lecture:
        if (history.location.pathname !== "/lectures/create") {
          history.push("/lectures/create");
        }
        break;
      case Actions.init:
        onHistoryNavigate(history.location.hash);
        break;
      case Actions.save_lecture:
        if (!_lectureActions) {
          console.error("ERROR: No lecture actions to act on.");
          this.postMessage({
            action: Actions.saved_lecture,
            lectureId: -1,
          });
          return;
        }
        // console.log("LECTURE PROPS: ", _lectureActions.props);
        const {
          props: {
            handleSubmitWithRedirect,
            invalid,
            pristine,
            record: { id: lectureId },
            showNotification,
          },
        } = _lectureActions;
        if (lectureId > 0) {
          if (pristine) {
            this.postMessage({
              action: Actions.saved_lecture,
              lectureId,
            });
            return;
          } else if (invalid) {
            showNotification("ra.message.invalid_form", "warning");
            this.postMessage({
              action: Actions.saved_lecture,
              lectureId: -1,
            });
            return;
          }
        } else {
          if (pristine || invalid) {
            showNotification("ra.message.invalid_form", "warning");
            this.postMessage({
              action: Actions.saved_lecture,
              lectureId: -1,
            });
            return;
          }
        }
        handleSubmitWithRedirect(false)();
        // NEXT: Our lecture data action handlers will run (below) after saved.
        break;
      case Actions.selected_upload:
        this.setState({
          selected: e.data.file || null,
        });
        break;
      default:
        break;
    }
  };
  /**
   * Posts a message to the uploader iframe.
   * @param {any} message
   */
  postMessage = (message) => {
    const { iframe } = this.state;
    if (!iframe) {
      consoleLogDev("ERROR: Cannot postMessage with non-existant iframe.");
      return;
    }
    return iframe.contentWindow.postMessage(message, REACT_APP_UPLOADER);
  };

  componentDidMount() {
    window.addEventListener("message", this.onWindowMessage);
    authUser.onLogout(this.onLogout);
    _provider = this;
    providerRef.current = _provider;
  }

  componentWillUnmount() {
    _provider = undefined;
    providerRef.current = _provider;
    window.removeEventListener("message", this.onWindowMessage);
  }

  render() {
    const { allowed, children } = this.props;
    if (!allowed) {
      return children;
    }
    return (
      <MediaUploadContext.Provider value={this.state}>
        {MediaUploadPortal}
        {children}
      </MediaUploadContext.Provider>
    );
  }
}

/**
 * The currently instantiated `MediaUploadProvider`.
 * @type {MediaUploadProvider}
 */
let _provider;
export const providerRef = {
  current: undefined,
};

/**
 * The current lectures action functions.
 * @type {{handleSubmitWithRedirect:(redirect?:string|false)=>void}}
 */
let _lectureActions;

/**
 * Out of context API for media uploads. Import and use for anything except for
 * rendering.
 */
export const MediaUploader = {
  /** Gets the selected uploader file info. */
  get selected() {
    if (!_provider) {
      return undefined;
    }
    return _provider.state.selected || undefined;
  },
  setLectureActions(actions) {
    _lectureActions = actions;
  },
  /** Shows the uploader panel. */
  show(showing = true) {
    if (!_provider) {
      return;
    }
    _provider.setState({ showing });
  },
};

function onHistoryNavigate(hash) {
  const lectureId = editingLecture(hash);
  console.log(`MediaUploader editing Lecture: ${lectureId}`);
  _provider?.postMessage({
    action: Actions.editing_lecture,
    lectureId,
  });
  // If we are on the lecture edit/create page and the user is not a full admin,
  // show the uploader panel.
  if (lectureId > -1 && !authUser.isAdministrator && authUser.isAuthor) {
    MediaUploader.show();
  }
}

// history.listen((e) => onHistoryNavigate(e.location));
// window.addEventListener("hashchange", (event) => {
//   console.log(event.newURL);
// });
window.navigation?.addEventListener("navigate", (e) => {
  const hash = e.destination.url.split("#")[1];
  onHistoryNavigate("#" + hash);
});
