import { ScissorsSquare } from "lucide-react";
import { Launch, Delete, Undo } from "@mui/icons-material";
import {
  Button as MuiButton,
  IconButton,
  ButtonGroup,
  Switch,
} from "@mui/material";
import {
  BooleanInput,
  Button,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FormTab,
  FunctionField,
  List,
  ReferenceField,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  SelectInput,
  useRecordContext,
  useUpdate,
} from "react-admin";

import {
  CardListActions,
  CategoryInput,
  EditorToolbar,
  MorePagination,
  SpeakerInput,
  SubCategoryInput,
  validation,
} from "../../components";
import DateTimeField from "../../components/field/DateTimeField";
import { useCallback, useState } from "react";
import { authClient } from "../../server";
import { TemporalProgressField } from "../../components/field/TemporalProgressField";

const validate = {
  title: [validation.required(), validation.minLength(2)],
  email: [validation.required()],
  lectureUrl: [validation.required()],
  location: [validation.required()],
  clipLink: [validation.required()],
  category: [validation.required()],
  date_recorded: [validation.required()],
  language: [validation.required()],
  speaker: [validation.required()],
};

function CreateView(props) {
  return (
    <Create {...props} redirect="edit" title="Upload Clip">
      <SimpleForm toolbar={<EditorToolbar />}>
        {/* <TextInput
          source="clipper_email"
          label="Clipper Email"
          validate={validate.email}
        />
        <TextInput
          source="lecture_url"
          label="Lecture URL or Id"
          validate={validate.lectureUrl}
        />
        <TextInput
          source="clip_title"
          label="Clip Title"
          validate={validate.title}
        />
        <InputRow validate={validate.category}>
          <TextInput source="cat_id" label="Category Id" />
          <span>or choose:</span>
          <CategoryInput source="cat_id" allowEmpty />
        </InputRow>
        <InputRow>
          <TextInput source="sub_cat_id" label="Sub-Category Id" />
          <span>or choose:</span>
          <SubCategoryInput source="sub_cat_id" />
        </InputRow>
        <TextInput
          source="clip_link"
          label="Clip Link"
          validate={validate.clipLink}
        /> */}
        <div>
          "Create" form not currently available. Please create a Clip by using
          the App or Website, as the interface is much better suited for it.
        </div>
      </SimpleForm>
    </Create>
  );
}

const EditActions = () => (
  <CardListActions>
    <Button
      color="primary"
      onClick={() => window.alert("OK")}
      label="Export Emails"
      title="Exports the emails of all followers..."
    />
  </CardListActions>
);

function EditView(props) {
  return (
    <Edit
      {...props}
      redirect={false}
      undoable={false}
      actions={<EditActions />}
    >
      <TabbedForm toolbar={<EditorToolbar />}>
        <FormTab label="Clip Details" replace>
          <TextInput source="name" label="Title" />
          {/* <TextField source="ending" /> */}
          <SelectInput
            source="type"
            label="Worthiness"
            choices={[
              { id: "tat_clip", name: "TAT Clip" },
              { id: "dose", name: "Daily Dose" },
              { id: "pending", name: "Pending" },
              { id: "standard", name: "Standard" },
            ]}
          />
          <CategoryInput source="category" />
          <SubCategoryInput source="subcategory" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

function PublishingStatusOrActionsField({ record: clip }) {
  if (clip.tat_clip_lecture_id) {
    return (
      <ReferenceField
        source="tat_clip_lecture_id"
        label="Clip Lecture"
        reference="lectures"
      />
    );
  } else if (clip.daily_dose_id) {
    return (
      <ReferenceField
        source="daily_dose_id"
        label="Dose"
        reference="daily_dose"
      />
    );
  } else if (clip.type === "tat_clip") {
    return (
      <MuiButton
        variant="outlined"
        onClick={() => {
          alert("Soon to be Implemented");
        }}
      >
        Publish Clip
      </MuiButton>
    );
  } else if (clip.type === "dose") {
    return (
      <MuiButton
        variant="outlined"
        onClick={() => {
          alert("Soon to be Implemented");
        }}
      >
        Publish Dose
      </MuiButton>
    );
  } else {
    return <span>-</span>;
  }
}

function WorthinessOrActionsField() {
  const clip = useRecordContext();
  const [update, { isPending, error }] = useUpdate();

  function handleMakeDoseClick() {
    // update("clips_new", {
    //   id: clip.id,
    //   data: { has_potential: true },
    //   previousData: clip,
    // });
    update("clips_new", {
      id: clip.id,
      data: { type: "dose" },
      previousData: clip,
    });
  }
  function handleMakeClipClick() {
    update("clips_new", {
      id: clip.id,
      data: { type: "tat_clip" },
      previousData: clip,
    });
  }
  function handleStandardClick() {
    update("clips_new", {
      id: clip.id,
      data: { type: "standard" },
      previousData: clip,
    });
  }
  if (clip.type === "tat_clip" || clip.type === "dose") {
    return (
      <div>
        <TextField source="type" label="Worthiness" />
        <IconButton
          variant="contained"
          color="error"
          size="small"
          onClick={handleStandardClick}
        >
          <Undo />
        </IconButton>
      </div>
    );
  } else if (clip.type === "standard") {
    return (
      <ButtonGroup variant="contained" aria-label="Basic button group">
        <MuiButton onClick={handleMakeDoseClick}>Dose</MuiButton>
        <MuiButton onClick={handleMakeClipClick}>Clip</MuiButton>
      </ButtonGroup>
    );
  } else {
    return <span>-</span>;
  }
}

function ReviewedSwitch() {
  const clip = useRecordContext();
  const [update, { isPending, error }] = useUpdate();

  function handleToggle() {
    update("clips_new", {
      id: clip.id,
      data: { is_reviewed: !clip.is_reviewed },
      previousData: clip,
    });
  }

  return <Switch checked={clip.is_reviewed} onChange={handleToggle} />;
}

function HasPotentialSwitch() {
  const clip = useRecordContext();
  const [update, { isPending, error }] = useUpdate();

  function handleToggle() {
    update("clips_new", {
      id: clip.id,
      data: { has_potential: !clip.has_potential, is_reviewed: true },
      previousData: clip,
    });
  }

  return <Switch checked={clip.has_potential} onChange={handleToggle} />;
}

function DownloadMp4Button() {
  const clip = useRecordContext();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");

  const handleClick = useCallback(() => {
    setIsLoading(true);
    authClient
      .post("/admin/clips_new/get_temporary_link_to_mp4", {
        id: clip.id,
      })
      .then((res) => {
        setIsLoading(false);
        setUrl(res.json.url);
      });
  }, [clip.id]);
  if (isLoading) {
    return (
      <div>
        <span>Loading...</span>
        <TemporalProgressField
          workflowId={`TemporaryVideoClip-${clip.id}`}
          activityNames={["downloadFileById"]}
          pollInterval={1000}
        />
      </div>
    );
  } else if (url === "") {
    return (
      <MuiButton variant="outlined" onClick={handleClick}>
        Get URL
      </MuiButton>
    );
  } else {
    return (
      <a target="_blank" href={url} rel="noopener noreferrer">
        Download
      </a>
    );
  }
}

const filters = [
  <TextInput source="id" />,
  <TextInput source="name" label="Title" alwaysOn />,
  <SpeakerInput
    label="Speaker"
    source="speaker_id"
    reference="speakers"
    alwaysOn
  />,
  <BooleanInput source="is_reviewed" label="Is Reviewed?" />,
  <BooleanInput source="has_potential" label="Has Potential?" />,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={filters}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title="Clips"
    >
      <Datagrid rowClick={false}>
        <EditButton />
        <TextField source="id" />
        {/* <ImageField source="thumbnail" label="Thumbnail" /> */}
        <ReferenceField
          source="speaker_id"
          label="Speaker"
          reference="speakers"
        />
        <FunctionField
          label="Watch"
          render={(record) => (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://clips.torahanytime.com/clips/${record.uuid}`}
            >
              <Launch />
            </a>
          )}
        />
        <WorthinessOrActionsField label="Worthiness" />
        <FunctionField
          label="Publish"
          render={(record) => (
            <PublishingStatusOrActionsField record={record} />
          )}
        />
        <TextField source="name" label="Title" />
        <DateTimeField source="created_at" style={{ minWidth: 150 }} />
        <ReferenceField source="user_id" label="Clipped by" reference="users" />
        <DownloadMp4Button label="Get mp4 Link" />
        <HasPotentialSwitch label="Has Potential?" />
        <ReviewedSwitch label="Reviewed?" />
        {/* <ReferenceField
          source="reviewer_user_id"
          label="Reviewed by"
          reference="users"
        /> */}
      </Datagrid>
    </List>
  );
}

export const Clips = {
  category: "database",
  name: "clips_new",
  list: ListView,
  create: CreateView,
  icon: ScissorsSquare,
  options: { label: "Clips (New Interface)" },
  edit: EditView,
};
