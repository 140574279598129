import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { authClient } from "../../../server";
import icon from "@mui/icons-material/Slideshow";
//@ts-ignore
import SortablePage from "../../../components/page/SortableFormPage.tsx";
import { SortItem } from "../../../../types/sortable";
import { ChevronDown, GalleryVertical } from "lucide-react";

const SlideShow = () => {
  const [items, setItems] = useState<SortItem[]>([]);
  const [auto_start, setAutoStart] = useState(false);
  const [interval, setInterval] = useState(0);
  const [undoing, setUndoing] = useState(false);

  useEffect(() => {
    getSlideshowItems().then((res) => {
      console.log({ res });
      setAutoStart(res.json.auto_start);
      setInterval(res.json.interval);
      setItems(res.json.slides);
    });
  }, []);

  const createItem = () => {
    return {
      display: true,
      image: "",
      title: "",
      link: "",
      onclick: () => {},
      height: 0,
      interval: 0,
      width: 0,
    };
  };

  const getSlideshowItems = async () => {
    return authClient.get("/admin/manage-banners/slideshow");
  };

  const handleSave = async () => {
    // return;
    const data = {
      auto_start: auto_start ? 1 : 0,
      interval: interval * 1000,
      // TODO: Remove slides where all the fields are empty...
      slides: items, // .filter(item => ("" + item).trim().length > 0),
    };
    //@ts-ignore I know this is not a great idea @todo figure out what's wrong with data- stringify made typescript happy but then obv the request failed
    return authClient.put("/admin/manage-banners/slideshow", data);
  };

  return (
    <SortablePage
      title="Slideshow Banners"
      info="NOTE: If HTML Code is set, it will be displayed instead of the Image."
      items={items}
      setItems={setItems}
      renderItem={(item, rowIndex, onChange) => (
        <SlideshowItem onChange={onChange} item={item} index={rowIndex} />
      )}
      renderFields={() => (
        <SlideshowFields
          interval={interval}
          setInterval={setInterval}
          auto_start={auto_start}
          setAutoStart={setAutoStart}
          undoing={undoing}
          setUndoing={setUndoing}
        />
      )}
      handleSave={handleSave}
      createItem={createItem}
    />
  );
};

export default SlideShow;

type SlideshowItemProps = {
  item: SortItem;
  index: number;
  onChange: (rowIndex: number, field: string, value?: any) => void;
};

const SlideshowItem = ({ onChange, item, index }: SlideshowItemProps) => {
  return (
    <div>
      <details>
        <summary>
          <TextField
            label="Title"
            value={item.title}
            onChange={(e) => onChange(index, "title", e.target.value)}
          />
          <TextField
            fullWidth
            label="Image URL"
            value={item.image}
            onChange={(e) => onChange(index, "image", e.target.value)}
          />
          <TextField
            fullWidth
            label="Link"
            value={item.link}
            onChange={(e) => onChange(index, "link", e.target.value)}
          />
          <ChevronDown className="openclose" />
        </summary>
        <div className="hidden">
          <TextField
            fullWidth
            label="JS"
            placeholder="JS onclick function()"
            value={item.onclick}
            onChange={(e) => onChange(index, "onclick", e.target.value)}
          />
          <TextField
            fullWidth
            label="HTML"
            value={item.html}
            onChange={(e) => onChange(index, "html", e.target.value)}
            multiline={true}
          />
        </div>
      </details>
    </div>
  );
};

type SlideshowFieldsProps = {
  interval: number;
  setInterval: (i: number) => void;
  auto_start: boolean;
  setAutoStart: (i: boolean) => void;
  undoing: boolean;
  setUndoing: (i: boolean) => void;
};

const SlideshowFields = (props: SlideshowFieldsProps) => {
  const {
    interval,
    setInterval,
    auto_start,
    setAutoStart,
    undoing,
    setUndoing,
  } = props;

  return (
    <div style={{ margin: 24 }}>
      <TextField
        type="number"
        label="Seconds to display each message"
        value={interval}
        onChange={(e) => setInterval(+e.currentTarget.value || 0)}
        InputLabelProps={{ shrink: !!interval }}
      />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={auto_start}
            onChange={(e) => setAutoStart(e.currentTarget.checked)}
          />
        }
        label="Auto-start slideshow rotation"
      />

      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={undoing}
            onChange={(e) => setUndoing(e.currentTarget.checked)}
          />
        }
        label="Toggle Save / Undo"
        title="Check or uncheck to enable save button, if disabled, to save an old version of the form data."
      />
    </div>
  );
};

export const SlideshowPage = {
  category: "banners",
  icon: GalleryVertical,
  name: "slideshow-banners",
  url: "/manage-banners/slideshow",
  label: "Slideshow",
  exact: true,
  Element: <SlideShow />,
};