import { Lightbulb } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CardListActions, EditorToolbar, validation } from "../../components";

const validate = {
  name: [validation.required(), validation.minLength(2)],
  //description: [validation.required(), validation.number()],
};

function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <TextInput source="name" label="Name" validate={validate.name} />
      <TextInput
        source="description"
        label="Description"
        validate={validate.name}
      />
    </SimpleForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm()}
    </Create>
  );
}
// SanitizedP is how we resolve the below issue for <p></p> DOM element
// Warning: React does not recognize the `basePath` prop on a DOM element.  If you
// accidentally passed it from a parent component, remove it from the DOM
// element.
const SanitizedP = ({ basePath, ...props }) => {
  return <p {...props} />;
};
function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      {renderEditForm()}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={[]}
      perPage={25}
      sort={{ field: "name", order: "ASC" }}
      title="Projects"
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" label="Name" />
        <TextField source="description" label="Description" />
      </Datagrid>
    </List>
  );
}

export const Projects = {
  category: "database",
  name: "projects",
  create: CreateView,
  edit: EditView,
  icon: Lightbulb,
  list: ListView,
  options: { label: "Projects" },
};
