import React, { useState } from "react";
import { useController } from "react-hook-form";
import JSONInput from "react-json-editor-ajrm";
const JSONInputViewer = () => {
  // const input1 = useController({ name: 'value', defaultValue: '' });
  const { field } = useController({ name: "value", defaultValue: "" });

  const [value, setValue] = React.useState(field.value);
  const [jsonValueError, SetJsonValueError] = useState("");

  return (
    <span>
      {/* <input  {...input1.field} type="text" placeholder="latitude" /> */}
      <JSONInput
        height={350}
        onKeyPressUpdate={false}
        placeholder={typeof value !== "object" ? JSON.parse(value) : value}
        onBlur={(c) => {
          console.log(c);
          if (c.error === false) {
            field.onChange(c.json, ""); // send data to hook form
            setValue(c.json);
          } else {
            SetJsonValueError("Invalid Json: " + c.error.reason);
            setTimeout(() => {
              SetJsonValueError("");
            }, 3000);
          }
        }}
        onChange={(c) => {
          console.log(c);
          if (c.error === false) {
            field.onChange(c.json, ""); // send data to hook form
            setValue(c.json);
          } else {
            SetJsonValueError("Invalid Json: " + c.error.reason);
            setTimeout(() => {
              SetJsonValueError("");
            }, 3000);
          }
        }}
      />
      {jsonValueError ? <p className="error">{jsonValueError}</p> : ""}
    </span>
  );
};
export default JSONInputViewer;
