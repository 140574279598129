import { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { CopyToClipboard } from "../../components";
import { authClient } from "../../server";

import { Tv } from "lucide-react";

export const PlayerList = {
  category: "misc",
  icon: Tv,
  name: "manage-player",
  url: "/misc/manage-player",
  label: "Manage Player",
  exact: true,
  Element: <SelectVideo />,
};

export function SelectVideo() {
  const [playerList, setPlayerList] = useState([]);

  useEffect(() => {
    (async function () {
      const response = await authClient.get(`/admin/get-players`);
      setPlayerList(JSON.parse(response.body).items);
    })();
  }, []);
  const generateEmbedCode = (playername) => `
  <style type="text/css">@media (max-width: 680px) {
    .ta-iframe-CsvP2vbG6FR {max-height:350px}
  }
  </style>
  <div class="ta-iframe-CsvP2vbG6FR" style="height:100%;min-height:350px;width:calc(100% + 20px);">
  <iframe allowfullscreen="" class="ta-iframe-CsvP2vbG6FR" frameborder="0" height="480" scrolling="no" src="https://player.torahanytime.com/?player_name=${playername}" width="100%"></iframe>
  </div>`;

  return (
    <div>
      <h1>Select Player</h1>
      <br />
      <ul>
        {playerList.map((p) => (
          <li style={{ margin: 20 }}>
            <div>
              <h3>{p}</h3>
              <div style={{ position: "relative" }}>
                <Collapsible trigger="Embed Code - (adjust styling as needed)">
                  <p>
                    <pre>{generateEmbedCode(p)}</pre>
                  </p>
                </Collapsible>
                <CopyToClipboard
                  style={{ position: "absolute", top: 0, left: 290 }}
                  textToCopy={generateEmbedCode(p)}
                />
              </div>

              <div>
                <a
                  target="_blank"
                  href={`https://player.torahanytime.com/?player_name=${p}`}
                  rel="noopener noreferrer"
                >
                  Player Link
                </a>
              </div>
              <div>
                {/* @todo use link component */}
                <a href={`/#/misc/manage-player/${p}`}>Channel List</a>
              </div>
              <div>
                <a href={`/#/misc/manage-player-settings/${p}`}>
                  Manage Settings
                </a>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
