import { Building2 } from "lucide-react";
import { RichTextInput } from "ra-input-rich-text";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  FormTab,
  ImageField,
  List,
  NumberInput,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin";
import { ColorInput } from "react-admin-color-picker";
import { CardListActions, EditorToolbar } from "../../components";
import { booleanToInteger, integerToBoolean } from "../../components/utils";

const filters = [
  <BooleanInput
    label="Active"
    source="display_active"
    alwaysOn
    parse={booleanToInteger}
    format={integerToBoolean}
  />,
  <TextInput source="id" alwaysOn />,
  <TextInput label="Name" source="name" alwaysOn />,
];

function RenderEditForm() {
  // const { id, field, fieldState, formState, isRequired, onChange } = useInput({
  //   source: "desc",
  // });
  return (
    <TabbedForm toolbar={<EditorToolbar />}>
      <FormTab label="Summary" replace>
        <ImageField
          source="logo_url"
          title="Photo"
          style={{
            float: "right",
            width: "70%",
          }}
        />
        <TextInput source="name" label="Name" />
        <TextInput source="logo" label="Logo" />
        <BooleanInput source="display_active" label="Active" />
        <BooleanInput
          source="are_lectures_not_discoverable_by_default"
          label="Hidden"
        />
        <BooleanInput source="is_host" label="Host" />
        <TextInput source="dedication" label="Dedication" />
        <NumberInput source="dial_in_ext" label="Dial In Extension" />
      </FormTab>
      <FormTab label="Description" replace>
        <RichTextInput source="desc" addLabel={false} />
      </FormTab>
      <FormTab label="Theme" replace>
        <TextInput source="theme.bg_image" label="Background image" />
        <ColorInput source="theme.bar_bg_color" label="Bar background color" />
        <ColorInput source="theme.color" label="Color" />
        <ColorInput source="theme.text_color" label="Text color" />
        <ColorInput source="theme.title_color" label="Title color" />
      </FormTab>
    </TabbedForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {RenderEditForm()}
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      {RenderEditForm()}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filterDefaultValues={{ display_active: 1 }}
      filters={filters}
      title="Organizations"
    >
      <Datagrid rowClick="edit">
        <ImageField source="logo_url" label="Logo" />
        <TextField source="name" style={{ minWidth: 240 }} label="Name" />
        <BooleanField source="display_active" label="Active" />
        <BooleanField source="is_host" label="Host" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const Organizations = {
  category: "database",
  name: "organizations",
  create: CreateView,
  edit: EditView,
  icon: Building2,
  list: ListView,
};
