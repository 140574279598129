import {
  Button,
  InputAdornment,
  TextField as MaterialTextField,
} from "@mui/material";
import { PlayCircle } from "lucide-react";
import { useEffect, useState } from "react";
import {
  BooleanField,
  // useRecordContext,
  BooleanInput,
  Create,
  Datagrid,
  DateInput,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  InfiniteList,
  List,
  NumberInput,
  RadioButtonGroupInput,
  TabbedForm,
  TextField,
  TextInput,
  UrlField,
  useNotify,
  usePermissions,
} from "react-admin";

import { Shuffle } from "@mui/icons-material";
import {
  FormatButtons,
  LinkButtons,
  ListButtons,
  RichTextInput,
  RichTextInputToolbar,
} from "ra-input-rich-text";
import {
  CardListActions,
  CategoryInput,
  CopyToClipboard,
  EditorToolbar,
  EventSpeakersInput,
  HostInput,
  LanguageInput,
  LocationInput,
  MorePagination,
  OrganizationInput,
  SpeakerInput,
  SubCategoryInput,
  validation,
} from "../../components";
import DateTimeField from "../../components/field/DateTimeField";
import SanitizedP from "../../components/field/SanitizedP";
import { ProjectsInput } from "../../components/input/ProjectsInput";
import { authClient, authUser } from "../../server";
import { MediaUploader } from "../../uploader";
import { LectureRethink } from "./LectureRethink";

const styles = {
  label: {
    fontWeight: 400,
    padding: 0,
    marginTop: 16,
    marginBottom: 2,
    fontSize: 12,
  },
  url: {
    margin: 2,
  },
};

const defaultValue = {
  on_recent_list: 1,
};

const validate = {
  title: [validation.required(), validation.minLength(2)],
  date_recorded: [validation.required()],
  language: [validation.required()],
  location: [validation.required()],
  speaker: [validation.required()],
  category: [validation.required()],
};

const ResendFollowingEmailButton = (props) => {
  const showNotification = useNotify();
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      record: { id },
    } = props;
    if (!window.confirm("Are you sure?")) {
      return;
    }
    authClient
      .post(`/admin/lectures/${id}/resend-following-email`, {})
      .then(() => {
        showNotification("Following email sent.");
      })
      .catch((err) => {
        showNotification(err, { type: "error" });
      });
  };

  return <Button onClick={handleClick}>Resend Following Email</Button>;
};

/**
 *
 * @param {Object} params
 * @param {number} params.lectureId - lecture id
 * @param {number} params.duration - lecture duration
 * @returns
 */
const UpdateThumbnail = (params) => {
  const { lectureId, duration } = params;

  const [thumbnailUpdateSeconds, setThumbnailUpdateSeconds] = useState(null);
  const [updatingThumbnail, setUpdatingThumbnail] = useState(false);

  const error = thumbnailUpdateSeconds > duration;
  return (
    <div>
      <MaterialTextField
        style={{
          width: 200,
        }}
        type="number"
        placeholder="seconds"
        onChange={(event) => setThumbnailUpdateSeconds(event.target.value)}
        value={thumbnailUpdateSeconds || ""}
        error={error}
        helperText={
          error ? "Can't be greater than duration of lecture" : undefined
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setThumbnailUpdateSeconds(
                    Math.floor(Math.random() * duration) + 1,
                  );
                  console.log("updating");
                }}
              >
                <Shuffle />
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <Button
        color="primary"
        variant="text"
        disabled={!thumbnailUpdateSeconds || updatingThumbnail}
        onClick={async () => {
          setUpdatingThumbnail(true);
          await authClient.post(`admin/lectures/${lectureId}/thumbnail`, {
            time: parseInt(thumbnailUpdateSeconds),
          });

          // eslint-disable-next-line no-restricted-globals
          location.reload();
          // Instead of this, we should figure out how to inject
          // the new thumbnail id into React Admin state.
        }}
      >
        Save
      </Button>
    </div>
  );
};

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      <TabbedForm
        defaultValue={defaultValue}
        toolbar={
          <EditorToolbar
            registerTrigger={MediaUploader.setLectureActions}
            showSave={authUser.isAdministrator}
          />
        }
        submitOnEnter={false}
      >
        <FormTab label="Details" replace>
          <TextInput source="title" validate={validate.title} autoFocus />
          <DateInput source="date_recorded" validate={validate.date_recorded} />

          <LanguageInput validate={validate.language} />
          <LocationInput validate={validate.location} />
          <HostInput />
          <OrganizationInput />
          <SpeakerInput validate={validate.speaker} />
          <CategoryInput validate={validate.category} />
          <SubCategoryInput />
          <EventSpeakersInput />

          <BooleanInput
            source="on_recent_list"
            label="Show on 'Recent Lectures' on Home Page"
            defaultValue={1}
          />
          <BooleanInput
            source="auto_display"
            label="Auto Send Email and Publish When Encoding Completes"
            defaultValue={1}
          />
          <BooleanInput source="no_download" label="Disable downloads" />
          <BooleanInput
            source="ladies"
            label="Only Ladies can View After Login"
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
}

function EditView(props) {
  // const {
  //   location: { pathname = "" },
  //   id,
  // } = props;
  // console.log({lectureprops:props});
  const [onRethink, setOnRethink] = useState(false);
  useEffect(() => {
    const tabIndex = window.location.hash.split("/").pop();
    if (tabIndex === "/2") {
      setOnRethink(true);
    } else {
      setOnRethink(false);
    }
  }, [window.location.hash]);

  // return <EditGuesser {...props} />;
  return (
    <Edit {...props} redirect={false} undoable={false}>
      <TabbedForm
        toolbar={
          onRethink ? (
            <div></div>
          ) : (
            <EditorToolbar
              registerTrigger={MediaUploader.setLectureActions}
              handleSubmitWithRedirect={(redirect) => () => {}}
            >
              <ResendFollowingEmailButton toolbarProps />
              <DeleteWithConfirmButton />
            </EditorToolbar>
          )
        }
        submitOnEnter={false}
      >
        <FormTab label="Details" replace>
          <ProjectsInput />
          <h6 style={styles.label}>Website</h6>
          <UrlField
            source="url"
            label="Website"
            target="_blank"
            rel="noopener noreferrer"
          />
          <h6 style={styles.label}>Vimeo</h6>
          <UrlField
            source="vimeo_url"
            label="Vimeo"
            target="_blank"
            rel="noopener noreferrer"
          />
          <h6 style={styles.label}>Video File</h6>
          <UrlField
            source="download_url"
            label="Video File"
            target="_blank"
            rel="noopener noreferrer"
          />
          <h6 style={styles.label}>Audio File</h6>
          <UrlField
            source="audio_download_url"
            label="Audio File"
            target="_blank"
            rel="noopener noreferrer"
          />
          <h6 style={styles.label}>Video Shortlink</h6>
          <FunctionField
            source="bitly_video"
            label="Video Shortlink"
            style={{ width: "inherit" }}
            render={(record) => {
              return (
                <div style={{ display: "flex" }}>
                  <UrlField
                    record={record}
                    source="bitly_video"
                    label="Video Shortlink"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                  <CopyToClipboard textToCopy={record.bitly_video} />
                </div>
              );
            }}
          />
          <h6 style={styles.label}>Audio Shortlink</h6>
          <FunctionField
            source="bitly_audio"
            label="Audio Shortlink"
            style={{ width: "inherit" }}
            render={(record) => {
              return (
                <div style={{ display: "flex" }}>
                  <UrlField
                    record={record}
                    source="bitly_audio"
                    label="Audio Shortlink"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                  <CopyToClipboard textToCopy={record.bitly_video} />
                </div>
              );
            }}
          />

          <SanitizedP>Originals are stored in box.</SanitizedP>
          <h6 style={styles.label}>Box</h6>
          <UrlField
            source="box_url"
            label="Box"
            target="_blank"
            rel="noopener noreferrer"
          />

          <SanitizedP>Originals are kept in trash for a 30 days</SanitizedP>
          <h6 style={styles.label}>Trash</h6>
          <UrlField
            source="cloud_trash_url"
            label="Trash"
            target="_blank"
            rel="noopener noreferrer"
          />
          <h6 style={styles.label}>Originals</h6>
          <UrlField
            source="cloud_url"
            label="Originals"
            target="_blank"
            rel="noopener noreferrer"
          />

          <h6 style={styles.label}>Original File Name</h6>
          <TextField source="original_file" label="original file" />

          <TextInput source="thumbnail" />
          <ImageField source="thumbnail_url" label="Thumbnail" />
          <FunctionField
            label="Change Thumbnail"
            render={(record) => (
              <UpdateThumbnail
                lectureId={record.id}
                duration={record.duration}
              />
            )}
          />
          <ImageInput
            source="FILE_thumbnail"
            label="Thumbnail Upload"
            accept="image/*"
            placeholder={<p>Click or drop a photo here.</p>}
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <BooleanInput source="display_active" label="Display" />
          <BooleanInput
            source="auto_display"
            label="Auto Send Email and Publish When Encoding Completes"
          />
          <TextInput source="title" validate={validate.title} fullWidth />
          <BooleanInput source="title_rtl" label="Title Right-to-Left" />
          <NumberInput source="duration" label="Duration (in seconds)" />
          <DateTimeInput source="date_created" />
          <DateInput source="date_recorded" validate={validate.date_recorded} />
          <DateTimeInput source="date_to_show" />
          <DateTimeInput source="publish_by_date" />
          <LanguageInput validate={validate.language} />
          <LocationInput validate={validate.location} />
          <HostInput />
          <OrganizationInput />
          <SpeakerInput validate={validate.speaker} />
          <CategoryInput validate={validate.category} />
          <SubCategoryInput />
          <EventSpeakersInput />
          <BooleanInput
            source="on_recent_list"
            label="Show on 'Recent Lectures' on Home Page"
          />
          <BooleanInput source="no_download" label="Disable downloads" />
          <BooleanInput
            source="ladies"
            label="Only Ladies can View After Login"
          />
          <BooleanInput source="private" label="Private Lecture" />
          <TextInput
            source="private_code"
            label="Private Lecture Access Code"
          />
          <TextInput source="dedication" label="Dedication message" fullWidth />
          <RichTextInput
            width={"100%"}
            source="desc"
            toolbar={
              <RichTextInputToolbar>
                <FormatButtons size="large" />
                <ListButtons size={"large"} />
                <LinkButtons size={"large"} />
              </RichTextInputToolbar>
            }
          />
        </FormTab>
        <FormTab label="Access Restriction Flags" replace>
          <BooleanInput source="display_active" label="Display" />
          <BooleanInput
            source="is_only_watchable_by_female"
            label="Only Watchable by Female"
          />
          <BooleanInput
            source="is_only_listenable_by_female"
            label="Only Listenable by Female"
          />
          <BooleanInput
            source="is_only_discoverable_by_female"
            label="Only Discoverable by Female"
          />
          <BooleanInput
            source="is_only_discoverable_within_org"
            label="Only Discoverable within Org"
          />
          <BooleanInput source="is_not_discoverable" label="Not Discoverable" />
        </FormTab>
        <FormTab label="Rethink Database" replace>
          <LectureRethink />
        </FormTab>
        <FormTab label="JewishAnytime" replace>
          <FunctionField
            label="JewishAnytime Thumbnail (this may take time to update)"
            render={(record) => (
              <>
                <img
                  src={`https://torahanytime-files.sfo2.cdn.digitaloceanspaces.com/assets/thumbnails/${record.id}.jpg`}
                  width={200}
                  title="ja thumnail"
                />
                <ImageInput
                  source="FILE_photo"
                  label="JewishAnytime Thumbnail Upload "
                  accept="image/*"
                  placeholder={
                    <p>
                      Click or drop a photo here. <br />
                      Size: 1280x720
                      <br />
                      (Youtube Thumbnail size)
                    </p>
                  }
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              </>
            )}
          />
          <FunctionField
            label="Feature on JewishAnytime"
            render={(record) =>
              record.projects?.includes(2) ? (
                <>
                  <BooleanInput source="is_featured_on_JA" label="Featured" />
                  <img
                    src={record.featured_banner}
                    width={600}
                    title="featured banner"
                  />
                  <ImageInput
                    source="FILE_banner"
                    label="Upload Banner"
                    accept="image/*"
                    placeholder={<p>Click or drop a full-size banner here.</p>}
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                </>
              ) : (
                <label>
                  Only JewishAnytime lectures can be featured on JewishAnytime
                </label>
              )
            }
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

const filters = [
  <RadioButtonGroupInput
    alwaysOn
    label="Display"
    source="display_active"
    initialValue=""
    options={{
      row: true,
    }}
    choices={[
      { id: "", name: "All" },
      { id: "1", name: "Active" },
      { id: "0", name: "Inactive" },
    ]}
  />,
  <TextInput source="id" alwaysOn />,
  <TextInput source="title" alwaysOn />,
  <SpeakerInput
    label="Speaker"
    source="speaker"
    reference="speakers"
    alwaysOn
  />,
  <OrganizationInput
    label="Organization"
    source="org"
    reference="organizations"
  />,
  <CategoryInput label="Category" source="category" reference="categories" />,
  <SubCategoryInput
    label="Sub-Category"
    source="subcategory"
    reference="categories"
  />,
];

function ListLayout(props) {
  if (props.infinite)
    return (
      <InfiniteList
        {...props}
        actions={<CardListActions />}
        exporter={true}
        filters={filters}
        filterDefaultValues={{ display_active: "" }}
        perPage={50}
        sort={{
          field: "id",
          order: "DESC",
        }}
        title="Lectures"
      >
        {props.children}
      </InfiniteList>
    );

  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={filters}
      filterDefaultValues={{ display_active: "" }}
      pagination={<MorePagination />}
      sort={{
        field: "id",
        order: "DESC",
      }}
      title="Lectures"
    >
      {props.children}
    </List>
  );
}

function ListView(props) {
  const [infinite, setInfinite] = useState(false);
  const { permissions } = usePermissions();
  console.log(permissions);
  return (
    <div>
      {/* <Switch value={infinite} onChange={setInfinite} /> For meir- toggle on infinite list mode*/}

      <ListLayout
        {...props}
        actions={<CardListActions />}
        exporter={true}
        filters={filters}
        filterDefaultValues={{ display_active: "" }}
        perPage={permissions?.includes("super_admin") ? 250 : 25}
        pagination={<MorePagination />}
        sort={{
          field: "id",
          order: "DESC",
        }}
        title="Lectures"
        infinite={infinite}
      >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="id" />
          <ImageField
            source="thumbnail_url"
            label="Thumbnail"
            sx={{
              "& img": { maxWidth: 135, maxHeight: 76, objectFit: "contain" },
            }}
          />
          <TextField source="speaker_name" label="Speaker" />
          <TextField source="title" />
          <BooleanField source="display_active" label="Active"></BooleanField>
          <DateTimeField
            source="created_at"
            label="Published at"
            style={{ minWidth: 150 }}
          />
          <DateTimeField
            source="updated_at"
            label="Updated at"
            style={{ minWidth: 150 }}
          />
          <TextField source="published_by_email" label="Published by" />
        </Datagrid>
      </ListLayout>
    </div>
  );
}

export const Lectures = {
  category: "database",
  name: "lectures",
  icon: PlayCircle,
  list: ListView,
  options: { label: "Lectures" },
  edit: EditView,
  create: CreateView,
  roles: ["author"],
  permissions: {
    author: ["create"],
  },
};
