import React, { useEffect, useState } from "react";
import { authClient } from "../../server";
import { useNotify, useRecordContext, useRefresh } from "ra-core";
import { formatDateTime } from "../../components";
import { Check, X } from "lucide-react";

const VerificationList = ({ styles }) => {
  const record = useRecordContext();
  const notify = useNotify();
  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await authClient
      .get(`/admin/users/${encodeURIComponent(record.id)}/verification`)
      .catch((error) => notify(error.body.message, { type: "error" }));

    if (res?.json) {
      setItems(res.json);
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      <div style={styles.row}>
        <span style={styles.header}>Date</span>
        <span style={styles.header}>Device</span>
        <span style={styles.header}>Verified</span>
        <span style={styles.header}>UUID</span>
      </div>
      {items.map((item) => (
        <VerificationItem item={item} styles={styles} />
      ))}
    </div>
  );
};

const VerificationItem = ({ item, styles }) => {
  const date = formatDateTime(item.time);
  const record = useRecordContext();
  const notify = useNotify();
  const refreshView = useRefresh();

  const verify = async (e) => {
    e.preventDefault();
    if (!window.confirm("Are you sure?")) {
      return;
    }

    await authClient
      .post(
        `/admin/users/${encodeURIComponent(
          record.id,
        )}/verification/${encodeURIComponent(item.id)}/verify`,
        item,
      )
      .catch((error) => notify(error.body.message, { type: "error" }))
      .finally(() => {
        refreshView();
      });
  };
  return (
    <div key={item.id} style={styles.row}>
      <span style={styles.col}>{date}</span>
      <span style={styles.col}>{item.device}</span>
      <span style={styles.col}>
        {item.verified ? (
          <Check />
        ) : (
          <>
            <X />
            <button onClick={verify}>Verify now</button>
          </>
        )}
      </span>
      <span style={styles.col}>{item.uuid}</span>
    </div>
  );
};

export default VerificationList;
