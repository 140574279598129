// Manage
// import { NotifyBar } from "./Homepages/notifybar";
import { HomepageCategories } from "./Homepages/2point0/homepage-categories";
import { Redirects } from "./Homepages/2point0/redirects";
import { Conventions } from "./LiveEvents/conventions";
import { LiveEvents } from "./LiveEvents/live-events";
import { Schedules } from "./Upload/schedules";
import { UserComments } from "./Users/UserComments.tsx";
// import { News_announcements } from "./Homepages/news_announcements";
import { FAQ } from "./Homepages/faq";
// Database
import { CampaignDedications } from "./Campaigns/campaign_dedications";
import { SponsorDedications } from "./Sponsor/sponsor_dedications";
import { CampaignDonations } from "./Campaigns/campaign_donations";
import { CampaignPackages } from "./Campaigns/campaign_packages";
import { Clips } from "./Clips/clips";
import { Clips as ClipsNew } from "./Clips/ClipsNew";
import { Categories } from "./MiscData/categories";
import { UserSpeakerPermissions } from "./Speakers/user-speaker-permissions.jsx";
import { BetaUsers } from "./Users/beta_users";

// import { CorporateDoseSponsor } from "./DailyDose/corp_dose_dedications";
import { Doses } from "./DailyDose/DisplayDoses";
import { DoseDedications } from "./DailyDose/dose_dedications";
import { DoseDedicationsReporting } from "./DailyDose/dose_dedications_reporting";
import { DoseDedicationTypes } from "./DailyDose/dose_dedication_types";
import { DoseDiscountCodes } from "./DailyDose/dose_discount_codes";
import { DoseDonors } from "./DailyDose/dose_donors";
import { DoseEmailSent } from "./DailyDose/dose_email_sent";
import { DoseEmailSubscribers } from "./DailyDose/dose_email_subscribers";
import { Dedications } from "./Donations/dedications";
import { Donations } from "./Donations/donations";
import { DonationsLegacy } from "./Donations/donations_legacy";
// import { EmailTemplateEditor } from "./Email/email_template_editor";
import { Lectures } from "./Lectures/lectures";
import { Languages } from "./MiscData/languages";
import { Locations } from "./MiscData/locations";
import { Organizations } from "./MiscData/organizations";
import { Posts } from "./MiscData/posts";
import { Projects } from "./MiscData/projects";
import { SpeakerTitles } from "./Speakers/speaker-titles";
import { Speakers } from "./Speakers/speakers";
import { UploadsRendered } from "./Upload/rendered";
import { UploadsRendering } from "./Upload/rendering";
import { Users } from "./Users/users";
// Stats
import { LectureStats } from "./Lectures/lecturesStats";
import { SpeakerStats } from "./Speakers/speakersStats";
// Email
import { EmailSubscribers } from "./Email/subscribers";
// Misc
import { DbOperationLogs } from "./MiscData/db_operation_logs";
import { SearchSynonyms } from "./MiscData/search_synonyms";
// TorahAnytime 3.0
import { Currencies } from "./Donations/currencies";
import { DoseCurrenciesAmounts } from "./DailyDose/dose_currencies_amounts";
import { Banners } from "./Homepages/Banners/banners";
import { Homepage } from "./Homepages/homepage";
import { Settings, SettingsTypes } from "./settings";
import { Layouts } from "./Homepages/Banners/layouts";
import { Partners } from "./Donations/partners";
import { Ads } from "./Homepages/ads";
import { Series } from "./Lectures/series";
import { Programs } from "./Tisha Bav/tisha_bav_programs";
import { TbavClasses } from "./Tisha Bav/tisha_bav_classes";
import { ProgramsReorderPage } from "./Tisha Bav/programs_order";
import { TbavSponsors } from "./Tisha Bav/tisha_bav_sponsors";
import { TbavAds } from "./Tisha Bav/tisha_bav_ads";
import { EmailTemplates } from "./Email/email_templates";
import { ClipNShare } from "./Clips/clipnshare";
import { LectureSponsors } from "./Donations/lecture_sponsors";

import { AppleAppSitePage } from "./App/apple-app-site.tsx";
import { PushNotifyPage } from "./App/push-notify.tsx";
import { Newsletters } from "./Email/newsletters.tsx";
import { PopupList } from "./Homepages/2point0/Popup.tsx";
import { SlideshowPage } from "./Homepages/2point0/Slideshow.tsx";
import { TodaysLearningPage } from "./Homepages/2point0/TodaysLearningDedication.tsx";
import { TopAdBannerPage } from "./Homepages/2point0/TopAdBanner.tsx";
import { VideoAdPage } from "./Homepages/2point0/VideoAd.tsx";
import { Cache } from "./Homepages/Cache";
import { HomeRowsReorderPage } from "./Homepages/HomeRowsReorder.tsx";
import { PlayerPage } from "./LiveEvents/Player.tsx";
import { PlayerList } from "./LiveEvents/PlayerList";
import { ManagePlayerSettings } from "./LiveEvents/PlayerSettings";
import { TranscodersPage } from "./Upload/Transcoders";
import { DynamicFiles } from "./MiscData/dynamic_files.jsx";

// Just export special pages that we don't route to here.
export * from "./Dashboard";

//routes that don't follow the standard resource CRUD layout
export const customPageRoutes = [
  AppleAppSitePage,
  PushNotifyPage,
  PlayerList,
  Cache,
  PopupList,
  SlideshowPage,
  TodaysLearningPage,
  TopAdBannerPage,
  VideoAdPage,
  PlayerPage,
  ManagePlayerSettings,
  TranscodersPage,
  HomeRowsReorderPage,
];

// NOTE: The order of the default exported array determines the render order.
// RESOURCES:
const resources = [
  // Manage
  // NotifyBar,
  HomepageCategories,
  LiveEvents,
  Conventions,
  Newsletters,
  Redirects,
  Schedules,
  DynamicFiles,
  // News_announcements,
  FAQ,
  // Corporate Sponsors
  // CorporateDoseSponsor,
  // EmailTemplateEditor,
  // Daily Dose
  DoseCurrenciesAmounts,
  DoseDedicationTypes,
  DoseDedications,
  DoseDonors,
  DoseDiscountCodes,
  DoseDedicationsReporting,
  DoseEmailSubscribers,
  DoseEmailSent,
  EmailTemplates,

  // Database
  BetaUsers,
  Categories,
  CampaignDedications,
  CampaignDonations,
  CampaignPackages,
  Clips,
  ClipsNew,
  ClipNShare,
  Dedications,
  Donations,
  DonationsLegacy,
  Doses,
  Languages,
  Lectures,
  LectureSponsors,
  Locations,
  Organizations,
  Posts,
  Projects,
  Speakers,
  SponsorDedications,
  UserSpeakerPermissions,
  SpeakerTitles,
  UploadsRendered,
  UploadsRendering,
  Users,
  // Stats
  LectureStats,
  SpeakerStats,
  UserComments,
  // Email
  EmailSubscribers,
  // Misc
  SearchSynonyms,
  DbOperationLogs,
  // TorahAnytime 3.0
  Currencies,
  Banners,
  Homepage,
  Settings,
  SettingsTypes,
  Layouts,
  Partners,
  Ads,
  Series,
  Programs,
  ProgramsReorderPage,
  TbavClasses,
  TbavSponsors,
  TbavAds,
];
export default resources;

export const customRoutes = [];
