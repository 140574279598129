import React from "react";
// import { withRouter } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import icon from "@mui/icons-material/Transform";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Title } from "react-admin";
import { authClient } from "../../server";

const cardStyle = {
  fontFamily: "Roboto Condensed, sans-serif",
};

class Transcoders extends React.PureComponent {
  state = {
    transcoders: {},
  };

  async getSummary() {
    const res = await authClient
      .get("/admin/transcoders/status")
      .catch(this.props.showError)
      .finally(this.props.fetchEnd);
    if (!res || !res.json) {
      return;
    }
    const transcoders = res.json.data.reduce((prev, curr) => {
      prev[curr.id] = curr;
      return prev;
    }, {});

    this.setState({
      transcoders,
    });
  }
  async handleDelete(id) {
    authClient
      .delete(`/admin/transcoder/${id}`)
      .catch(this.props.showError)
      .finally(this.props.fetchEnd);
    const transcoderC = this.state.transcoders;
    delete transcoderC[id];
    this.setState({
      transcoders: { ...transcoderC },
    });
  }

  async handleCreate() {
    const res = await authClient
      .post("/admin/transcoder/create")
      .catch(this.props.showError)
      .finally(this.props.fetchEnd);
    if (!res || !res.json) {
      return;
    }
    const { data } = res.json;
    const { transcoders } = this.state;
    this.setState({
      transcoders: { ...transcoders, [data.id]: data },
    });
  }

  async handleRefresh(id) {
    const res = await authClient
      .get(`/admin/transcoder/${id}`)
      .catch(this.props.showError)
      .finally(this.props.fetchEnd);
    if (!res || !res.json) {
      return;
    }
    const { data } = res.json;
    if (!data.id) {
      // If no id in response, it was deleted, then remove from state.
      const transcoderC = this.state.transcoders;
      delete transcoderC[id];
      this.setState({
        transcoders: { ...transcoderC },
      });
      return;
    }
    const { transcoders } = this.state;
    this.setState({
      transcoders: { ...transcoders, [data.id]: data },
    });
  }

  componentDidMount() {
    this.getSummary();
  }

  render() {
    const {
      state: { transcoders },
    } = this;

    const hasPending = !!Object.keys(transcoders).find(
      (id) =>
        transcoders[id].server_status === "pending" ||
        transcoders[id].transcoder_status === "pending",
    );

    return (
      <React.Fragment>
        <Card>
          <Title title="Transcoders" />
          <CardContent style={cardStyle}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {" "}
              <Button
                variant="fab"
                color="primary"
                aria-label="Add"
                disabled={hasPending}
                onClick={() => this.handleCreate()}
              >
                <AddIcon />
              </Button>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell numeric>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Server Status</TableCell>
                  <TableCell>Transcoder Status</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(transcoders).map((row) => {
                  return (
                    <TableRow key={row}>
                      <TableCell component="th" scope="row" numeric>
                        {transcoders[row].id}
                      </TableCell>
                      <TableCell>{transcoders[row].name}</TableCell>
                      <TableCell>{transcoders[row].server_status}</TableCell>
                      <TableCell>
                        {transcoders[row].transcoder_status}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Button
                          variant="contained"
                          style={
                            transcoders[row].deletable
                              ? { backgroundColor: "red", color: "white" }
                              : undefined
                          }
                          onClick={() => this.handleDelete(row)}
                          disabled={
                            transcoders[row].transcoder_status !== "pending" &&
                            !transcoders[row].deletable
                          }
                        >
                          Delete
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => this.handleRefresh(row)}
                        >
                          Refresh
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <Typography>Note: Each Transcoder = 3 messages.</Typography>
      </React.Fragment>
    );
  }
}

export default Transcoders;
export const TranscodersPage = {
  category: "transcoder",
  icon,
  name: "Transcoders",
  label: "Transcoders",
  url: "/transcoder",
  exact: true,
  Element: <Transcoders />,
};
