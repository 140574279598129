import PropTypes from "prop-types";
import { useRefresh } from "ra-core";
import React, { useEffect, useState } from "react";

/**@deprecated waiting to delete this */
export class AutoRefresh extends React.PureComponent {
  static defaultProps = {
    seconds: 30,
  };

  static propTypes = {
    seconds: PropTypes.number,
  };
  /** Id of the last timer created by calling `setTimer`. */
  timer = 0;

  /** Calls `this.props.refreshView()`. */
  refresh = () => {
    const {
      props: { refreshView },
    } = this;
    // console.log("AUTO REFRESH");
    refreshView?.();
    this.startTimer();
  };

  componentDidMount() {
    // console.log("START AUTO REFRESH");
    this.startTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    return null;
  }

  startTimer() {
    const {
      props: { seconds },
      refresh,
    } = this;
    this.timer = setTimeout(refresh, seconds * 1000);
  }
}
const THIRTY_SECONDS = 30000;

export const AutoRefreshView = () => {
  const refreshView = useRefresh();
  const [refresh, setRefreshCount] = useState(1);
  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshCount((c) => c + 1);
      refreshView();
      console.log("refreshed");
    }, THIRTY_SECONDS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
};

export default AutoRefreshView;
