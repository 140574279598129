import UploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";
import { Button } from "react-admin";
import { MediaUploadContext } from "./MediaUploadProvider";

/**
 * Toolbar tool to toggle the media upload panel and show status.
 */
export class MediaUploadTool extends React.PureComponent {
  static contextType = MediaUploadContext;

  togglePanel = (e) => {
    e.preventDefault();
    this.context.toggle();
  };

  render() {
    const { activeUploadCount, allowed /*, selected */ } = this.context;
    if (!allowed) {
      return null;
    }
    // const selectedCount = selected ? 1 : 0;
    const title =
      `Media Uploads` +
      // `\n - ${selectedCount} selected.` +
      `\n - ${activeUploadCount} uploading.`;
    return (
      <div style={{ flex: 0 }}>
        <Button
          color="inherit"
          onClick={this.togglePanel}
          // label={`(${selectedCount}/${activeUploadCount})`}
          label={`(${activeUploadCount})`}
          title={title}
        >
          <UploadIcon />
        </Button>
      </div>
    );
    // HOWTO: Change color of the icon:
    // `<div style={{ color: condition ? "#224277" : undefined }}`

    // HOWTO: Align icon to the right of the label:
    // <Button ... alignIcon="right">

    // HOWTO: Use stateful icons:
    // `{this.context.showing ? <ArrowDropUp /> : <ArrowDropDown />}`
  }
}
