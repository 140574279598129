import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { authClient } from "../../server";

export const LectureRethink = _LectureRethink;
function _LectureRethink() {
  const [settings, setSettings] = useState({});
  const [lectureToSave, setLectureToSave] = useState({});
  const [lectureId, setLectureId] = useState("");
  const [lectureChanged, setLectureChanged] = useState(false);

  const saveSettings = async () => {
    setLectureChanged(false);

    await authClient.post(
      `/admin/rethink-lectures/${lectureId}`,
      JSON.parse(lectureToSave),
    );
  };

  useEffect(() => {
    (async function () {
      console.log({ rethink: window.location });
      let _lectureId = window.location.hash.split("/")[2];
      setLectureId(_lectureId);
      const response = await authClient.get(
        `/admin/rethink-lectures/${_lectureId}`,
      );
      setSettings(JSON.parse(response.body));
    })();
  }, []);

  const styles = {
    flex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "20px",
    },
  };

  return (
    <div>
      <JSONInput
        style={{
          width: "100%",
        }}
        id="a_unique_id"
        placeholder={settings}
        // colors={darktheme}
        locale={locale}
        height="550px"
        width="100%"
        onChange={(e) => {
          setLectureChanged(true);
          console.log("e.json", e.json);
          setLectureToSave(e.json);
        }}
      />
      <div style={styles.flex}>
        <Button
          disabled={!lectureChanged}
          variant={"outlined"}
          onClick={saveSettings}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}
